import apiCooperplace from 'services/api/cooperplace';

export async function getVehicles({ setVehicles, setLoading, setSnackBar }) {

  try {
    const newVehicles = []

    setLoading(true)

    const response = await apiCooperplace('QueueOfVehicles')

    const { vehicles } = response.data;

    vehicles.forEach(vehicle => {
      if (vehicle.position) {
        newVehicles.push(vehicle)
      }
    })

    setVehicles(newVehicles);

  } catch (error) {
    setSnackBar({
      type: 'error',
      message: 'Não foi possivel listar os veiculos',
      display: true,
    });
    console.log(error)
  } finally {
    setLoading(false);
  }
}

import styled from 'styled-components';
import RawBreadcrumb from 'react-bootstrap/Breadcrumb';
import Container from 'react-bootstrap/Container';

import theme from 'styles/theme';

export const Breadcrumb = styled(RawBreadcrumb)`
  > ol {
    background-color: transparent;

    li.breadcrumb-item::before {
      color: #fff;
    }
  }
`;

export const BreadcrumbItem = styled(RawBreadcrumb.Item)``;

export const Header = styled.header`
  background-color: ${theme.colors.dark_blue};
  min-height: 250;
  padding-bottom: ${({ isOld }) => (isOld ? 0 : 130)}px;
`;

export const Main = styled(Container)`
  margin-top: ${({ isOld }) => (isOld ? 0 : -130)}px;
`;

export const SaveContainer = styled.div`
  background-color: ${theme.colors.dark_blue};
  padding: 15px;
  z-index: 998;
  position: fixed;
  top: 50px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  > button:first-of-type {
    margin-right: 10px;
  }
  > button {
    width: 125px;
  }
`;

export const CenterComponentContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

import { takeLatest, call, put, all } from 'redux-saga/effects';

// eslint-disable-next-line import/no-cycle
import api from 'services/api';

import Types from './types';
import Actions from './actions';

export function* fetchDrivers({ payload }) {
  try {
    const response = yield call(api.get, 'shipping-companies/drivers', {
      params: { ...payload.params },
    });

    yield put(Actions.fetchDriversSuccess(response.data));
  } catch (ex) {
    yield put(Actions.fetchDriversFailure());
  }
}

export default all([takeLatest(Types.FETCH_DRIVERS, fetchDrivers)]);

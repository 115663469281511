const Yup = require('yup');

export const dealCreateSchema = Yup.object().shape({
  vehicle_id: Yup.number().required('Campo obrigatório').typeError('Campo obrigatório'),
  shipping_company_id: Yup.number().required('Campo obrigatório').typeError('Campo obrigatório'),
  driver_id_carrier_web: Yup.number().required('Campo obrigatório').typeError('Campo obrigatório')
});


export async function validate(data, setErrors) {
  const errorList = {};
  try {
    await dealCreateSchema.validate(data, {
      abortEarly: false,
    });
    return true;

  } catch (err) {
    err.inner.forEach(error => {
      errorList[error.path] = error?.message;
    });
    return false;
  } finally {
    setErrors(errorList);
  }
}

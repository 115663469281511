import Types from './types';

const Actions = {
  dealsRequest: ({ user_id, only_active, only_closed, paginate }) => ({
    type: Types.DEALS_REQUEST,
    payload: { user_id, only_active, only_closed, paginate },
  }),

  dealsSuccess: deals => ({
    type: Types.DEALS_SUCCESS,
    payload: { deals },
  }),

  dealsFailure: error => ({
    type: Types.DEALS_FAILURE,
    payload: { error },
  }),
  dealCreateRequest: (deal, history) => ({
    type: Types.DEAL_CREATE_REQUEST,
    payload: { deal, history },
  }),

  dealCreateSuccess: (deal, history) => ({
    type: Types.DEAL_CREATE_SUCCESS,
    payload: { deal, history },
  }),

  dealCreateFailure: error => ({
    type: Types.DEAL_CREATE_FAILURE,
    payload: { error },
  }),
};

export default Actions;

import axios from 'axios';

// eslint-disable-next-line import/no-cycle
import { getToken } from '../auth';

const dealApi = axios.create({
  baseURL: process.env.REACT_APP_DEAL_API_URL,
});

dealApi.interceptors.request.use(async config => {

  const outputConfig = config;

  const token = getToken();

  if (token) {
    outputConfig.headers.Authorization = `Bearer ${token}`;
  }

  return outputConfig;
});

export default dealApi;

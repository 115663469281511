export function groupBy(array, keyGetter) {
    return array.reduce((acc, item) => {
      const key = keyGetter(item);
  
      const holder = acc[key] || [];
      holder.push(item);
  
      return { ...acc, [key]: holder };
    }, {});
  }
  
  export function removeDuplicates(array, keyGetter = item => item.id) {
    return [...new Map(array.map(item => [keyGetter(item), item])).values()];
  }
  
  export function hasDuplicates(array) {
    return new Set(array).size !== array.length;
  }
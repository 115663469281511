import styled from 'styled-components';

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 75px;
  margin-bottom: 75px;
`;

export const Image = styled.img`
  height: 45px;
  width: 45px;
  margin: -25px;
  object-fit: contain;
`;

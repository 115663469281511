import styled from 'styled-components';

export const Logo = styled.img`
  margin-bottom: 10px;
  height: 40px;
  width: 185px;
  margin-left:10px;
  object-fit: contain;
  
`;

import React, { useState, useMemo } from 'react';
import { Row, Col } from 'react-bootstrap';
import Lightbox from 'react-image-lightbox';

import Badge from 'components/Badge';
import Button from 'components/Button';
import DropdownButton from 'components/DropdownButton';
import { useSnackbar } from 'components/SnackbarV3';
import Text from 'components/Text';
import { downloadPDF, downloadXml } from 'utils/fileDownload';
import { CTE_STATUS_VARIANTS } from 'utils/constants';
import { formatDateTime, formatCurrency } from 'utils/formatter';
import { DocumentType } from './documentType';
import apiCooperplace from 'services/api/cooperplace';

function isPDF(item) {
  const extensions = item.split('.');
  const lastExtension = extensions[extensions.length - 1];

  return /pdf/gi.test(lastExtension);
}

export function Emitted({
  emitted,
  documents,
  setLightbox,
  fetchDocuments,
  opened,
  lightbox,
  lightboxTitle,
  lightboxIndexes,
}) {
  const snackbar = useSnackbar();
  const isUTC = true;

  const [isLoading, setLoading] = useState(false);
  const [isDownloading, setDownloading] = useState(false);

  const attachments =
    documents[emitted.id]?.filter(
      document => document.attachments.length > 0
    ) || [];
  const attachmentsWithoutPDF = useMemo(() => {
    return attachments.filter(item => !isPDF(item.doc_filename));
  }, [attachments]);
  const ctes = attachments.filter(document => document.type_obs === 'cte');
  const nfs = attachments.filter(document => document.type_obs === 'canhoto');
  const others = attachments.filter(
    document => document.type_obs === 'outros' || document.type_obs === 'others'
  );

  function onClickPreview(id) {
    const index = id
      ? attachmentsWithoutPDF.findIndex(attachment => attachment.id === id)
      : 0;

    setLightbox(prev => ({
      ...prev,
      index,
      documents: attachmentsWithoutPDF,
      isOpen: true,
    }));
  }

  async function downloadCteXml(emitted_id, cteNumber) {
    try {
      setDownloading(true);
      const response = await apiCooperplace.get(
        `files/getMdfeCte?id=${emitted_id}&fileExtension=xml&documentType=cte`
      );
      downloadXml(response.data.content, `XML CT-e ${cteNumber}.xml`);
    } catch (error) {
      snackbar.show('Chave do CT-e inválida', { type: 'error' });
    } finally {
      setDownloading(false);
    }
  }

  async function downloadCtePdf(id) {
    try {
      setDownloading(true);
      const response = await apiCooperplace.get(
        `files/getMdfeCte?id=${id}&fileExtension=pdf&documentType=cte`
      );
      downloadPDF(response.data.content, 'CT-e', id);
    } catch (error) {
      snackbar.show('Chave do CT-e inválida', { type: 'error' });
    } finally {
      setDownloading(false);
    }
  }

  function getImagePath(index, alwaysDisplay) {
    const isSameAsIndex =
      lightbox.index === lightboxIndexes.next &&
      lightbox.index === lightboxIndexes.previous;

    if (!alwaysDisplay && isSameAsIndex && lightbox.documents.length === 1) {
      return null;
    }

    return lightbox.documents[index]?.attachments[0].path;
  }

  return (
    <div key={emitted.id} className="mb-3">
      {emitted.docType === 'CTE' && (
        <>
          <Text as="div" color="dark" type="body" weight={500} className="mb-4">
            CT-e {emitted.cteNumber}
            {emitted.emission_status_id && (
              <Badge
                pill
                light
                size="sm"
                className="ml-2"
                variant={CTE_STATUS_VARIANTS[emitted.emission_status_id]}
              >
                {emitted.emission_status_name}
              </Badge>
            )}
          </Text>
        </>
      )}
      {emitted.docType !== 'CTE' && (
        <>
          <Text as="div" color="dark" type="body" weight={500} className="mb-4">
            {emitted.docType} {emitted.cteNumber}
            {emitted.description && (
              <Badge className="ml-3" size="sm" pill variant={'light'}>
                {emitted.docType !== 'CTE' && emitted.docType !== 'MIC' && (
                  <Text type="label" weight={600}>
                    {emitted.description}
                  </Text>
                )}
                {emitted.doc_type === 'MIC' && (
                  <Text type="label" weight={600}>
                    {'Gerado MIC/DTA'}
                  </Text>
                )}
              </Badge>
            )}
          </Text>
        </>
      )}
      <Row className="d-flex justify-content-between my-3">
        <Col xs={2}>
          <Text as="div" color="dark" type="regular" className="mb-3">
            Data de emissão
          </Text>
          <Text color="gray" type="body">
            {formatDateTime(emitted.dateEmitted, { isUTC })}
          </Text>
        </Col>
        {emitted.docType !== 'MIC' && emitted.docType !== 'CRT' && (
          <>
            <Col xs={2}>
              <Text as="div" color="dark" type="regular" className="mb-3">
                Nº das notas fiscais
              </Text>
              <Text color="gray" type="body">
                {emitted?.nfs?.map(nf => nf.nfNumber).join(', ')}
              </Text>
            </Col>
            <Col xs={2}>
              <Text as="div" color="dark" type="regular" className="mb-3">
                Valor
              </Text>
              <Text color="gray" type="body">
                {formatCurrency(
                  emitted?.nfs
                    ?.filter(nf => emitted.id === nf.emittedId)
                    ?.reduce((acc, curr) => {
                      if (curr.nfValue) {
                        return curr.nfValue + acc;
                      }
                      return acc + 0;
                    }, 0),
                  'BRL',
                  'R$'
                )}
              </Text>
            </Col>
          </>
        )}
        {emitted.docType === 'CTE' && (
          <Col md={4} sm={12} xs={12} className="d-flex justify-content-end">
            <Button
              loading={isLoading}
              style={{ height: '44px' }}
              className=" py-2"
              onClick={() => fetchDocuments(emitted.id, { setLoading })}
            >
              <Text type="regular" weight="600">
                {opened === emitted.id ? 'Fechar' : 'Anexos'}
              </Text>
            </Button>
            <DropdownButton
              style={{ display: 'inline', marginLeft: 10 }}
              title="Opções"
              variant="secondary"
              className="py-2 d-flex"
              loading={isDownloading}
              options={[
                {
                  title: 'Baixar XML',
                  onClick: () => downloadCteXml(emitted.id, emitted.cteNumber),
                  type: 'item',
                },
                {
                  title: 'Baixar PDF',
                  onClick: () => downloadCtePdf(emitted.id, emitted.cteNumber),
                  type: 'item',
                },
              ]}
            />
          </Col>
        )}
      </Row>

      {opened === emitted.id && (
        <div>
          {lightbox.isOpen && (
            <Lightbox
              mainSrc={getImagePath(lightbox.index, true)}
              nextSrc={getImagePath(lightboxIndexes.next)}
              prevSrc={getImagePath(lightboxIndexes.previous)}
              onCloseRequest={() =>
                setLightbox(prev => ({ ...prev, isOpen: false }))
              }
              onMovePrevRequest={() =>
                setLightbox(prev => ({
                  ...prev,
                  index: lightboxIndexes.previous,
                }))
              }
              onMoveNextRequest={() =>
                setLightbox(prev => ({
                  ...prev,
                  index: lightboxIndexes.next,
                }))
              }
              imageTitle={lightboxTitle}
            />
          )}
          <DocumentType
            title="CT-e"
            documents={ctes}
            onClickPreview={onClickPreview}
            fetchDocuments={fetchDocuments}
            emittedId={emitted?.id}
            setLoading={setLoading}
          />
          <DocumentType
            title="Canhoto"
            documents={nfs}
            onClickPreview={onClickPreview}
            fetchDocuments={fetchDocuments}
            emittedId={emitted?.id}
            setLoading={setLoading}
          />
          <DocumentType
            title="Outros"
            documents={others}
            onClickPreview={onClickPreview}
            fetchDocuments={fetchDocuments}
            emittedId={emitted?.id}
            setLoading={setLoading}
          />
        </div>
      )}
    </div>
  );
}

import { plateValidator } from 'utils/vehicle';
const Yup = require('yup');

export const ownerSchema = Yup.object().shape({
  owner: Yup.object()
    .shape({
      id: Yup.number().required('Proprietário obrigatório'),
    })
    .typeError('Proprietário obrigatório'),
  beneficiary: Yup.object()
    .shape({
      id: Yup.number().required('Beneficiário obrigatório'),
    })
    .typeError('Beneficiário obrigatório'),

  anttOwner: Yup.object()
    .shape({
      id: Yup.number().required('Proprietário do RNTRC é obrigatório'),
    })
    .typeError('Proprietário do RNTRC é obrigatório'),
});

const ImplementUpdateSchema = Yup.object().shape({
  antt: Yup.string()
    .required('RNTRC obrigatório')
    .typeError('RNTRC obrigatório'),
  anttType: Yup.object()
    .shape({
      id: Yup.number().required('Tipo de RNTRC obrigatório').nullable(),
    })
    .typeError('Tipo de RNTRC obrigatório'),
  anttAdherence: Yup.string()
    .nullable()
    .test('not-empty', 'Informe uma data', value => {
      if (!value) {
        return false;
      }
      return true;
    })
    .test('valid-date', 'Formato inválido', value => {
      return /^\d{4}\-\d{2}\-\d{2}$/.test(value);
    })
    .test(
      'valid-past-or-present-date',
      ({ originalValue }) => {
        return originalValue !== undefined &&
          originalValue !== '' &&
          originalValue !== null
          ? 'Data não pode ser posterior à data de hoje'
          : 'Informe uma data';
      },
      value => {
        if (!value) {
          return false;
        }

        const inputDate = new Date(value);
        const currentDate = new Date();
        return inputDate <= currentDate;
      }
    ),
  rntrcExpDate: Yup.string().nullable(),
  brand: Yup.object()
    .shape({
      id: Yup.number().nullable(),
    })
    .typeError('Marca inválida')
    .nullable(),
  city: Yup.object()
    .shape({
      name: Yup.string().required('Cidade obrigatória'),
    })
    .typeError('Cidade obrigatória'),
  color: Yup.object()
    .shape({
      value: Yup.string().nullable(),
    })
    .typeError('Cor inválida')
    .nullable(),
  model: Yup.object()
    .shape({
      label: Yup.string().nullable(),
    })
    .typeError('Modelo inválido')
    .nullable(),
  plate: Yup.string()
    .test('plateValidation', 'Placa inválida', value => plateValidator(value))
    .required('Placa obrigatória')
    .nullable(),
  renavam: Yup.string().typeError('Renavam Inválido').nullable(),
});

const ImplementCreateSchema = Yup.object().shape({
  antt: Yup.string()
    .nullable()
    .required('RNTRC obrigatório')
    .typeError('RNTRC obrigatório'),
  anttAdherence: Yup.string()
    .nullable()
    .test('not-empty', 'Informe uma data', value => {
      if (!value) {
        return false;
      }
      return true;
    })
    .test('valid-date', 'Formato inválido', value => {
      return /^\d{4}\-\d{2}\-\d{2}$/.test(value);
    })
    .test(
      'valid-past-or-present-date',
      ({ originalValue }) => {
        return originalValue !== undefined &&
          originalValue !== '' &&
          originalValue !== null
          ? 'Data não pode ser posterior à data de hoje'
          : 'Informe uma data';
      },
      value => {
        if (!value) {
          return false;
        }

        const inputDate = new Date(value);
        const currentDate = new Date();
        return inputDate <= currentDate;
      }
    ),
  anttType: Yup.object()
    .shape({
      id: Yup.number(),
    })
    .required('Tipo de RNTRC obrigatório')
    .nullable(),
  owner: Yup.object()
    .shape({
      id: Yup.number().required(),
    })
    .typeError('Proprietário obrigatório'),
  beneficiary: Yup.object()
    .shape({
      id: Yup.number().required(),
    })
    .typeError('Beneficiário obrigatório'),
  anttOwner: Yup.object()
    .shape({
      id: Yup.number().required(),
    })
    .typeError('Proprietário do RNTRC é obrigatório'),
  rntrcExpDate: Yup.string().nullable(),
  brand: Yup.object()
    .shape({
      id: Yup.number().nullable(),
    })
    .nullable(),
  city: Yup.object()
    .shape({
      id: Yup.number(),
    })
    .nullable()
    .required('Informe a cidade'),
  color: Yup.object()
    .shape({
      value: Yup.string().nullable(),
    })
    .nullable(),
  model: Yup.object()
    .shape({
      label: Yup.string().nullable(),
    })
    .nullable(),
  plate: Yup.string()
    .test('plateValidation', 'Placa inválida', value => plateValidator(value))
    .required('Placa obrigatória')
    .nullable(),
  renavam: Yup.number().nullable().required('Informe o renavam'),
  chassi: Yup.string().nullable().required('Informe o chassi'),
  yearManufacture: Yup.number()
    .nullable()
    .required('Informe o ano de fabricação'),
  yearModel: Yup.number().nullable().required('Informe o ano do modelo'),
  vehicleType: Yup.object()
    .shape({
      id: Yup.number().required(),
    })
    .nullable()
    .required('Tipo de veículo obrigatório'),
  implementType: Yup.object()
    .shape({
      id: Yup.number().required(),
      name: Yup.string().required(),
    })
    .nullable()
    .required('Tipo de implemento obrigatório'),
  bodyType: Yup.object()
    .shape({
      id: Yup.number().required(),
    })
    .when('implementType', {
      is: implementType => implementType?.name === 'Dolly',
      then: Yup.object()
        .shape({
          id: Yup.number(),
        })
        .nullable()
        .optional(),
      otherwise: Yup.object()
        .shape({
          id: Yup.number().required(),
        })
        .nullable()
        .required('Tipo de carroceria obrigatória'),
    }),
});

export async function validate(field, data, setErrors, context) {
  const errorList = {};
  try {
    if (field === 'createSchema') {
      await ImplementCreateSchema.validate(data, {
        abortEarly: false,
        context,
      });
      return true;
    } else if (field === 'edit') {
      await ImplementUpdateSchema.validate(data, {
        abortEarly: false,
        context,
      });
      return true;
    } else if (field === 'owner') {
      await ownerSchema.validate(data, {
        abortEarly: false,
        context,
      });
      return true;
    }
  } catch (err) {
    err.inner.forEach(error => {
      errorList[error.path] = error?.message;
    });
    return false;
  } finally {
    setErrors(errorList);
  }
}

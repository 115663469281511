import React, { useState, useEffect } from 'react';

import {
  Area,
  AreaChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
} from 'recharts';

function SpeedViagem({ latLong, setCoords, handleClickVelocity }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (latLong?.length > 0) {
      const line = latLong
        .filter((el, index) => {
          if (el.speed > 200) {
            return false;
          }
          if (index > 0) {
            let variance = el.speed - latLong[index - 1].speed;

            let variance_after = latLong[index + 1]
              ? el.speed - latLong[index + 1].speed
              : 0;
            if (variance < 0) {
              variance *= -1;
            }
            if (variance_after < 0) {
              variance_after *= -1;
            }
            if (variance > 50 || variance_after > 50) {
              return false;
            }
          }
          return true;
        })
        .map((el, index) => {
          return {
            ...el,
            velocidade: el.speed > 0 ? el.speed : null,
            latitude: el.latitude,
            longitude: el.longitude,
            battery: el.battery,
            point: index,
          };
        });
      setData(line);
    } else {
      setData([]);
    }
  }, [latLong]);

  function onClick(data) {
    setCoords(data?.activePayload[0]?.payload);
    handleClickVelocity(data?.activePayload[0]?.payload);
  }

  return (
    <>
      {data.length > 0 && (
        <ResponsiveContainer width="101%" height={100}>
          <AreaChart
            height={90}
            data={data}
            onClick={onClick}
            margin={{
              top: 8,
              right: 21,
              left: 20,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="hora" />
            <ReferenceLine
              y={60}
              label=""
              stroke="#FFc726"
              strokeDasharray="4 6"
              strokeWidth={2}
            />
            <ReferenceLine y={80} label="" stroke="red" strokeDasharray="3 3" />
            <Area
              type="monotone"
              dataKey="velocidade"
              stroke="#1C4B75"
              fill="#1C4B75"
              style={{ cursor: 'pointer' }}
              unit="km/h"
            />
            <YAxis unit="km/h" />
            <Tooltip />
            <Line
              unit="km/h"
              type="monotone"
              dataKey="velocidade"
              stroke="#1C4B75"
              activeDot={{ r: 8 }}
            />
          </AreaChart>
        </ResponsiveContainer>
      )}
    </>
  );
}

export default SpeedViagem;

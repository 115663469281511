import React, { useRef, useEffect, useCallback, useState } from 'react';
import { useField } from '@unform/core';
import Form from 'react-bootstrap/Form';

import Input from 'components/Input';

const ImageInput = ({ label, name, onChange, placeholder, ...rest }) => {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [preview, setPreview] = useState(defaultValue);
  const handlePreview = useCallback(e => {
    const file = e.target.files?.[0];
    if (!file) {
      setPreview(null);
    }
    const previewURL = URL.createObjectURL(file);
    setPreview(previewURL);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'files[0]',
      clearValue(ref) {
        ref.value = '';
        setPreview(null);
      },
      setValue(_, value) {
        setPreview(value);
      },
    });
  }, [fieldName, registerField]);
  return (
    <>
      {preview && <img src={preview} alt="Preview" width="100" />}
      <Form.File
        id="custom-file-translate-html"
        data-browse="Selecionar imagem"
        custom
        label={label}
        type="file"
        ref={inputRef}
        onChange={handlePreview}
        lang="pt-BR"
        {...rest}
        customInput={
          <Input
            defaultValue={defaultValue}
            placeholder={placeholder}
            error={error}
            label={label}
            onChange={onChange}
          />
        }
      />
    </>
  );
};
export default ImageInput;

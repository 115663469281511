export function convertToDatabase(value) {
    if (!value) {
      return 0;
    }
  
    return value.toString().split('.').join('').split(',').join('.');
  }
  
  export function sumArrayProperty(array, getProperty) {
    return array.reduce((acc, item) => acc + +getProperty(item), 0);
  }
  
  export function percentage(percent, total, toFixedDigits = 2) {
    return ((percent / 100) * total).toFixed(toFixedDigits);
  }
  
  export default {
    convertToDatabase,
  };
  
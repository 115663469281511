import React from 'react';
import ReactSelect from 'react-select';

import Text from '../Text';
import Loader from '../Loader';

import Async from './Async';
import * as Styled from './styles';

export default function Select({
  label,
  labelTextColor = '#464e5f',
  labelWhiteSpace = 'normal',
  menuFontSize = '14px',
  error,
  placeholder = 'Selecione...',
  onChange,
  value,
  options = [],
  multiple,
  onBlur,
  disableClear = false,
  disableSearch = false,
  isDisabled = false,
  getOptionLabel,
  getOptionValue,
  styles = {},
  components = {},
  noOptionsMessageText = 'Nenhuma opção encontrada',
  loading = false,
}) {
  const styledSelect = {
    singleValue: provided => ({
      ...provided,
      color: '#494950',
    }),
    menu: provided => ({
      ...provided,
      fontSize: menuFontSize,
    }),
    singleValue: provided => ({
      ...provided,
      fontSize: menuFontSize,
    }),
    ...styles,
  };

  return (
    <Styled.Container style={styles.containerStyle}>
      {label && (
        <Styled.Label
          color={labelTextColor}
          type="label"
          weight="500"
          labelWhiteSpace={labelWhiteSpace}
        >
          {label}
        </Styled.Label>
      )}
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <>
          <ReactSelect
            styles={styledSelect}
            isDisabled={isDisabled}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            options={options}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            isClearable={disableClear ? false : !!value}
            isSearchable={!disableSearch}
            isMulti={multiple}
            noOptionsMessage={() => <Text>{noOptionsMessageText}</Text>}
            onBlur={onBlur}
            components={{
              Control: ({ innerRef, innerProps, children }) => (
                <Styled.Control
                  innerRef={innerRef}
                  {...innerProps}
                  error={error}
                >
                  {children}
                </Styled.Control>
              ),
              ValueContainer: ({ innerRef, innerProps, children }) => (
                <Styled.ValueContainer innerRef={innerRef} {...innerProps}>
                  {children}
                </Styled.ValueContainer>
              ),
              MultiValue: ({
                children,
                components: Components,
                removeProps,
              }) => (
                <Styled.MultiValue>
                  <Text type="label">{children}</Text>
                  <Styled.MultiValueRemove {...removeProps}>
                    <Components.Remove />
                  </Styled.MultiValueRemove>
                </Styled.MultiValue>
              ),
              Placeholder: ({ innerRef, ...innerProps }) => (
                <Styled.Placeholder innerRef={innerRef} {...innerProps} />
              ),
              ...components,
            }}
          />
          {error && (
            <Styled.ErrorText color="error" type="little">
              {error}
            </Styled.ErrorText>
          )}
        </>
      )}
    </Styled.Container>
  );
}

Select.Async = Async;

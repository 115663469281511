import React from 'react';
import styled, { css } from 'styled-components';

const Box = styled.div`
  > *:not(:last-child) {
    ${({ $x }) =>
      !!$x &&
      css`
        margin-right: ${$x};
      `}
    ${({ $y }) =>
      !!$y &&
      css`
        margin-bottom: ${$y};
      `}
  }
`;

export default function Space({ x, y, ...props }) {
  return <Box {...props} $x={x} $y={y} />;
}

import React from 'react';

import Fallback from 'components/Fallback';
import Text from 'components/Text';

import * as Styled from './styles';
import { AttachmentComponent } from './attachment';

export function DocumentType({
  title,
  documents,
  onClickPreview,
  fetchDocuments,
  emittedId,
  setLoading,
}) {
  return (
    <div className="text-center mt-4">
      <Text as="div" type="regular" color="dark" weight={500}>
        {title}
      </Text>
      <div>
        <Styled.ImageRow>
          <Fallback
            fall={documents.length === 0}
            component={
              <div className="text-center flex-fill">
                <Text color="gray">Nenhuma imagem enviada.</Text>
              </div>
            }
          >
            {documents.map(document => (
              <AttachmentComponent
                key={document.id}
                document={document}
                onClickPreview={() => onClickPreview(document.id)}
                fetchDocuments={fetchDocuments}
                emittedId={emittedId}
                setLoading={setLoading}
              />
            ))}
          </Fallback>
        </Styled.ImageRow>
      </div>
    </div>
  );
}

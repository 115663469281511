import React from 'react';
import { Card } from 'components';
import Container from "react-bootstrap/Container";

export function LoadingPage({ isLoading }) {
  return (
  <Container>
    <Card loading={isLoading}></Card>
  </Container>
  );
}

import styled from 'styled-components';
import MaterialFormControlLabel from '@material-ui/core/FormControlLabel';
import MaterialCheckbox from '@material-ui/core/Checkbox';

export const StyledCheckbox = styled(MaterialCheckbox)`
  && {
    margin-top: 4px;
    margin-bottom: 4px;
    margin-right: 10px;
    margin-left: 10px;
    padding: 0px;
    border: 1px solid #76838f;
    border-radius: 2px;
    color: #76838f;
    .MuiIconButton-label {
      height: 20px;
      width: 20px;
    }
    span.MuiFormControlLabel-label {
      font-weight: 400;
    }
    &.Mui-checked {
      color: #5fb1f3;
      .MuiSvgIcon-root {
        opacity: 1;
      }
    }
    .MuiSvgIcon-root {
      opacity: 0;
      width: 30px;
      height: 30px;
    }
  }
`;
export const StyledFormControlLabel = styled(MaterialFormControlLabel)`
  width: 100%;
  padding: 0px;
  margin: 0px;
  && {
    color: ${({ theme }) => theme.colors.secondary};
    font-size: 1rem;
    font-weight: 400 !important;
    line-height: 1.2rem;
    text-transform: uppercase;

    .MuiButtonBase-root {
    }
  }
`;
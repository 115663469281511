import React from 'react';

import { StyledBadge } from './styles';

export default function Badge({
  variant,
  outlined,
  light,
  pill,
  children,
  size = 'md',
  ...props
}) {
  return (
    <StyledBadge
      size={size}
      type={variant}
      outlined={outlined}
      light={light}
      pill={pill}
      {...props}
    >
      {children}
    </StyledBadge>
  );
}

import Types from './types';

const Actions = {
  fetchCities: () => ({
    type: Types.FETCH_CITIES,
  }),
  fetchCitiesSuccess: cities => ({
    type: Types.FETCH_CITIES_SUCCESS,
    payload: { cities },
  }),
  fetchCitiesFailure: error => ({
    type: Types.FETCH_CITIES_FAILURE,
    payload: { error },
  }),
};

export default Actions;

/* eslint-disable import/no-unresolved */
import React, { useEffect, memo, forwardRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import theme from '../../../styles/theme';

import Card from 'components/Card';
import Text from 'components/Text';

import useController from './useController';
import * as Styled from './styles';

function Notifications({ className, 'aria-labelledby': labeledBy }, ref) {
  const {
    loading,
    notifications,
    pagination,
    setNotifications,
    fetchNotifications,
    handleNotificationClick,
  } = useController();

  useEffect(() => {
    fetchNotifications();
  }, []);

  const [didMount, setDidMount] = useState(false);

  useEffect(() => {
    setDidMount(true);
    return () => {
      setDidMount(false);
      setNotifications([]);
    };
  }, []);

  if (!didMount) {
    return null;
  }

  return (
    <Styled.Container
      ref={ref}
      aria-labelledby={labeledBy}
      id="notify-scroll"
      className={className}
    >
      <InfiniteScroll
        dataLength={notifications?.length}
        next={() => fetchNotifications(pagination.page + 1)}
        hasMore={pagination?.page < pagination.lastPage}
        scrollableTarget="notify-scroll"
      >
        {notifications?.map((notification, index) => (
          <Styled.Notification
            onClick={() => handleNotificationClick(notification)}
            className={`${notification?.read ? 'is-read' : ' '} ${
              notifications?.length - 1 === index ? 'border-none' : ' '
            }`}
          >
            <Text
              as="h2"
              type="regular"
              color={
                notification.read ? theme.colors.gray : theme.colors.primary
              }
              weight={notification.read ? 400 : 700}
            >
              {notification.title}
            </Text>
            <Text
              type="label"
              color={notification.read ? theme.colors.gray : undefined}
            >
              {notification.text}
            </Text>
          </Styled.Notification>
        ))}
      </InfiniteScroll>

      {!loading && notifications?.length === 0 && (
        <Card>Sem notificações não lidas</Card>
      )}
    </Styled.Container>
  );
}

export default memo(forwardRef(Notifications));

export { default as useNotificationsController } from './useController';
export { NotificationContextProvider } from './context';

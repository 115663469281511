import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { FaTrash, FaPencilAlt } from 'react-icons/fa';
import { Col, Row } from 'react-bootstrap';

import api from 'services/api';

import Card from 'components/Card';
import Text from 'components/Text';
import Button from 'components/Button';
import Fallback from 'components/Fallback';
import Table from 'components/Table';
import Badge from 'components/Badge';

import useModal from 'hooks/useModal';
import theme from 'styles/theme';
import DriverModal from './Modals/Drivers';

import { useVehicle } from '../shared/context';

import Tooltip from "components/Tooltip"
import { FaQuestionCircle } from 'react-icons/fa';

export default function VehicleDrivers() {
  const vehicle = useVehicle();
  const driverModal = useModal();
  const { vehicle_id } = useParams();
  const [modalName, setModalName] = useState('');

  function openModal() {
    setModalName('Driver');
  }

  async function handleRemove(index, item) {
    const deleting = vehicle.data.drivers.filter((_, idx) => idx !== index);
    vehicle.setData({
      drivers: deleting,
    });

    vehicle.setPrevVehicle(old => ({
      ...old,
      drivers: deleting,
    }));

    await api.delete(`v3/vehicle/driver/${vehicle_id}/${item.value}`);
  }

  useEffect(() => {
    if (modalName === 'Driver') {
      driverModal.open();
    }
  }, [modalName]);

  return (
    <>
      <DriverModal
        isOpen={driverModal.isOpen}
        onClose={() => {
          driverModal.close();
          setModalName('');
        }}
      />
      <Card
        loading={vehicle.isFetching}
        header={
          <Text type="header" color="dark">
            Motoristas
          </Text>
        }
        HeaderRightComponent={
          <Row>
            {!vehicle.data?.drivers?.length || (
              <Col
                className="d-flex justify-content-center my-2"
                style={{ gap: '0.3rem', alignSelf: 'baseline' }}>
                <Button
                  disabled={vehicle?.data?.vehicleManagement}
                  onClick={() => {
                    openModal();
                    vehicle.setData({
                      currentDriverEditing: null,
                    });
                  }}
                >
                  <Text type="regular" weight="500" color="white">
                    Adicionar
                  </Text>
                </Button>
                {vehicle?.data?.vehicleManagement && (
                  <Tooltip
                    content={
                      <Text type="label" color="white">
                        O veículo está cadastrado na gestão de frota e a edição de alguns campos está restrita ao seu operador.
                        Para mais informações, entre em contato pelo e-mail: coopercarga@coopercarga.com.br
                      </Text>
                    }
                  >
                    <FaQuestionCircle style={{ color: theme.colors.light_blue }} />
                  </Tooltip>
                )}
              </Col>
            )}
          </Row>
        }
      >
        <Row className="form">
          <Fallback
            fall={!vehicle.data?.drivers?.length}
            component={
              <Col
                xs={12}
                className="align-items-center d-flex justify-content-center my-2 text-center"
              >
                <Text color={theme.colors.gray}>
                  Não há motoristas cadastrados para esse veículo.
                </Text>
              </Col>
            }
          >
            <Table>
              <thead>
                <tr>
                  <th>
                    <Text weight="500" color="dark">
                      Nome
                    </Text>
                  </th>
                  <th className="text-center">
                    <Text weight="500" color="dark">
                      Ação
                    </Text>
                  </th>
                </tr>
              </thead>
              <tbody>
                {vehicle.data?.drivers
                  ?.sort((x, y) =>
                    x.current === y.current ? 0 : x.current ? -1 : 1
                  )
                  .map((item, index) => (
                    <tr>
                      <td>
                        <Text type="regular" color="dark">
                          {item.social_name}
                        </Text>
                        {item.current && (
                          <Badge
                            pill
                            light
                            variant="success"
                            className="ml-2"
                            size="sm"
                          >
                            <Text color="success" type="label">
                              Atual
                            </Text>
                          </Badge>
                        )}
                      </td>
                      <td width="120" className="text-center">
                        <Button
                          variant="secondary"
                          size="sm"
                          className="mr-1"
                          disabled={vehicle?.data?.vehicleManagement}
                          onClick={() => {
                            openModal(item);
                            vehicle.setData({
                              currentDriverEditing: item,
                            });
                          }}
                        >
                          <FaPencilAlt size={16} color="#464E5F" />
                        </Button>
                        <Button
                          variant="error"
                          size="sm"
                          onClick={() => handleRemove(index, item)}
                          disabled={item.current || vehicle?.data?.vehicleManagement}
                        >
                          <FaTrash size={16} />
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Fallback>
          <Text
            label="Vehicle"
            value={vehicle.data.driver}
            onChange={event => vehicle.setData({ driver: event.target.value })}
          />
          {!vehicle.data?.drivers?.length && (
            <Col
              xs={12}
              className="align-items-center d-flex justify-content-center my-2"
              style={{ gap: '1rem' }}
            >
              <Button
                disabled={vehicle?.data?.vehicleManagement}
                onClick={() => {
                  openModal(null);
                  vehicle.setData({
                    currentDriverEditing: null,
                  });
                }}
              >
                <Text type="regular" weight="500" color="white">
                  Adicionar
                </Text>
              </Button>
              {vehicle?.data?.vehicleManagement && (
                <Tooltip
                  content={
                    <Text type="label" color="white">
                      O veículo está cadastrado na gestão de frota e a edição de alguns campos está restrita ao seu operador.
                      Para mais informações, entre em contato pelo e-mail: coopercarga@coopercarga.com.br
                    </Text>
                  }
                >
                  <FaQuestionCircle style={{ color: theme.colors.light_blue }} />
                </Tooltip>
              )}
            </Col>
          )}
        </Row>
      </Card>
    </>
  );
}

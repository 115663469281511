import styled, { css } from 'styled-components';

import theme from 'styles/theme';

export const Vehicle = styled.div`
  display: flex;

  img {
    object-fit: contain;
    height: 130px;
  }
`;

export const Wagon = styled.div`
  margin-left: 35px;

  img {
    width: 400px;
    height: 140px;
  }
`;

export const Border = styled.div`
  width: 100%;
  height: 20px;
  border: 2px dashed ${theme.colors.medium_gray};
  border-top: none;
  margin: 10px 0;

  ${({ inverted }) =>
    inverted &&
    css`
      transform: rotate(180deg);
    `}
`;

export const TareMass = styled.div`
  width: 80%;
  margin-left: 50px;
  margin-bottom: 10px;
`;

export const Capacity = styled.div`
  width: 104%;
  margin-left: -2%;

  ${({ applyOffset }) =>
    applyOffset &&
    css`
      width: 65%;
      margin-left: 20px;
    `}
`;

export const TotalWeight = styled.div`
  width: 95%;
`;

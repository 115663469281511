/* eslint-disable import/no-cycle */
import React, { useState, useRef } from 'react';
import { useHistory, Link } from 'react-router-dom';
import {
  trackedVehicleRequiredFiels,
  validAnttRegex,
  vehicleRequiredFields,
} from 'utils/constants';

import moment from 'moment';
import { format } from 'date-fns';
import StringBuilder from 'utils/StringBuilder';
import useModal from "hooks/useModal";
import Container from 'react-bootstrap/Container';
import { Form as Unform } from '@unform/web';

import { useSnackbar } from 'components/SnackbarV3';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import { useSelector } from 'react-redux';
import {
  fetchVehiclesForDeals,
  fetchShippingCompanies,
  fetchDrivers
} from 'utils/fetches'

import Address from './Address';
import Header from 'components/Header';
import Text from "components/Text";
import Button from 'components/Button';
import { LoadingPage } from 'components/LoadingPage';

import Select from 'components/Select';
import { useLoad } from 'contexts/load';

import dealApi from 'services/api/deal';

import { validate } from './validator'
import { UpdateVehicleData } from './Modal/UpdateVehicle';

export default function Deal() {
  const formRef = useRef(null);
  const user = useSelector(store => store.auth.user.properties);

  const snackbar = useSnackbar();
  const history = useHistory();

  const [vehicle, setVehicle] = useState()
  const [driver, setDriver] = useState()
  const [shippingCompany, setShippingCompany] = useState()
  const [loading, setLoading] = useState(false)
  const { load, isFetching } = useLoad()
  const [errors, setErrors] = useState({});
  const updateVehicleData = useModal();

  const dealCreateRequest = async (payload) => {
    setLoading(true)
    const {
      user_id,
      load_id,
      vehicle,
      driver,
      shippingCompany } = payload;
    const dataValid = {
      vehicle_id: vehicle?.id,
      shipping_company_id: shippingCompany?.id,
      driver_id_carrier_web: driver?.driverId,
    }

    const isDataValid = await validate(dataValid, setErrors);

    if (!isDataValid) {
      setLoading(false)
      snackbar.show(<Text>Verifique os campos obrigatórios.</Text>, {
        type: 'error',
      });
      return false;
    }

    try {
      const builder = new StringBuilder(' ', '', '.');

      builder.append('Tenho interesse de me candidatar nesta carga, ');
      builder.append(`Com o veiculo placa: ${vehicle?.plate}, `)
      builder.append(`Com o Motorista: ${driver?.socialName}, `)
      builder.append(`Com a transportadora: ${shippingCompany?.socialName}`)

      const response = await dealApi.post('/carrierWebDeals', {
        driver_id: user_id,
        load_id: load_id,
        shipping_company_id: shippingCompany?.id,
        driver_id_carrier_web: driver?.driverId,
        vehicle_id: vehicle?.id,
        message: builder.build()
      });

      snackbar.show(<Text>Negociação iniciada com sucesso</Text>, {
        type: 'success',
      });

      history.push('/negociacoes')

      return response.data

    } catch (error) {
      snackbar.show(<Text>Não foi possivel iniciar a negociação</Text>, {
        type: 'error',
      });
    } finally {
      setLoading(false)
    }
  }

  const handleChangeVehicle = async value => {
    if (value) {
      let updatedVehicle = value;
      setVehicle({
        id: updatedVehicle?.id,
        plate: updatedVehicle?.plate,
        year_manufacture: updatedVehicle?.yearManufacture,
        year_model: updatedVehicle?.yearModel,
        chassi: updatedVehicle?.chassis,
        vehicleType: updatedVehicle?.vehicleType,
        vehicle_type_id: updatedVehicle?.vehicleTypeId,
        // vehicle_implement_type: updatedVehicle?.vehicle_implement_type,
        // implementType: updatedVehicle?.implementType,
        vehicleBodyType: updatedVehicle?.vehicleBodyType,
        vehicle_body_type_id: updatedVehicle?.vehicleBodyTypeId,
        renavam: updatedVehicle?.renavam,
        antt_type: updatedVehicle?.anttType,
        anttType: updatedVehicle?.anttTypes,
        antt: updatedVehicle?.antt,
        antt_adherence: updatedVehicle?.anttAdherence
          ? format(
            new Date(moment(updatedVehicle?.anttAdherence).add(1, 'day')),
            'yyyy-MM-dd'
          )
          : null,
        tracked: updatedVehicle?.tracked,
        tracker_code: updatedVehicle?.trackerCode,
        tracker: updatedVehicle?.tracker,
        tracker_type: updatedVehicle?.trackerType,
        city: updatedVehicle?.cityPlate
          ? {
            id: updatedVehicle?.cityPlate?.id,
            name: updatedVehicle?.cityPlate?.name,
            uf: updatedVehicle?.cityPlate?.province?.uf,
          }
          : null,
        owner_id: updatedVehicle?.ownerId,
        ownerData: updatedVehicle?.owner,
        beneficiary_id: updatedVehicle?.beneficiaryId,
        beneficiary: updatedVehicle?.beneficiary,
        tags: updatedVehicle?.tags || [],
      });

      if (checkVehicleData(updatedVehicle)) {
        let message = 'Cadastro de veículo incompleto'
        snackbar.show(<Text>{message}</Text>, {
          type: 'warning',
        });
        return updateVehicleData.open();
      }
    }
  };

  function checkVehicleData(data) {
    const validation = vehicleRequiredFields.filter(field => !data[field]);

    const anttNumberValidation = validAnttRegex.test(data?.antt);
    let trackValidation = null;
    const shouldValidatedTracker = data?.tracked;
    if (shouldValidatedTracker) {
      trackValidation = trackedVehicleRequiredFiels.filter(
        field => !data[field]
      );
      return (
        validation?.length > 0 ||
        !anttNumberValidation ||
        trackValidation?.length > 0
      );
    }
    return validation?.length > 0 || !anttNumberValidation;
  }

  async function formSubmit() {
    dealCreateRequest({
      load_id: load?.id,
      user_id: user?.id,
      vehicle: vehicle,
      driver: driver,
      shippingCompany: shippingCompany
    })

  }

  const cardLoad = () => {
    return (
      <Unform ref={formRef} onSubmit={formSubmit}>
        <Row>
          <Col xs={12}>
            <Card classname="card-list">
              <Card.Body className="p-5">
                <Row>
                  <Col xs={12}>
                    <h5 className="card-title">Nº da carga - {load?.id}</h5>
                    <hr className="my-3" />
                  </Col>
                  <Col xs={12}>
                    <Select.Async
                      label="Selecione uma transportadora para negociação *"
                      onSearch={value => fetchShippingCompanies(value)}
                      value={shippingCompany}
                      horizontal
                      onChange={value => {
                        if (value) {
                          setShippingCompany(value)
                        } else {
                          setShippingCompany(null)
                        }
                      }}
                      getOptionLabel={option => `${option.socialName} - ${option.cpfCnpj}`}
                      getOptionValue={option => option.id}
                      error={errors.shipping_company_id}
                    />
                    <Select.Async
                      label="Selecione um veículo para negociação *"
                      onSearch={fetchVehiclesForDeals}
                      value={vehicle}
                      horizontal
                      onChange={value => {
                        if (value) {
                          handleChangeVehicle(value)
                        } else {
                          setVehicle(null)
                          handleChangeVehicle(null)
                        }
                      }}
                      getOptionLabel={option => option.plate}
                      getOptionValue={(option) => option.id}
                      error={errors.vehicle_id}
                    />
                    <Select.Async
                      label="Selecione um motorista para negociação *"
                      onSearch={fetchDrivers}
                      value={driver}
                      horizontal
                      onChange={value => {
                        if (value) {
                          setDriver(value)
                        } else {
                          setDriver(null)
                        }
                      }}
                      getOptionLabel={option => option.label}
                      getOptionValue={(option) => option.driverId}
                      error={errors.driver_id_carrier_web}
                    />
                  </Col>
                  <Col xs={12} className="text-right">
                    <Button
                      variant="success"
                      type="submit"
                      className="mt-3 px-3"
                      loading={loading}
                    >
                      Iniciar negociação
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Unform>
    );
  };
  return (
    <>
      <Header
        RightComponent={
          <>
            <Link to="/cargas">
              <Button
                variant="primary"
              >
                <Text weight={500} color="white" type="regular">
                  Voltar
                </Text>
              </Button>
            </Link>
          </>
        } />

      <Container>
        {load ? (
          <Row>
            <Col lg={6} md={6} xs={12}>
              <Address load={load} />
            </Col>
            <Col lg={6} md={6} xs={12}>
              {cardLoad()}
            </Col>
          </Row>) : (
          <LoadingPage isLoading={isFetching} />
        )}
      </Container>

      <UpdateVehicleData
        isOpen={updateVehicleData.isOpen}
        onClose={() => updateVehicleData.close()}
        vehicle={vehicle}
        setVehicle={setVehicle}
      />
    </>
  );
}

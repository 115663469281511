import Types from './types';

const Actions = {
  fetchProvinces: () => ({
    type: Types.FETCH_PROVINCES,
  }),
  fetchProvincesSuccess: provinces => ({
    type: Types.FETCH_PROVINCES_SUCCESS,
    payload: { provinces },
  }),
  fetchProvincesFailure: error => ({
    type: Types.FETCH_PROVINCES_FAILURE,
    payload: { error },
  }),
};

export default Actions;

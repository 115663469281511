import styled, { css } from 'styled-components';

import Row from 'react-bootstrap/Row';

export const CardResume = styled(Row)``;

export const ResumeCnh = styled.div`
  display: flex;
  flex-direction: column;
  ${({ marginRight }) =>
    marginRight &&
    css`
      margin-right: 120px;
    `}
`;

export const ContainerStar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  @media (max-width: 768px) {
    margin-top: 3rem;
  }
`;

export const Image = styled.div`
  background-image: url(${({ url }) => url});
  background-size: cover;
  background-position: center;
  width: 150px;
  height: 150px;
  border-radius: 100%;
  border: 5px solid #f2f2f2;
`;
import styled, { css } from 'styled-components';

const Marker = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 0% 50% 50% 50%;
  ${({ background }) =>
    background &&
    css`
      background: ${background};
    `}
  position: absolute;
  transform: rotate(-135deg);
  transform-origin: top left;
  left: 50%;
  top: 50%;
  margin: 0;

  :after {
    ${({ letter }) =>
    letter &&
    css`
        content: '${letter}';
      `}
    width: 14px;
    height: 14px;
    margin: 10px 0 0 10px;
    position: absolute;
    transform: rotate(135deg);
    border-radius: 50%;
    ${({ color }) =>
    color &&
    css`
        color: ${color};
      `}
    font-size: 14px;
  }
  
  :hover {
    cursor: pointer;
  }
`;

export default Marker;

import React, { useContext, useCallback } from 'react';

import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'components/SnackbarV3';
import Text from 'components/Text';
import { NotificationContext } from './context';
import * as Controller from './controller';

export default function useNotification() {
  const {
    unread,
    setUnread,
    loading,
    setLoading,
    notifications,
    setNotifications,
    pagination,
    setPagination,
  } = useContext(NotificationContext);

  const history = useHistory();
  const snackbar = useSnackbar();
  const fetchNotifications = useCallback(
    async page => {
      try {
        setLoading(true);

        const {
          data,
          pagination: receivedPagination,
        } = await Controller.fetchNotifications(page);

        setPagination(receivedPagination);
        if (receivedPagination.page === 1) {
          setNotifications(data);
        } else {
          setNotifications(previousNotifications => [
            ...previousNotifications,
            ...data,
          ]);
        }
      } catch (ex) {
        // Handle exception
      } finally {
        setLoading(false);
      }
    },
    [setNotifications]
  );

  const fetchUnreadNotifications = useCallback(async () => {
    try {
      const data = await Controller.fetchUnreadNotifications();
      setUnread(!!data);
    } catch (ex) {
      // Handle exception
    }
  }, []);

  const updateNotification = useCallback(
    notification => {
      setNotifications(
        notifications.map(n => (n.id === notification.id ? notification : n))
      );
    },
    [notifications]
  );

  const handleNotificationClick = useCallback(
    async notification => {
      const payload = JSON.parse(notification.url);
      if (payload && payload.url) {
        history.push(`/${payload.url}`);
      } else if (notification.type !== 'APP_NOTIFICATION') {
        snackbar.show(<Text>Notificação sem link</Text>, {
          type: 'error',
        });
      }

      if (!notification.read) {
        try {
          const updatedNotification = await Controller.markNotificationAsRead(
            notification
          );

          updateNotification(updatedNotification);
          fetchUnreadNotifications();
        } catch (ex) {
          // Handle exception
        }
      }
    },
    [history, updateNotification, fetchUnreadNotifications]
  );

  return {
    unread,
    loading,
    notifications,
    pagination,
    setNotifications,
    fetchNotifications,
    fetchUnreadNotifications,
    handleNotificationClick,
  };
}
import React from 'react';

import Text from 'components/Text';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';

export default function PersonalData({ driver, isBrazilian }) {

  function formatDate(date) {
    if (date) return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
    return 'Sem data';
  }

  return (
    <Row>
      <Col xs={12}>
        <Text type="label">Estrangeiro</Text>

        <Text type="regular" color="gray" as="p" className="mt-2">
          {driver && driver.is_international ? 'Sim' : 'Não'}
        </Text>
      </Col>
      <Col xs={12}>
        <Text type="label">Nome</Text>

        <Text type="regular" color="gray" as="p" className="mt-2">
          {driver && driver.social_name}
        </Text>
      </Col>
      <Col lg={6} md={6} xs={12}>
        <Text type="label">{isBrazilian ? 'CPF' : 'Documento'}</Text>

        <Text type="regular" color="gray" as="p" className="mt-2">
          {driver && driver.cgccpf}
        </Text>
      </Col>
      <Col lg={6} md={6} xs={12}>
        <Text type="label">Data de nascimento</Text>

        <Text type="regular" color="gray" as="p" className="mt-2">
          {(driver && formatDate(driver.birthdate)) || '-'}
        </Text>
      </Col>
      <Col xs={12}>
        <Text type="label">Cidade natal</Text>

        <Text type="regular" color="gray" as="p" className="mt-2">
          {driver && driver.born_city ? driver.born_city.name : '-'}
        </Text>
      </Col>
      <Col lg={6} md={6} xs={12}>
        <Text type="label">RG</Text>

        <Text type="regular" color="gray" as="p" className="mt-2">
          {(driver && driver.rg) || '-'}
        </Text>
      </Col>
      <Col lg={6} md={6} xs={12}>
        <Text type="label">Orgão emissor</Text>

        <Text type="regular" color="gray" as="p" className="mt-2">
          {(driver && driver.rg_emissor) || '-'}
        </Text>
      </Col>
      <Col lg={6} md={6} xs={12}>
        <Text type="label">Estado emissor</Text>

        <Text type="regular" color="gray" as="p" className="mt-2">
          {(driver &&
            driver.rg_dispatch_province2 &&
            driver.rg_dispatch_province2.name) ||
            '-'}
        </Text>
      </Col>
      <Col lg={6} md={6} xs={12}>
        <Text type="label">Data de emissão RG</Text>

        <Text type="regular" color="gray" as="p" className="mt-2">
          {(driver && formatDate(driver.rg_dispatch_date)) || '-'}
        </Text>
      </Col>
      <Col xs={12}>
        <Text type="label">Mãe</Text>

        <Text type="regular" color="gray" as="p" className="mt-2">
          {(driver && driver.mothers_name) || '-'}
        </Text>
      </Col>
      <Col xs={12}>
        <Text type="label">Pai</Text>

        <Text type="regular" color="gray" as="p" className="mt-2">
          {(driver && driver.father_name) || '-'}
        </Text>
      </Col>

      <Col lg={6} md={6} xs={12}>
        <Text type="label">Estado civil</Text>

        <Text type="regular" color="gray" as="p" className="mt-2">
          {(driver && driver.civilStatus && driver.civilStatus.name) || '-'}
        </Text>
      </Col>
      <Col lg={6} md={6} xs={12}>
        <Text type="label">PIS</Text>

        <Text type="regular" color="gray" as="p" className="mt-2">
          {(driver && driver.pispasep) || '-'}
        </Text>
      </Col>
      {driver && driver.driverTags.length > 0 && (
        <Col xs={12}>
          <Text type="label">Tags</Text>

          <Text type="regular" color="gray" as="p" className="mt-2">
            {driver.driverTags.map(tag => tag.name).join(', ')}
          </Text>
        </Col>
      )}
    </Row>
  );
}
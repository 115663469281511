import React, { useState } from 'react';

import Input from 'components/Input';
import Button from 'components/Button';
import Text from 'components/Text';
import Select from 'components/Select';
import { Row, Col } from 'react-bootstrap';
import { fetchShippingCompanies } from 'utils/fetches'

function Filter({ fetch }) {
  const [socialName, setSocialName] = useState('');
  const [cgccpf, setCgccpf] = useState('');
  const [shippingCompanies, setShippingCompanies] = useState([])

  return (
    <>
      <form
        onSubmit={event => {
          event.preventDefault();
          fetch({
            name: socialName,
            cgccpf: cgccpf,
            shippingCompanie: shippingCompanies
          });
        }}
      >
        <Row className="filter">
          <Col lg={4} md={4} xs={12}>
            <Input
              label="Nome"
              value={socialName}
              onChange={event => setSocialName(event.target.value)}
              labelColor="#fff"
              placeholder="Digite..."
            />
          </Col>
          <Col lg={2} md={2} xs={12}>
            <Input
              label="CPF"
              value={cgccpf}
              onChange={event => setCgccpf(event.target.value)}
              masked
              guide={false}
              labelColor="#fff"
              mask={[
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                '.',
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                '.',
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                '-',
                /[0-9]/,
                /[0-9]/,
              ]}
              placeholder="Digite..."
            />
          </Col>
          <Col lg={4} md={4} xs={12}>
            <Select.Async
              multiple
              label="Transportadora"
              onSearch={value => fetchShippingCompanies(value)}
              value={shippingCompanies}
              horizontal
              onChange={value => {
                if (value) {
                  setShippingCompanies(value)
                } else {
                  setShippingCompanies([])
                }
              }}
              getOptionLabel={option => `${option.socialName} - ${option.cpfCnpj}`}
              getOptionValue={option => option.id}
              labelColor="#fff"
            />
          </Col>
          <Col lg={2} xs={12} className="vertical bottom">
            <Button type="submit" variant="success" style={{ padding: '12px 30px' }}>
              <Text type="regular">Filtrar</Text>
            </Button>
          </Col>
        </Row>
      </form>
    </>
  );
}

export default Filter;

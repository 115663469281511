export const getTexts = () => {
  return [
    {
      title: "Por que cadastrar meus veículos ou motoristas?",
      text: `
        O cadastro de veículos e motoristas é fundamental para nossa empresa

        1.  Validação de Risco e Cadastral: Os dados inseridos são utilizados para realizar validações de risco e verificações cadastrais, garantindo maior segurança e conformidade em nossas operações.

        2.  Viagens Contempladas: Caso sua empresa seja selecionada para realizar uma viagem conosco, esses dados já estarão prontos para uso, agilizando o processo.

        3.  Interesse de Cargas: Para realizar o interesse de cargas e garantir a continuidade dos serviços, é necessário ter veículos e motoristas cadastrados e disponíveis para utilização.
      `
    },
    {
      title: "Como cadastrar meu veículo ou implemento? ",
      text: `
        Para cadastrar seu veículo ou implemento, siga estes passos:

          1.  No módulo de veículo/implemento, clique em “Novo Veículo/Implemento”.
          2.  Insira as informações solicitadas.

        Tipos de Veículos e Implementos:
          •   Veículos: Exemplos incluem Cavalo Mecânico ou Truck.
          •   Implementos: Exemplos incluem Carreta ou Vanderléia, que são puxados pelo Cavalo Mecânico.

        Campos de Cadastro:
          •   Proprietário: Indique o proprietário do veículo.
          •   Beneficiários: Alocar o administrador do veículo (o CNPJ/CPF que gerencia o veículo e visualiza as viagens).
        
        Informações Adicionais:
          •   Outros transportadores podem ser beneficiários de seus veículos e vice-versa.
          •   Caso o proprietário ou beneficiário do veículo não esteja sob sua gestão, você poderá solicitar a inclusão deles em seu usuário.
          •   Lembre-se que registros de proprietários/beneficiários externos não permitem visualizar viagens ou atender cargas, mas são úteis em casos de diferença de CNPJ/CPF do proprietário/beneficiário.
      `
    },
    {
      title: "Como cadastrar motoristas? ",
      text: `
        O cadastro de motoristas é simples:

          1.  Acesse o módulo "Motoristas".
          2.  Clique em "Novo Motorista".
          3.  Preencha os campos apresentados.
          4.  Clique em "Salvar".
        
        Pronto! O novo motorista estará cadastrado.
      `
    },
    {
      title: "O que representa o módulo de viagens? ",
      text: `
        O módulo de viagens mostra todas as viagens alocadas para as transportadoras que você gerencia.
        Lembre-se de que você pode adicionar alguns CNPJs/CPFs como proprietários ou beneficiários de veículos que não estão sob sua gestão. No entanto, não terá acesso para visualizar as viagens desses outros transportadores.
      `
    },
    {
      title: "O que representa o módulo de cargas e negociações? ",
      text: `
      Módulo de Cargas:
        Este módulo apresenta as cargas disponíveis para carregamento. Nele, você pode:

          1.  Sinalizar interesse em cargas, informando a transportadora (caso você gerencie mais de uma), deverá informar veículos e motorista onde os disponíveis serão os veículos dos quais você é beneficiário. Veículos em que você é proprietário, mas outro CNPJ é beneficiário, não podem ser selecionados.

      Módulo de Negociações:
        Ao sinalizar interesse em uma carga, será habilitado um chat com nossos negociadores. Nesse módulo, você poderá:

          1.  Acordar a rota e obter informações adicionais.
          2.  Negociar o valor do frete e demais despesas relacionadas à viagem.
      `
    },
    {
      title: "Como funciona a fila de veículos? ",
      text: `
        Se você possui veículos ou conjuntos geridos pela Coopercarga, eles serão cadastrados em uma fila de veículos. Isso permite que você saiba a posição do seu veículo na fila para atendimento de carga.
      `
    },
  ]
}
import React, { useState} from 'react';
import { Link } from 'react-router-dom';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Button from 'components/Button';
import { formatDate,formatCustom } from 'utils/formatter';
import Text from 'components/Text';
import * as Styled from './styles';

export default function LoadCard({ load }) {
  const [show, setShow] = useState(false);
  const handleCloseAllDestinations = () => setShow(false);
  const handleShowAllDestinations = () => setShow(true);

  function formatCityProvince(obj) {
    return `${obj.city && obj.province
      ? `${obj.city} - ${obj.province}`
      : obj.city
        ? obj.city
        : obj.province
          ? obj.province
          : 'Sem localização'
      }`;
  }

  function showIntermediateDestinationQuantity(obj) {
    return `${obj.length - 1} destino${obj.length - 1 > 1 ? 's' : ''}`;
  }

  const ModalDestinations = () => (
    <Modal scrollable show={show} onHide={handleCloseAllDestinations}>
      <Modal.Header closeButton>
        <Text type="header" className="ml-3">
          Todos os destinos
        </Text>
      </Modal.Header>
      <Modal.Body>
        <Styled.UnorderedList>
          <Styled.ListItem origin>
            <Text>Origem</Text>
            {load && load.originsList && load.originsList.length > 0 && (
              <>
                <Text color="gray" type="regular">
                  {formatCityProvince(load.originsList[0])}
                </Text>
                <Text color="gray" type="regular">
                  {formatDate(load.originsList[0].scheduled_time)}
                </Text>
              </>
            )}
          </Styled.ListItem>
          {load &&
            load.destinationsList &&
            load.destinationsList.map(destination => (
              <Styled.ListItem destination>
                <Text>
                  {destination.action === 'Delivery' ? 'Entrega' : 'Coleta'}
                </Text>
                <Text color="gray" type="regular">
                  {formatCityProvince(destination)}
                </Text>
                <Text color="gray" type="regular">
                  {formatDate(destination.scheduled_time)}
                </Text>
              </Styled.ListItem>
            ))}
        </Styled.UnorderedList>
      </Modal.Body>
    </Modal>
  );

  return (
    <>
      <ModalDestinations />
      <Col lg={6} md={6} xs={12} className="p-2">
        <Card className="card-list">
          <Card.Body>
            <Row className=" align-items-center">
              <Col>
                <Text>Carga - {load && load.id}</Text>
              </Col>
              <Col style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                <Link to={`/negociacoes/cadastrar/${load.id}`}>
                  {load?.deals?.length > 0 ? (

                    <Button variant="primary" style={{ width: 'max-content' }} disabled>
                      <Text weight={500} color="white" type="regular">
                        Em Negociação
                      </Text>
                    </Button>
                  ) : (
                    <Button variant="primary" style={{ width: 'max-content' }}>
                      <Text weight={500} color="white" type="regular">
                        Tenho Interesse
                      </Text>
                    </Button>)}
                </Link>
                <Link to={`/cargas/${load.id}`}>
                  <Button variant="primary">
                    <Text weight={500} color="white" type="regular">
                      Detalhes
                    </Text>
                  </Button>
                </Link>
              </Col>
            </Row>
            <hr className="mt-2 mb-3" />
            <Row className="p-3">
              <Col xs={4}>
                <Styled.UnorderedList>
                  <Styled.ListItem origin>
                    <Text className="d-block mb-2">Origem</Text>
                    {load.originsList && load.originsList.length > 0 && (
                      <>
                        <Text color="gray" type="regular">
                          {formatCityProvince(load.originsList[0])}
                        </Text>
                        <Text color="gray" type="regular">
                          {formatDate(load.originsList[0].scheduled_time)}
                        </Text>
                      </>
                    )}
                  </Styled.ListItem>
                  {load.destinationsList && load.destinationsList.length > 1 && (
                    <Styled.ListItem destinations>
                      <Text>
                        <Styled.ModalLauncher
                          onClick={handleShowAllDestinations}
                        >
                          {showIntermediateDestinationQuantity(
                            load.destinationsList
                          )}
                        </Styled.ModalLauncher>
                      </Text>
                    </Styled.ListItem>
                  )}
                  <Styled.ListItem destination>
                    <Text>Destino</Text>
                    {load.destinationsList && load.destinationsList.length > 0 && (
                      <>
                        <Text color="gray" type="regular">
                          {formatCityProvince(
                            load.destinationsList[
                            load.destinationsList.length - 1
                            ]
                          )}
                        </Text>
                        <Text color="gray" type="regular">
                          {formatDate(
                            load.destinationsList[
                              load.destinationsList.length - 1
                            ].scheduled_time
                          )}
                        </Text>
                      </>
                    )}
                  </Styled.ListItem>
                </Styled.UnorderedList>
              </Col>
              <Col xs={4}>
                <Text className="d-block">Produto:</Text>
                <Text className="d-block" color="gray" type="regular">
                  {(load && load.productType && load.productType.name) || '-'}
                </Text>
                <Text className="d-block">Peso:</Text>
                <Text className="d-block" color="gray" typ Kge="regular">
                  {(load && load.load_weight) || '-'}
                </Text>
                <Text className="d-block load-label-align mb-1">Valores: </Text>
                <Text className="d-block load-label-align mb-1">A negociar </Text>
              </Col>
              <Col xs={4}>
                <Text className="d-block">Tipo do veículo:</Text>
                <Text className="d-block" color="gray" type="regular">
                  {load &&
                    load.vehicleTypes.map((vehicleTypes, index) => {
                      if (index !== 0) return `, ${vehicleTypes.name}`;
                      return `${vehicleTypes.name}`;
                    })}
                </Text>
                <Text className="d-block">Tipo de carroceria:</Text>
                <Text className="d-block" color="gray" type="regular">
                  {load &&
                    load.bodyTypes.map((bodyType, index) => {
                      if (index !== 0) return `, ${bodyType.name}`;
                      return `${bodyType.name}`;
                    })}
                </Text>
                <Text className="d-block">Empresa:</Text>
                <Text className="d-block" color="gray" type="regular">
                  {load && load?.companyCreated?.name}
                </Text>
              </Col>
            </Row>
            <hr className="mt-2 mb-3" />
            <Row>
              <Col xs={6}>
                <Text className="load-label-align mb-2">Coletar em:</Text>
                <Text color="gray" type="regular" className="mb-1">
                  {' '}
                  {formatDate(
                    load.originsList &&
                    load.originsList.length > 0 &&
                    load.originsList[0].scheduled_time
                  )}
                </Text>
              </Col>
              <Col xs={6} className="text-right">
                {formatCustom(load.created_at, `dd/MM/yyyy 'às' HH:mm`)}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </>
  );
}

import { takeLatest, call, put, all } from 'redux-saga/effects';
// eslint-disable-next-line import/no-cycle
import { persistor } from 'store';
// eslint-disable-next-line import/no-cycle
import api from 'services/api';

import Types from './types';
import Actions from './actions';
import User from '../../models/User';
import axios from 'axios';

export function* validateEmail({ payload }) {
  const { email, formRef } = payload;

  try {
    if (!email) {
      throw new Error();
    }

    yield call(api.post, 'sessions/email', { email });

    yield put(Actions.validateEmailSuccess());
  } catch (ex) {
    let message = 'Você informou um e-mail inválido.';
    if (ex.response) {
      message = ex.response.data.message;
    }

    yield put(Actions.validateEmailFailure(message, formRef));
  }
}

export function validateEmailFailure({ payload }) {
  const { message, formRef } = payload;

  formRef.setErrors({ email: message });
}

export function* signIn({ payload }) {
  const { email, password, formRef, history, from } = payload;

  try {
    const response = yield call(api.post, 'sessions', {
      email,
      password,
    });

    let message = ""

    const userShippingCompanies = response?.data?.user?.shipping_companies
    const slug = 'PORTAL_TRANSPORTADOR'
    const permissionUser = response?.data?.user?.sites.map(site => site.slug)

    if (permissionUser.includes(slug)) {
      const formatData = {
        id: response?.data?.user.id,
        active: response?.data?.user.active,
        change_password: response?.data?.user.change_password,
        last_update_password: response?.data.user.last_update_password,
      }


      localStorage.setItem('token', response?.data?.token?.token);
      localStorage.setItem('user', JSON.stringify(formatData));
      localStorage.setItem('helpNotification', JSON.stringify({notification:true}));

      axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'token'
      )}`;

      const { user: { id, username, authenticated } } = response.data;
      const user = new User({ id: id, username: username, authenticated: authenticated, email: email });

      if (userShippingCompanies.length == 0) {
        message = 'Usuario não possui transportadora cadastrada. Favor entre em contato pelo email suporte.cooperplace@coopercarga.com.br';
        yield put(Actions.signInFailure(message, formRef));
      } else {
        yield put(Actions.signInSuccess(user, { history, from }));
      }
    } else {
      message = 'Usuario não possui permissão para acessar o portal';
      yield put(Actions.signInFailure(message, formRef));
    }

  } catch (ex) {
    let message = 'Senha incorreta.';
    if (ex.response) {
      message = ex.response.data.message;
    }
    yield put(Actions.signInFailure(message, formRef));
  }
}

function signInSuccess({ payload }) {
  const { history, from } = payload;

  history.push(from);
}

function signInFailure({ payload }) {
  const { message, formRef } = payload;

  formRef.setErrors({ password: message });
}

function signOut({ payload }) {
  const { history } = payload;

  history.push('/login');
  persistor.purge();
  localStorage.clear()
}

export default all([
  takeLatest(Types.VALIDATE_EMAIL, validateEmail),
  takeLatest(Types.VALIDATE_EMAIL_FAILURE, validateEmailFailure),
  takeLatest(Types.SIGN_IN, signIn),
  takeLatest(Types.SIGN_IN_SUCCESS, signInSuccess),
  takeLatest(Types.SIGN_IN_FAILURE, signInFailure),
  takeLatest(Types.SIGN_OUT, signOut),
]);

import styled, { css } from 'styled-components';

import ListItem from '@material-ui/core/ListItem';

const StyledListItem = styled(ListItem)`
  font-size: 14px;
  color: #76838f;
  font-family: 'Muli', sans-serif;
  font-weight: 200;
  text-transform: uppercase;

  span:last-child {
    ${({ active }) =>
      active &&
      css`
        border-right: 2px solid;
      `};
  }

  span {
  }
`;

export default StyledListItem;

import Types from './types';

const Actions = {
  validateEmail: (email, formRef) => ({
    type: Types.VALIDATE_EMAIL,
    payload: { email, formRef },
  }),
  validateEmailSuccess: username => ({
    type: Types.VALIDATE_EMAIL_SUCCESS,
    payload: { username },
  }),
  validateEmailFailure: (message, formRef) => ({
    type: Types.VALIDATE_EMAIL_FAILURE,
    payload: { message, formRef },
  }),
  signIn: ({ email, password }, formRef, { history, from }) => ({
    type: Types.SIGN_IN,
    payload: {
      email,
      password,
      formRef,
      history,
      from,
    },
  }),
  signInSuccess: (user, { history, from }) => ({
    type: Types.SIGN_IN_SUCCESS,
    payload: { user, history, from },
  }),
  signInFailure: (message, formRef) => ({
    type: Types.SIGN_IN_FAILURE,
    payload: { message, formRef },
  }),
  signOut: history => ({
    type: Types.SIGN_OUT,
    payload: { history },
  }),
  validateReset: () => ({
    type: Types.VALIDATE_RESET,
  }),
};

export default Actions;

import { takeLatest, call, put, all } from 'redux-saga/effects';

// eslint-disable-next-line import/no-cycle
import dealApi from 'services/api/deal';
import StringBuilder from 'utils/StringBuilder';

import Types from './types';
import Actions from './actions';

function* dealsRequest({ payload }) {
  const { user_id, only_active, only_closed, paginate } = payload;

  try {
    const response = yield call(dealApi.get, '/carrierWebDeals', {
      params: { driver_id: user_id, only_active, only_closed, paginate },
    });
    
    yield put(Actions.dealsSuccess(response));
  } catch (ex) {
    yield put(Actions.dealsFailure());
  }
}
function dealCreateSuccess({ payload }) {
  const { history } = payload;
  setTimeout(() => {
    history.push('/negociacoes');
  }, 2000);
}
function* dealCreateRequest({ payload }) {
  const {
    negotiators_ids,
    user_id,
    load_id,
    vehicle,
    driver,
    shippingCompany } = payload.deal;

  const { history } = payload;

  try {
    const builder = new StringBuilder(' ', '', '.');

    builder.append('Tenho interesse de me candidatar nesta carga, ');
    builder.append(`Com o veiculo placa: ${vehicle?.plate}, `)
    builder.append(`Com o Motorista: ${driver?.socialName[1]}, `)
    builder.append(`Com a transportadora: ${shippingCompany?.socialName}`)

    const response = yield call(dealApi.post, '/carrierWebDeals', {
      negotiators_ids,
      driver_id: user_id,
      load_id,
      shipping_company_id: shippingCompany?.id,
      driver_id_carrier_web: driver?.id,
      vehicle_id: vehicle?.id,
      message: builder.build()
    });

    yield put(Actions.dealCreateSuccess(response.data, history));
  } catch (ex) {
    const retError = ex.response ? ex.response.data : { message: 'Erro' };
    yield put(Actions.dealCreateFailure(retError));
  }
}

export default all([
  takeLatest(Types.DEALS_REQUEST, dealsRequest),
  takeLatest(Types.DEAL_CREATE_REQUEST, dealCreateRequest),
  takeLatest(Types.DEAL_CREATE_SUCCESS, dealCreateSuccess),
]);

// eslint-disable-next-line import/no-cycle

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from "components/Button";
import Container from "react-bootstrap/Container";
import Text from 'components/Text';
import InfiniteScroll from "components/InfiniteScroll";
import Header from "components/Header";
import Filter from "./Filter";
import DriverCard from "./Card";
import * as Controller from "./controller";

export default function Driver() {

  const [params, setParams] = useState({
    name: "",
    document: "",
    shippingCompanie: []
  });

  function fetchDriver(filter) {
    Controller.getDrivers(filter, pagination.page, {
      setDrivers,
      setLoading,
      setPagination,
    });
  }

  useEffect(() => {
    fetchDriver(params);
  }, []);

  const [drivers, setDrivers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    last_page: 1,
    current_page: 1,
    total: 0,
  });
  
  function renderItem(drivers) {
    return <DriverCard driver={drivers} />;
  }

  return (
    <>
      <Header
        RightComponent={
          <>
            <Link to="/motoristas/cadastrar">
              <Button variant="primary" className="mr-2">
                <Text weight={500} color="white" type="regular">
                  Novo motorista
                </Text>
              </Button>
            </Link>
          </>
        }
      />

      <Container>
        <Filter
          fetch={(filter) => {
            setParams(filter);
            Controller.getDrivers(filter, 1, {
              setDrivers,
              setLoading,
              setPagination,
            });
          }}
        />
      </Container>

      <Container>
        <InfiniteScroll
          loading={loading}
          data={drivers}
          renderItem={renderItem}
          onEndReach={() => {
            Controller.getDrivers(params, pagination?.current_page + 1, {
              setDrivers,
              setLoading,
              setPagination,
            });
          }}
          hasMore={pagination?.current_page < pagination?.last_page}
          scrollThreshold={0.7}
        />
      </Container>
    </>
  );
}

import apiCooperplace from 'services/api/cooperplace';
import { validate } from '../shared/validator';

export async function handleCreation(
  data,
  setData,
  setErrors,
  showSnackbar,
  setLoading,
  history
) {
  const isDataValid = await validate('createSchema', data, setErrors);

  if (!isDataValid) {
    showSnackbar('Verifique os campos obrigatórios!', { type: 'error' });
    return false;
  }
  let createdImplementId = null;
  try {
    setLoading(true);
    const payload = {
      plate: data?.plate,
      yearModel: data?.yearModel,
      yearManufacture: data?.yearManufacture,
      chassi: data?.chassi,
      renavam: data?.renavam,
      antt: data?.antt,
      crlv: data?.crlv,
      rntrcExpDate: data?.rntrcExpDate,
      totalGrossWeight: data?.totalGrossWeight,
      tareMass: data?.tareMass,
      cubage: data?.cubage,
      brand: data?.brand?.name,
      color: data?.color?.label,
      idOwner: data?.owner?.id,
      plateCity: data?.city?.name,
      plateState: data?.city?.province?.uf,
      plateCityId: data?.city?.id,
      model: data?.model?.label,
      anttType: data?.anttType?.id,
      manufacturerId: data?.brand?.id,
      anttOwner: data?.anttOwner?.id,
      vehicleTypeId: data?.vehicleType?.id,
      vehicleBodyTypeId: data?.bodyType?.id,
      vehicleImplementTypeId: data?.implementType?.id,
      anttAdherence: data?.anttAdherence,
      beneficiaryId: data?.beneficiary.id
    };

    const response = await apiCooperplace.post('wagons', payload);
    createdImplementId = response.data.id;
    showSnackbar('Implemento cadastrado com sucesso!', { type: 'success' });
  } catch (error) {
    showSnackbar('Erro ao cadastrar implemento!', { type: 'error' });
    if (error.response) {
      const errors = error?.response?.data;
      if (errors && Array.isArray(errors)) {
        setErrors(old => ({
          ...old,
          ...Object.fromEntries(
            errors?.map(error => [error?.field, error?.message])
          ),
        }));
      }
    }
  } finally {
    setLoading(false);
    if (createdImplementId) {
      setTimeout(() => {
        history.push(`/implementos/${createdImplementId}`);
      }, 500);
    } else {
      history.push('/implementos');
    }
  }
}

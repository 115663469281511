import React, { useEffect, useState } from "react";

import InfiniteScroll from "components/InfiniteScroll";
import Text from "components/Text";
import Button from "components/Button";
import VehicleFilter from "./Filter";
import Container from "react-bootstrap/Container";
import CardVehicle from "./Card";
import * as Controller from "./controller";
import Header from "components/Header";
import { Link } from "react-router-dom";

export default function VehicleList() {
  const [finalFilter, setFinalFilter] = useState('/');
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
  const [qtdAdvancedFilters, setQtdAdvancedFilters] = useState(0);

  const [loading, setLoading] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [pagination, setPagination] = useState({
    last_page: 1,
    current_page: 1,
    total: 0,
  });

  function fetchVehicles(filters = {}, page = 0) {
    Controller.getVehicles(filters, page || pagination.page, {
      setVehicles,
      setLoading,
      setPagination,
    });
  }

  const handleClose = () => setShowAdvancedFilters(false);
  const handleShow = () => setShowAdvancedFilters(true);

  function handleTitleAdvancedFilters() {
    let title = "Filtro avançado";
    if (qtdAdvancedFilters) {
      title += ` (${qtdAdvancedFilters})`;
    }
    return title;
  }

  // Ao atualizar o filtro e "filtrar"
  useEffect(() => {
    if (finalFilter) {
      fetchVehicles(finalFilter, 1);
    }
  }, [finalFilter]);

  function renderItem(items) {
    return <CardVehicle vehicle={items} />;
  }

  return (
    <>
      <Header
        title="Veículos"
        RightComponent={
          <>
            <Button variant="secondary" onClick={() => handleShow()}>
              <Text weight={500} color="black" type="regular" >
                {handleTitleAdvancedFilters()}
              </Text>
            </Button>

            <Link to="/veiculos/cadastrar">
              <Button
                variant="primary"
                style={{ marginLeft: "10px",padding:'11px 30px'}}
              >
                <Text weight={500} color="white" type="regular">
                  Novo veículo
                </Text>
              </Button>
            </Link>
          </>
        }
      />

      <Container>
        <VehicleFilter
          setFinalFilter={setFinalFilter}
          showAdvancedFilters={showAdvancedFilters}
          handleClose={handleClose}
          handleShow={handleShow}
          setQtdAdvancedFilters={setQtdAdvancedFilters}
          resultsTotal={pagination.total}
          isFetching={loading && vehicles.length === 0}
        />
      </Container>
      <Container>
        <InfiniteScroll
          loading={loading}
          data={vehicles}
          renderItem={renderItem}
          onEndReach={() => {
            Controller.getVehicles(finalFilter, pagination?.current_page + 1, {
              setVehicles,
              setLoading,
              setPagination,
            });
          }}
          hasMore={pagination?.current_page  < pagination?.last_page}
          scrollThreshold={0.7}
        />
      </Container>
    </>
  );
}

import React from 'react';

import * as Styled from './styles';

function Toggle({
  onSelected,
  options,
  getOptionText = option => option.label,
  getOptionValue = option => option.value,
  multiple,
  defaultValue,
  variant = 'white',
  buttonSize = 'md',
  label,
  labelColor,
  value,
  error = false,
}) {
  const selected = value || defaultValue;

  return (
    <Styled.Container>
      {label && (
        <Styled.Label color={labelColor || ''} type="label">
          {label}
        </Styled.Label>
      )}
      <Styled.ButtonGroup exclusive={!multiple}>
        {options.map(option => (
          <Styled.Button
            onClick={() => onSelected(getOptionValue(option))}
            type={variant}
            value={getOptionValue(option)}
            size={buttonSize}
            className={`${
              getOptionValue(option) === selected ? 'selected' : ''
            }`}
          >
            {getOptionText(option)}
          </Styled.Button>
        ))}
      </Styled.ButtonGroup>
      {error && (
        <Styled.ErrorText color="error" type="little">
          {error}
        </Styled.ErrorText>
      )}
    </Styled.Container>
  );
}

export default Toggle;

import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';

import  useValidation  from 'hooks/useValidation';
import { useSnackbar } from 'components/SnackbarV3';
import Text from 'components/Text';
import { formatNumber } from 'utils/formatter';
import api from 'services/api';

import SharedData from '../shared/Section';
import Inputs from '../shared/DataInputs';
import Editable from '../shared/Editable';
import { dataSchema } from '../shared/validator';
import { useVehicle } from '../shared/context';

export default function Main() {
  const vehicle = useVehicle();
  const snackbar = useSnackbar();
  const [errors, { validate }] = useValidation(dataSchema);

  const [isEditing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    vehicle.setErrors(errors);
  }, [errors]);

  async function saveData() {
    setLoading(true);

    const [isValid, validData] = await validate(vehicle.data);

    if (!isValid) {
      snackbar.show(<Text>Verifique os campos obrigatórios </Text>, {
        type: 'error',
      });
      setLoading(false);
      return;
    }

    const payload = {
      capacity: validData.capacity,
      cubage: validData.cubage,
      exclusive: validData.exclusive,
      total_gross_weight: validData.total_gross_weight,
      tare_mass: validData.tare_mass,
    };

    try {
      await api.put(`v3/vehicle/data/${validData.id}`, payload);
      vehicle.setPrevVehicle(vehicle.data);
      snackbar.show(<Text>Salvo com sucesso</Text>, { type: 'success' });
      vehicle.setErrors({});
    } catch (error) {
      snackbar.show(<Text>Erro ao salvar</Text>, { type: 'error' });
    } finally {
      setEditing(false);
      setLoading(false);
    }
  }

  return (
    <SharedData
      Title="Conjunto"
      HeaderRightComponent={
        <Editable
          isEditing={isEditing}
          setEditing={setEditing}
          onSave={saveData}
          isLoading={loading}
        />
      }
      loading={vehicle.isFetching}
    >
      {isEditing ? (
        <Inputs />
      ) : (
        <Row>
          <Col xs={12} md={6}>
            <Text type="label" color="#464E5F">
              PBT (Peso Bruto total)
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {formatNumber(vehicle.data?.total_gross_weight)} kg
            </Text>
          </Col>
          <Col xs={12} md={6}>
            <Text type="label" color="#464E5F">
              Tara
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {formatNumber(vehicle.data?.tare_mass)} kg
            </Text>
          </Col>
          <Col xs={12} md={6}>
            <Text type="label" color="#464E5F">
              Pallets
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {vehicle.data?.capacity || 'Não informado'}
            </Text>
          </Col>
          <Col xs={12} md={6}>
            <Text type="label" color="#464E5F">
              Cubagem em m³
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {vehicle.data?.cubage
                ? ` ${vehicle.data?.cubage} m³`
                : 'Não informado'}
            </Text>
          </Col>
          <Col xs={12}>
            <Text type="label" color="#464E5F">
              Exclusivo
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {vehicle.data?.exclusive ? 'Sim' : 'Não'}
            </Text>
          </Col>
        </Row>
      )}
    </SharedData>
  );
}

import React, { forwardRef } from 'react';
import Form from 'react-bootstrap/Form';

function StyledInput(
  { label, type, onChange, placeholder, defaultValue, error, ...rest },
  ref
) {
  return (
    <Form.Group>
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Control
        {...rest}
        ref={ref}
        type={type}
        placeholder={placeholder}
        defaultValue={defaultValue}
        isInvalid={!!error}
        onChange={onChange}
      />
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </Form.Group>
  );
}

export default forwardRef(StyledInput);

import produce from 'immer';

import Types from './types';

const INITIAL_STATE = {
  data: [],
  loading: false,
};

export default function provinces(state = INITIAL_STATE, { type, payload }) {
  return produce(state, draft => {
    switch (type) {
      case Types.FETCH_PROVINCES:
        draft.loading = true;
        break;
      case Types.FETCH_PROVINCES_SUCCESS:
        draft.loading = false;
        draft.data = payload.provinces;
        break;
      case Types.FETCH_PROVINCES_FAILURE:
        draft.loading = false;
        break;
      default:
        break;
    }
  });
}

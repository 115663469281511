import produce from 'immer';

import Types from './types';

const INITIAL_STATE = {
  types: {
    data: [],
    loading: false,
  },
  axis: {
    data: [],
    loading: false,
  },
  implements: {
    data: [],
    loading: false,
  },
  bodies: {
    data: [],
    loading: false,
  },
  data: [],
  loading: false,
};

export default function vehicle(state = INITIAL_STATE, { type, payload }) {
  return produce(state, draft => {
    switch (type) {
      case Types.FETCH_VEHICLE_TYPES:
        draft.types.loading = true;
        break;
      case Types.FETCH_VEHICLE_TYPES_SUCCESS:
        draft.types.loading = false;
        draft.types.data = payload.types;
        break;
      case Types.FETCH_VEHICLE_TYPES_FAILURE:
        draft.types.loading = false;
        break;
      case Types.FETCH_VEHICLE_AXIS:
        draft.axis.loading = true;
        break;
      case Types.FETCH_VEHICLE_AXIS_SUCCESS:
        draft.axis.loading = false;
        draft.axis.data = payload.axis;
        break;
      case Types.FETCH_VEHICLE_AXIS_FAILURE:
        draft.axis.loading = false;
        break;
      case Types.FETCH_VEHICLE_IMPLEMENTS:
        draft.implements.loading = true;
        break;
      case Types.FETCH_VEHICLE_IMPLEMENTS_SUCCESS:
        draft.implements.loading = false;
        draft.implements.data = payload.implements;
        break;
      case Types.FETCH_VEHICLE_IMPLEMENTS_FAILURE:
        draft.implements.loading = false;
        break;
      case Types.FETCH_VEHICLE_BODIES:
        draft.bodies.loading = true;
        break;
      case Types.FETCH_VEHICLE_BODIES_SUCCESS:
        draft.bodies.loading = false;
        draft.bodies.data = payload.bodies;
        break;
      case Types.FETCH_VEHICLE_BODIES_FAILURE:
        draft.bodies.loading = false;
        break;
      case Types.FETCH_VEHICLES:
        draft.loading = true;
        break;
      case Types.FETCH_VEHICLES_SUCCESS:
        draft.data = payload.vehicles;
        draft.loading = false;
        break;
      case Types.FETCH_VEHICLES_FAILURE:
        draft.data = [];
        draft.loading = false;
        break;
      default:
        break;
    }
  });
}

export { default as Actions } from './actions';

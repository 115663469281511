import styled from 'styled-components';

import { Snackbar as MaterialSnackbar } from '@material-ui/core';

export const StyledSnackbar = styled(MaterialSnackbar)`
  div {
    background-color: ${({ theme, type }) => theme.colors[type]};
  }
`;

export const Message = styled.span``;

import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import Space from 'components/Space';
import Button from 'components/Button';
import Text from 'components/Text';
import Header from 'components/Header';
import { Data } from './Data';
import { OwnerData } from './Owner';
import { Attachments } from './Attachments';
import Container from "react-bootstrap/Container";

export function ImplementDetails() {
  const history = useHistory();
  const [attachments, setAttachments] = useState([]);

  return (
    <>
      <Header title="Detalhes do implemento"
        RightComponent={
          <Space x="0.5rem">
            <Button variant="secondary" onClick={() => history.goBack()}>
              <Text color="dark" weight={500} type="regular">
                Voltar
              </Text>
            </Button>
            <Link to='/implementos/cadastrar'>
              <Button>
                <Text weight={500} color="white" type="regular" >
                  Novo implemento
                </Text>
              </Button>
            </Link>
          </Space>
        } />
      <Container>
        <Row>
          <Col md={6} xs={12}>
            <Data />
          </Col>
          <Col md={6} xs={12}>
            <OwnerData />
          </Col>
          <Col md={6} xs={12} />
        </Row>
        <Row>
          <Col md={12}>
            <Attachments
              attachments={attachments}
              setAttachments={setAttachments}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}

import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';

import StyledInput from './styled';

export default function Input({
  label,
  name,
  type,
  onChange,
  placeholder,
  inputAs,
  ...rest
}) {
  const inputRef = useRef(null);

  const {
    fieldName,
    defaultValue,
    registerField,
    error,
    clearError,
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  function handleChange() {
    clearError();

    if (onChange) {
      onChange();
    }
  }

  return (
    <StyledInput
      ref={inputRef}
      label={label}
      placeholder={placeholder}
      type={type}
      onChange={handleChange}
      error={error}
      defaultValue={defaultValue}
      {...rest}
    />
  );
}

import React, { useState,useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import Button from "components/Button";
import Text from "components/Text";

import Section from "../shared/Section";
import DataInputs from "../shared/DataInputs";
import MainInputs from "../shared/Main";
import OwnerInputs from "../shared/Owner";
import ImplementsInputs from "../shared/Implements";
import Drivers from "../shared/Drivers";
import TollTags from "./TollTags";
import { useVehicle } from '../shared/context';
import Header from "components/Header";
import Container from "react-bootstrap/Container";
import { useSnackbar } from 'components/SnackbarV3';
import { handleCreation } from "./controller";

export default function VehicleRegister() {
  const history = useHistory();
  const vehicle = useVehicle();
  const snackbar = useSnackbar();

  const [isLoading, setLoading] = useState(false);

  function showSnackbar(text, options) {
    snackbar.show(<Text> {text} </Text>, options);
  }

  return (
    <>
      <Header
        title="Cadastrar veículo"
        RightComponent={
          <>
            <Button
              variant="secondary"
              onClick={() => history.push("/veiculos")}
              className="mr-2"
            >
              <Text weight={500} color="black" type="regular">
                Voltar
              </Text>
            </Button>

            <Button
              variant="success"
              onClick={() => {
                handleCreation(
                  vehicle.data,
                  vehicle.setData,
                  vehicle.setErrors,
                  showSnackbar,
                  setLoading
                );
              }}
              loading={isLoading}
            >
              <Text weight={500} color="white" type="regular">
                Salvar veículo
              </Text>
            </Button>
          </>
        }
      />
      <Container>
        <Row>
          <Col xs={12} md={6}>
            <Section Title="Veículo tração">
              <MainInputs />
            </Section>
          </Col>
          <Col xs={12} md={6}>
            <Section Title="Proprietário">
              <OwnerInputs />
            </Section>
            <Section Title="Implementos">
              <ImplementsInputs />
            </Section>
            <Section Title="Conjunto">
              <DataInputs />
            </Section>
            <Drivers />
            <TollTags />
          </Col>
        </Row>
      </Container>
    </>
  );
}

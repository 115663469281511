import React, { useState, useEffect } from "react";

import InfiniteScroll from "components/InfiniteScroll";
import Text from "components/Text";
import Button from "components/Button";
import Container from "react-bootstrap/Container";
import CardVehicle from "./Card";
import * as Controller from "./controller";
import Header from "components/Header";
import { Link } from "react-router-dom";
import Snackbar from 'components/SnackbarV1';

export default function QueueOfVehicles() {

  const [snackBar, setSnackBar] = useState({
    display: false,
    type: 'error',
    message: '',
  });

  const [loading, setLoading] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [pagination, setPagination] = useState({
    lastPage: 1,
    page: 1,
    total: 0,
  });

  function fetchVehicles() {
    Controller.getVehicles({
      setVehicles,
      setLoading,
      setSnackBar,
    });
  }

  useEffect(() => {
    fetchVehicles();
  }, []);


  function renderItem(items) {
    return <CardVehicle vehicle={items} />
  }
  return (
    <>
      <Snackbar
        open={snackBar.display}
        type={snackBar.type}
        message={snackBar.message}
        onClose={() => setSnackBar(false)}
      />
      <Header
        title="Fila de veículos"
        RightComponent={
          <>
            <Link to="/veiculos/cadastrar">
              <Button
                variant="primary"
                style={{ marginLeft: "10px", padding:'11px 30px'}}
              >
                <Text weight={500} color="white" type="regular">
                  Novo veículo
                </Text>
              </Button>
            </Link>
          </>
        }
      />

      <Container>
        <InfiniteScroll
          loading={loading}
          data={vehicles}
          renderItem={renderItem}
          onEndReach={() => {
            Controller.getVehicles(pagination.page + 1, {
              setVehicles,
              setLoading,
              setPagination,
            });
          }}
          hasMore={pagination.page < pagination.lastPage}
          scrollThreshold={0.7}
        />
      </Container>
    </>
  );
}

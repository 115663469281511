import React from 'react';

import RawInfiniteScroll from 'react-infinite-scroll-component';
import List from 'components/List';

export default function InfiniteScroll({
  data,
  renderItem,
  hasMore,
  onEndReach,
  loading,
  ...props
}) {
  return (
    <RawInfiniteScroll
      {...props}
      hasMore={hasMore}
      next={onEndReach}
      dataLength={data.length}
    >
      <List data={data} loading={loading} renderItem={renderItem} />
    </RawInfiniteScroll>
  );
}
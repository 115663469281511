import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import  {useSnackbar}  from 'components/SnackbarV3';
import Text from 'components/Text';
import apiCooperplace from 'services/api/cooperplace';

import { useImplement } from '../shared/context';
import { Section } from '../shared/Section';
import { OwnerData as Inputs } from '../shared/Owner';

import { Editable } from '../shared/Editable';
import { validate } from '../shared/validator';

export function OwnerData() {
  const implement = useImplement();
  const snackbar = useSnackbar();
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  
  async function saveData() {
    setLoading(true);
    const isDataValid = await validate(
      'owner',
      {
        owner: implement.data?.owner,
        anttOwner: implement.data?.anttOwner,
        beneficiary: implement.data?.beneficiary,
      },
      implement.setErrors
    );

    if (!isDataValid) {
      snackbar.show(<Text>Verifique os campos obrigatórios</Text>, {
        type: 'error',
      });
      setLoading(false);
      return;
    }
    const payload = {
      idOwner: implement.data?.owner?.id,
      anttOwner: implement.data?.anttOwner?.id,
      beneficiaryId: implement.data?.beneficiary?.id,
    };
  
    try {
      await apiCooperplace.put(`wagons/${implement.data.id}`, payload);
      implement.updatePrevImplement(implement.data);
      snackbar.show(<Text>Salvo com sucesso</Text>, { type: 'success' });
    } catch (error) {
      implement.setData(implement.prevImplement);
      snackbar.show(<Text>Erro ao salvar</Text>, { type: 'error' });
    } finally {
      setEditing(false);
      setLoading(false);
    }
  }

  return (
    <Section
      Title="Proprietário"
      HeaderRightComponent={
        <Editable
          isEditing={editing}
          setEditing={setEditing}
          onSave={saveData}
          isLoading={loading}
        />
      }
      loading={implement.loading}
    >
      {editing ? (
        <Inputs />
      ) : (
        <Row>
          <Col xs={12}>
            <Text type="label" color="#464E5F">
              Proprietário do documento
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {implement.data?.owner?.socialName ||
                implement.data?.owner?.social_name ||
                'Não informado'}
              {implement?.data?.owner?.cpfCnpj || implement?.data?.owner?.cgccpf
                ? ` - ${
                    implement?.data?.owner?.cpfCnpj ||
                    implement?.data?.owner?.cgccpf
                  }`
                : ''}
            </Text>
          </Col>
          <Col xs={12}>
            <Text type="label" color="#464E5F">
              Beneficiário do implemento
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {implement.data?.beneficiary?.socialName ||
                implement.data?.beneficiary?.social_name ||
                'Não informado'}
              {implement?.data?.beneficiary?.cpfCnpj || implement?.data?.beneficiary?.cgccpf
                ? ` - ${
                    implement?.data?.beneficiary?.cpfCnpj ||
                    implement?.data?.beneficiary?.cgccpf
                  }`
                : ''}
            </Text>
          </Col>
          <Col xs={12}>
            <Text type="label" color="#464E5F">
              Proprietário RNTRC
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {implement.data?.anttOwner?.socialName ||
                implement.data?.anttOwner?.social_name ||
                'Não informado'}
              {implement?.data?.anttOwner?.cpfCnpj ||
              implement?.data?.anttOwner?.cgccpf
                ? ` - ${
                    implement?.data?.anttOwner?.cpfCnpj ||
                    implement?.data?.anttOwner?.cgccpf
                  }`
                : ''}
            </Text>
          </Col>
        </Row>
      )}
    </Section>
  );
}

import * as Yup from 'yup';
import { isValid, isBefore, parse, addYears } from 'date-fns';
import { cpf } from 'cpf-cnpj-validator';

function validateDocument(document) {
  if (document) {
    const valid = cpf.isValid(document);

    return valid;
  }
  return true;
}

function whiteSpace(value) {
  if (value) {
    const onlySpace = value.replace(/\s/g, '');
    return !!onlySpace;
  }

  return true;
}

function validateDate(value) {
  if (value) {
    const [day, month, year] = value.split('/');

    if (!Number(year) && !Number(month) && !Number(day)) {
      return false;
    }

    const date = parse(
      `${Number(year)}-${Number(month)}-${Number(day)}`,
      'yyyy-MM-dd',
      new Date()
    );

    const valid = isValid(date);

    if (!valid) {
      return false;
    }

    const isGreatter = isBefore(date, new Date());

    return isGreatter;
  }

  return true;
}

function testPhone(value) {
  if (value) {
    const onlyNumber = value.replace(/\D/g, '');

    return onlyNumber.length === 11 || onlyNumber.length === 10;
  }

  return true;
}

function expireDate(value, time) {
  if (value) {
    const maxDate = addYears(new Date(), time);

    const [day, month, year] = value.split('/');

    if (!Number(year) && !Number(month) && !Number(day)) {
      return false;
    }

    const date = parse(
      `${Number(year)}-${Number(month)}-${Number(day)}`,
      'yyyy-MM-dd',
      new Date()
    );

    if (!date) {
      return false;
    }

    return isBefore(date, maxDate);
  }
  return true;
}

export const validator = Yup.object().shape(
  {
    is_international: Yup.boolean(),
    cgccpf: Yup.string()
      .required('Campo obrigatório')
      .when('is_international', {
        is: false,
        then: Yup.string().test(
          'invalid-document',
          'Documento inválido',
          value => validateDocument(value)
        ),
      }),
    social_name: Yup.string()
      .required('Campo obrigatório')
      .test('white-space-field', 'Campo inválido', value => whiteSpace(value)),
    birthdate: Yup.string()
      .typeError('Data inválida')
      .test('invalid-date', 'Data inválida', value => validateDate(value)),
    rg: Yup.string().test('white-space-field', 'Campo inválido', value =>
      whiteSpace(value)
    ),
    rg_emissor: Yup.string().test(
      'white-space-field',
      'Campo inválido',
      value => whiteSpace(value)
    ),
    rg_dispatch_date: Yup.string()
      .typeError('Data inválida')
      .test('invalid-date', 'Data inválida', value => validateDate(value)),
    mothers_name: Yup.string().test(
      'white-space-field',
      'Campo inválido',
      value => whiteSpace(value)
    ),
    father_name: Yup.string()
      .nullable()
      .default(null)
      .test('white-space-field', 'Campo inválido', value => whiteSpace(value)),
    shipping_company_id: Yup.string()
      .required('Campo obrigatório')
      .nullable()
      .default(null)
      .test('white-space-field', 'Campo inválido', value => whiteSpace(value)),
    phone: Yup.string()
      .required('Campo obrigatório')
      .test('valid-phone', 'Telefone inválido', value => testPhone(value)),
    cnh_number: Yup.string().test(
      'white-space-field',
      'Campo inválido',
      value => whiteSpace(value)
    ),
    cnh_key: Yup.string().test('white-space-field', 'Campo inválido', value =>
      whiteSpace(value)
    ),
    cnh_dispatch_city: Yup.number().typeError('Campo obrigatório'),
    first_cnh_date: Yup.string()
      .typeError('Data inválida')
      .test('invalid-date', 'Data inválida', value => validateDate(value)),
    cnh_dispatch_date: Yup.string()
      .typeError('Data inválida')
      .test('invalid-date', 'Data inválida', value => validateDate(value)),
    cnh_exp_date: Yup.string()
      .typeError('Data inválida')
      .test('exp-date', 'Válidade máxima 10 anos', value =>
        expireDate(value, 10)
      ),
    hasMopp: Yup.boolean(),
    mopp_description: Yup.string()
      .default(null)
      .when('hasMopp', {
        is: value => value,
        then: Yup.string(),
      })
      .test('white-space-field', 'Campo inválido', value => whiteSpace(value)),
    mopp_expiration: Yup.string()
      .default(null)
      .when('hasMopp', {
        is: value => value,
        then: Yup.string(),
      })
      .test('exp-date', 'Válidade máxima 5 anos', value =>
        expireDate(value, 5)
      ),
    mopp_date_init: Yup.string()
      .default(null)
      .when('hasMopp', {
        is: value => value,
        then: Yup.string(),
      })
      .test('invalid-date', 'Data inválida', value => validateDate(value)),
    selectedTagList: Yup.array()
      .of(
        Yup.object({
          value: Yup.number().typeError('Campo inválido'),
        })
      )
      .default([]),
    pispasep: Yup.string()
      .nullable()
      .default(null)
      .test('white-space-field', 'Campo inválido', value => whiteSpace(value)),
    paymentMethod: Yup.object()
      .required('Verifique os campos obrigatórios')
      .typeError('Verifique os campos obrigatórios'),
  },

  ['mopp_description', 'mopp_expiration', 'mopp_date_init']
);

export const validatorPhones = Yup.object().shape({
  phone: Yup.string()
    .required('Campo obrigatório')
    .test('valid-phone', 'Telefone inválido', value => testPhone(value)),
  name: Yup.string()
    .required('Campo obrigatório')
    .test('white-space-field', 'Campo inválido', value => whiteSpace(value)),
});

export const ValidatorPaymentsCard = Yup.array().of(
  Yup.object().shape({
    corporation: Yup.object().typeError('Campo obrigatório'),
    card_number: Yup.string(),
    due_date: Yup.string(),
  })
);

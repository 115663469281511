import React, { useState, useEffect } from 'react';

import { Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import Select from 'components/Select';
import api from 'services/api';
import { useVehicle } from '../shared/context';

import apiCooperplace from 'services/api/cooperplace';

export default function VehicleImplements() {
  const vehicle = useVehicle();
  const { vehicle_id } = useParams();

  const [prevImplement, setPrevImplement] = useState(
    vehicle.prevVehicle.implement
  );
  const [implementOptions, setImplementOptions] = useState([]);
  const [bodyOptions, setBodyOptions] = useState([]);

  async function fetchWagons(search) {
    try {
      const response = await apiCooperplace.get(`carrierWeb/wagons/byUserId`, {
        params: { plateWagon: search },
      });

      return response.data.data;
    } catch (error) {
      return [];
    }
  }

  useEffect(() => {
    if (!vehicle_id)
      vehicle.setData({
        implement: '',
        body: '',
        implements: [],
      });
  }, []);

  useEffect(() => {
    async function fetchImplements() {
      const response = await api.get(`vehicle-implement-types?app=${true}`);
      setImplementOptions(
        response.data?.filter(item => item?.name !== 'Dolly')
      );
    }
    fetchImplements();
  }, []);

  useEffect(() => {
    async function fetchBodies() {
      const response = await api.get(`body-types?app=${true}`);
      setBodyOptions(response.data);
    }
    fetchBodies();
  }, []);

  useEffect(() => {
    if (
      !vehicle_id ||
      prevImplement?.id !== vehicle.data.implement?.id ||
      (vehicle?.data?.implement?.id && vehicle?.data?.implements?.length === 0)
    ) {
      const iimplements = new Array(vehicle.data.implement?.qty_plates);
      if (vehicle.data.implement) iimplements.fill({ identifier: '' });
      vehicle.setData({
        implements: iimplements,
      });
    }
  }, [vehicle.data.implement]);

  useEffect(() => {
    if (!vehicle_id) {
      vehicle.setData({ implement: null });
    }
  }, [vehicle.data.type]);

  return (
    <>
      {vehicle.data.type?.qty_plates === 4 ? (
        <Row className="form">
          <Col md={6} xs={12}>
            <Select
              label="Tipo de implemento *"
              value={vehicle.data.implement}
              options={implementOptions}
              getOptionLabel={option =>
                `${option.name} - ${option.qty_axis} eixos`
              }
              getOptionValue={option => option.id}
              onChange={value => {
                setPrevImplement(vehicle.data.implement);
                vehicle.setData({ implement: value });
              }}
              error={vehicle.errors?.implement}
            />
          </Col>
          <Col md={6} xs={12}>
            <Select
              label={'Tipo de carroceria *'}
              value={vehicle.data.body}
              options={bodyOptions}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              onChange={value => vehicle.setData({ body: value })}
              error={vehicle.errors?.body}
            />
          </Col>
          {vehicle.data.implement &&
            vehicle.data.implements?.map((_, index) => (
              <>
                <Col md={6} xs={12}>
                  <Select.Async
                    onSearch={fetchWagons}
                    label={`Placa implemento ${index + 1}`}
                    placeholder=""
                    value={vehicle.data.implements[index]?.identifier}
                    onChange={value => {
                      vehicle.setData({
                        implements: vehicle.data.implements.map((item, idx) => {
                          if (idx === index)
                            return {
                              ...item,
                              identifier: {
                                id: value?.id,
                                plate: value?.plate,
                              },
                            };
                          return item;
                        }),
                      });
                    }}
                    getOptionLabel={option => {
                      let label = option.plate;
                      return label;
                    }}
                    getOptionValue={option => option.id}
                  />
                </Col>
              </>
            ))}
        </Row>
      ) : (
        <Row className="form">
          <Col xs={12}>
            <Select
              label={'Tipo de carroceria *'}
              value={vehicle.data.body}
              options={bodyOptions}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              onChange={value => {
                vehicle.setData({ body: value });
              }}
              error={vehicle.errors?.body}
            />
          </Col>
        </Row>
      )}
    </>
  );
}
import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useParams, } from "react-router-dom";

import { groupBy } from 'utils/array';
import { TravelDetailProvider } from "./context";
import { Col, Row } from "react-bootstrap";
import { HeaderCard } from "./HeaderCard";
import { TravelRouteCard } from "./TravelRouteCard";
import { TravelDocsCard } from "./TravelDocsCard";
import { Button, Card, Text } from "components";
import { FaExclamationCircle } from "react-icons/fa";
import Container from "react-bootstrap/Container";
import Header from "components/Header";
import api from "services/api";
import { formatDate } from 'utils/formatter';
import Load from "./Load";
import Vehicle from "./Vehicle";
import Driver from "./Driver";
import Negotiator from "./Negotiator";
import Map from "../Map"
import { TrackingCard } from "./TrackingCard";
import apiCooperplace from "services/api/cooperplace";

export default function TravelDetail() {
  const params = useParams();
  const history = useHistory();
  const [data, setData] = useState([]);
  const [tracking, setTracking] = useState();
  const [loading, setLoading] = useState(false);
  const [errorFetchingTravel, setErrorFetchingTravel] = useState(false);
  const [feed, setFeed] = useState([]);

  async function fetchTravel() {
    try {
      setLoading(true);
      const response = await api.get(`travel-data/byId/${params.id}`);
      setData(response?.data);
    } catch (error) {
      setErrorFetchingTravel(true);
    } finally {
      setLoading(false);
    }
  }

  async function fetchTracking() {
    try {
      setLoading(true);
      const response = await apiCooperplace.get(`carrierWeb/travel/tracking/${params?.id}`);
      setTracking(response?.data);
    } catch (error) {
      // Handle exception
    } 
  }

  async function fetchFeed() {
    try {
      const response = await api.get('travel-logs', {
        params: { attended_id: params.id },
      });

      const grouped = groupBy(response.data, item => formatDate(item.log_time));
      setFeed(grouped);
    } catch (ex) {
      // Handle exception
    }
  }

  const refetchTravel = useCallback(async () => {
    await fetchTravel();
  }, []);

  useEffect(() => {
    if (params.id) {
      fetchTracking()
      fetchTravel();
      fetchFeed()
    }
  }, [params]);

  if (errorFetchingTravel) {
    return (
      <Container>
        <Card>
          <div className="text-center">
            <FaExclamationCircle
              size={26}
              color="#F64E60"
              className="mr-2 mb-1"
            />
            <Text type="header" color="error">
              {"Erro ao carregar viagem."}
            </Text>
          </div>
        </Card>
      </Container>
    );
  }

  return (
    <TravelDetailProvider
      value={{
        data: data[0],
        tracking: tracking,
        refetchTravel,
        isFetching: loading,
        feed,
      }}
    >
      <Header
        title={"Detalhamento viagem"}
        RightComponent={
          <Button variant={"secondary"} onClick={() => history.goBack()}>
            <Text weight={500}>Voltar</Text>
          </Button>
        }
      />
      <Container>
        <Row className="my-2">
          <Col xs={12}>
            <HeaderCard />
            <TrackingCard />
            <Map />
            <TravelRouteCard />
          </Col>
          <Col md={6} xs={12}>
            <Load />
            <Negotiator />
          </Col>
          <Col md={6} xs={12}>
            <Vehicle />
            <Driver />
          </Col>
          <Col xs={12}>
            <TravelDocsCard />
          </Col>
        </Row>
      </Container>
    </TravelDetailProvider>
  );
}

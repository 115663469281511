import React from 'react';

import theme from 'styles/theme';
import Text from 'components/Text';

import * as Styled from './styles';

function Marker({ text, color, background = theme.colors.primary }) {
  return (
    <Styled.Container background={background}>
      {text && (
        <Text color={color} type="label">
          {text}
        </Text>
      )}
    </Styled.Container>
  );
}

export default Marker;

export default class StringBuilder {
  constructor(delimiter = '', prefix, suffix) {
    this.builder = [];
    this.delimiter = delimiter;
    this.prefix = prefix;
    this.suffix = suffix;
  }

  append(...values) {
    values.filter(value => !!value).forEach(value => this.builder.push(value));

    return this;
  }

  build() {
    let joined = this.builder.join(this.delimiter);

    if (this.suffix) {
      joined = joined.concat(this.suffix);
    }

    if (this.prefix) {
      joined = this.prefix.concat(joined);
    }

    return joined;
  }
}

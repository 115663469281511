import axios from 'axios';

import { getToken } from './auth';

const locationAPI = axios.create({
  baseURL: process.env.REACT_APP_LOCATION_API_URL,
});

locationAPI.interceptors.request.use(config => {
  const outputConfig = config;

  const token = getToken();

  if (token) {
    outputConfig.headers.Authorization = `Bearer ${token}`;
  }

  return outputConfig;
});

export default locationAPI;

import React from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "components/Button";
import Tooltip from "components/Tooltip"
import Text from "components/Text"
import { Link } from "react-router-dom";
import { FaQuestionCircle } from 'react-icons/fa';
import theme from 'styles/theme';

export default function VehileCard({ vehicle }) {

  const handleVehicleInfo = (vehicle) => {
    let info = "";
    if (vehicle.vehicleType) info = vehicle.vehicleType.name;
    if (vehicle.veichleAxi) info += ` | ${vehicle.veichleAxi.name}`;
    if (vehicle.implementType) info += ` | ${vehicle.implementType.name}`;

    return info;
  }

  const buttonDetaisValid = () => {

    const vehicleBeneficiary = vehicle?.beneficiary?.usersShippingCompanies.find(shipping => {
      if (shipping.personId === vehicle?.beneficiaryId) {
        return shipping
      }
    })

    const vehicleOwner = vehicle?.owner?.usersShippingCompanies.find(shipping => {
      if (shipping.personId === vehicle?.ownerId) {
        return shipping
      }
    })

    if (vehicleBeneficiary) {
      return true
    }

    if (!vehicleBeneficiary && vehicleOwner) {
      return false
    }
  }

  return (
    <Col lg={6} md={6} xs={12} className="p-2">
      <Card className="card-list">
        <Card.Body >
          <Row className="p-3" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Col xs="8">
              <span className="card-title">
                <i className="fas fa-truck mr-2" />
                {vehicle.plate === null ? "não encontrada" : vehicle.plate}
              </span>
              <br />
              <span className="card-text">{handleVehicleInfo(vehicle)}</span>
            </Col>
            {
              buttonDetaisValid() ? (
                <Col xs="4" className="text-right">
                  <Link to={`/veiculos/${vehicle.id}`}>
                    <Button variant="primary">Detalhes</Button>
                  </Link>
                </Col>
              ) : (
                <Tooltip
                  content={
                    <Text type="label" color="white">
                      Veículo editável somente pelo beneficiário do mesmo. Para mais informações,
                      entre em contato através do email coopercarga@coopercarga.com.br.
                    </Text>
                  }
                >
                  <FaQuestionCircle style={{ color: theme.colors.light_blue }} />
                </Tooltip>
              )
            }
            <Col xs={12}>
              <hr className="mt-2 mb-3" />
              <span className="card-label">Tipo de carroceria:</span>
              <span className="card-text">
                {vehicle.vehicleBodyType
                  ? vehicle.vehicleBodyType.name
                  : "Sem carroceria cadastrada"}
              </span>
            </Col>
            <Col xs={12}>
              <span className="card-label">Motorista:</span>
              <span className="card-text">
                {vehicle.driverList.length === 0
                  ? "Sem motorista cadastrado"
                  : vehicle.driverList[0].socialName}
              </span>
            </Col>
            <Col xs={12}>
              <span className="card-label">Proprietário:</span>
              <span className="card-text">
                {vehicle?.owner?.socialName}
              </span>
            </Col>
            <Col xs={12}>
              <span className="card-label">Beneficiário:</span>
              <span className="card-text">
                {vehicle?.beneficiary?.socialName || 'Não informado'}
              </span>
            </Col>
            <Col xs={12}>
              <span className="card-label">Beneficiário CNPJ/CPF:</span>
              <span className="card-text">
                {vehicle?.beneficiary?.cpfCnpj}
              </span>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
}

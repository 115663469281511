import React, { forwardRef } from 'react';

import Input from '../Input';
import { StyledCalendarIcon } from './styles';

export const DatePicker = forwardRef(
  (
    {
      disabled,
      placeholder = 'Digite uma data',
      containerStyle,
      onChange,
      onBlur,
      label,
      labelWhiteSpace = 'normal',
      placeholderSize,
      value,
      error,
      guide = false,
      withTime = false,
      required = false,
      ...rest
    },
    ref
  ) => (
    <Input
      ref={ref}
      label={label}
      labelWhiteSpace={labelWhiteSpace}
      placeholderSize={placeholderSize}
      icon={<StyledCalendarIcon />}
      guide={guide}
      value={value}
      required={required}
      disabled={disabled}
      placeholder={placeholder}
      onChange={onChange}
      error={error}
      containerStyle={containerStyle}
      onBlur={onBlur}
      masked
      mask={
        withTime
          ? [
              /[0-9]/,
              /[0-9]/,
              '/',
              /[0-9]/,
              /[0-9]/,
              '/',
              /[0-9]/,
              /[0-9]/,
              /[0-9]/,
              /[0-9]/,
              ' ',
              /[0-9]/,
              /[0-9]/,
              ':',
              /[0-9]/,
              /[0-9]/,
            ]
          : [
              /[0-9]/,
              /[0-9]/,
              '/',
              /[0-9]/,
              /[0-9]/,
              '/',
              /[0-9]/,
              /[0-9]/,
              /[0-9]/,
              /[0-9]/,
            ]
      }
      {...rest}
    />
  )
);

export default DatePicker;

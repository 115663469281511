import Types from './types';

const Actions = {
  fetchVehicleTypes: () => ({
    type: Types.FETCH_VEHICLE_TYPES,
  }),
  fetchVehicleTypesSuccess: types => ({
    type: Types.FETCH_VEHICLE_TYPES_SUCCESS,
    payload: { types },
  }),
  fetchVehicleTypesFailure: error => ({
    type: Types.FETCH_VEHICLE_TYPES_FAILURE,
    payload: { error },
  }),
  fetchVehicleAxis: () => ({
    type: Types.FETCH_VEHICLE_AXIS,
  }),
  fetchVehicleAxisSuccess: axis => ({
    type: Types.FETCH_VEHICLE_AXIS_SUCCESS,
    payload: { axis },
  }),
  fetchVehicleAxisFailure: error => ({
    type: Types.FETCH_VEHICLE_AXIS_FAILURE,
    payload: { error },
  }),
  fetchVehicleImplements: () => ({
    type: Types.FETCH_VEHICLE_IMPLEMENTS,
  }),
  fetchVehicleImplementsSuccess: iimplements => ({
    type: Types.FETCH_VEHICLE_IMPLEMENTS_SUCCESS,
    payload: { implements: iimplements },
  }),
  fetchVehicleImplementsFailure: error => ({
    type: Types.FETCH_VEHICLE_IMPLEMENTS_FAILURE,
    payload: { error },
  }),
  fetchVehicleBodies: () => ({
    type: Types.FETCH_VEHICLE_BODIES,
  }),
  fetchVehicleBodiesSuccess: bodies => ({
    type: Types.FETCH_VEHICLE_BODIES_SUCCESS,
    payload: { bodies },
  }),
  fetchVehicleBodiesFailure: error => ({
    type: Types.FETCH_VEHICLE_BODIES_FAILURE,
    payload: { error },
  }),
  fetchVehicles: params => ({
    type: Types.FETCH_VEHICLES,
    payload: { params },
  }),
  fetchVehiclesSuccess: vehicles => ({
    type: Types.FETCH_VEHICLES_SUCCESS,
    payload: { vehicles },
  }),
  fetchVehiclesFailure: error => ({
    type: Types.FETCH_VEHICLES_FAILURE,
    payload: { error },
  }),
};

export default Actions;

/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import Card from 'components/Card';
import Text from 'components/Text';
import Modal from 'components/Modal';
import Button from 'components/Button';
import List from 'components/BulletList';
import Tooltip from 'components/Tooltip';
import { FaWhatsapp } from 'react-icons/fa';
import * as Controller from './controller';
import * as Styled from './styles';

export default function TravelCard({ travel }) {
  const [show, setShow] = useState(false);

  const handleCloseAllDestinations = () => setShow(false);
  const handleShowAllDestinations = () => setShow(true);

  const ModalDestinations = () => (
    <Modal
      scrollable
      show={show}
      onHide={handleCloseAllDestinations}
      heading={
        <Text type="header" className="ml-3">
          Todos os destinos
        </Text>
      }
      body={
        <Row md={12}>
          <Col md={6} className="pl-5">
            <List items={Controller.locationsObject(true, travel)} />
          </Col>
        </Row>
      }
    />
  );

  const Header = () => (
    <div>
      <Text type="regular" color="gray" className="mr-2">
        Viagem {travel.id}{' '}
        {travel.travel_number &&
          travel.travel_number !== travel.id &&
          `- ${travel.travel_number}`}
      </Text>
      <Styled.Badge pill variant={travel.status.name} className="mr-2">
        <Text type="label">{travel.status.name}</Text>
      </Styled.Badge>
      {travel.firstEmitted_data_emissao && (
        <Styled.Badge pill outlined type="dark" className="mr-2">
          <Text type="label">
            Emitida{' '}
            {moment.utc(travel.firstEmitted_data_emissao).format('DD/MM/YYYY')}
          </Text>
        </Styled.Badge>
      )}
      <Styled.Badge pill outlined type="dark">
        <Text type="label">
          Lançada {moment.utc(travel.created_at).format('DD/MM/YYYY')}
        </Text>
      </Styled.Badge>
    </div>
  );
  return (
    <Col xs={12}>
      <Card
        header={<Header />}
        HeaderRightComponent={
          <div>
            <Tooltip
              content={
                <Text type="label">{travel?.user_created_username}</Text>
              }
              placement="top"
            >
              <Text type="label" color="gray" className="mr-2">
                Criado por {travel?.user_created_username?.split(' ')[0]}{' '}
                {travel?.user_created_username?.split(' ')[1]}
              </Text>
            </Tooltip>

            <Link to={`/viagens/${travel.id}`}>
              <Button className="mr-2 py-2">
                <Text type="regular" weight="500">
                  Detalhes
                </Text>
              </Button>
            </Link>
          </div>
        }
      >
        <ModalDestinations />
        <Row>
          <Col lg={6} md={6} xs={12}>
            <Row>
              <Col xs={12} className="travel-list-align mb-2">
                <Text className="d-block travel-label-align mb-2">Cliente</Text>
                <Text color="gray" type="regular" className="d-block mb-1">
                  {travel.load?.client?.social_name}
                </Text>
                <Text color="gray" type="regular" className="d-block mb-1">
                  {travel.load?.client?.cgccpf}
                </Text>
              </Col>
              <Col xs={12}>
                <List
                  items={Controller.locationsObject(
                    false,
                    travel,
                    handleShowAllDestinations
                  )}
                />
              </Col>
            </Row>
          </Col>
          <Col lg={6} md={6} xs={12}>
            <Row>
              <Col lg={6} md={6} xs={12} className="mb-3">
                <Text className="d-block travel-label-align mb-2">Veículo</Text>
                {travel.vehicleAttended && travel.vehicleAttended.vehicle && (
                  <>
                    <Text color="gray" type="regular" className="d-block mb-1">
                      {travel.vehicleAttended.vehicle.type
                        ? travel.vehicleAttended.vehicle.type.name
                        : 'Não Informado'}
                    </Text>
                    <Text color="gray" type="regular" className="d-block mb-1">
                      {travel.vehicleAttended.vehicle.bodyType
                        ? travel.vehicleAttended.vehicle.bodyType.name
                        : 'Não Informado'}
                    </Text>
                    {travel.vehicleAttended.vehicle.axistype && (
                      <Text
                        color="gray"
                        type="regular"
                        className="d-block mb-1"
                      >
                        {travel.vehicleAttended.vehicle.axistype.name}
                      </Text>
                    )}
                  </>
                )}
                <Text color="gray" type="regular" className="d-block mb-1">
                  {travel.plate || 'Não Informado'}
                </Text>
              </Col>

              <Col lg={6} md={6} xs={12} className="mb-3">
                <Text className="d-block travel-label-align mb-2">
                  Transportadora
                </Text>
                <Text color="gray" type="regular" className="d-block mb-1">
                  {`${travel.shipping_company_name} - ${travel.shipping_company_cgccpf}`}
                </Text>

              </Col>
              <Col lg={6} md={6} xs={12} className="mb-3">
                <Text className="d-block travel-label-align mb-2">
                  Motorista
                </Text>
                <Text color="gray" type="regular" className="d-block mb-1">
                  {travel.driver_name}
                </Text>
                <Col className="d-flex d-row m-0 p-0 align-items-center">
                  <a
                    href={
                      travel.driver_phone_unmasked
                        ? `https://api.whatsapp.com/send?l=pt-BR&phone=${travel.driver_phone_unmasked}`
                        : `https://api.whatsapp.com/send?l=pt-BR&phone=${travel.driver_phone}`
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaWhatsapp color="#00E676" className="mb-2 mr-1" />
                  </a>

                  {travel.driver_phone || travel.driver_phone_unmasked ? (
                    <a
                      href={
                        travel.driver_phone_unmasked
                          ? `https://api.whatsapp.com/send?l=pt-BR&phone=${travel.driver_phone_unmasked}`
                          : `https://api.whatsapp.com/send?l=pt-BR&phone=${travel.driver_phone}`
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Text type="regular" color="gray" as="p" className="mt-2">
                        {travel.driver_phone}
                      </Text>
                    </a>
                  ) : (
                    'Não Informado'
                  )}
                </Col>
              </Col>
              <Col xs={12}>
                {travel.tags && travel.tags.length > 0 && (
                  <div>
                    <Text className="d-block travel-label-align mb-2">
                      Tags
                    </Text>
                    {travel.tags.map(tag => (
                      <div className="mr-2">
                        <Styled.Badge pill variant="secondary">
                          <Text type="label">{tag.name}</Text>
                        </Styled.Badge>
                      </div>
                    ))}
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </Col>
  );
}

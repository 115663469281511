import React from 'react';
import { formatDateTime } from 'utils/formatter';
import { Card, Text } from 'components';
import { StyledTrackDiv, StyledTrackingCol, TrackingContainer } from '../styles';
import { useTravelDetail } from '../context';
import { DefaultLoadingBodyComponent } from 'components/Card';

const collectConcludedStatus = [
  'RELEASED_COLLECTION',
  'ST8',
  'ST3',
  'ST4',
  'ST5',
  'ST6',
  'ENDED_LOADING',
  'ST9',
  'DOCS_ISSUED',
];

const loadingConcludedStatus = [
  'ST3',
  'ST4',
  'ST5',
  'ST6',
  'ST8',
  'ENDED_LOADING',
  'ST9',
  'DOCS_ISSUED',
];

const loadingInProgressStatus = ['RELEASED_COLLECTION'];

const transitConcludedStatus = ['ST3', 'ST4', 'ST5', 'ST6'];

const transitInProgressStatus = ['ST8', 'ENDED_LOADING', 'ST9', 'DOCS_ISSUED'];

const customHouseOriginStatus = ['ST3', 'ST4', 'ST5', 'ST6'];
const customHouseDestinationStatus = ['ST3', 'ST4', 'ST5', 'ST6', 'ST8'];

const dischargingConcludedStatus = ['ST4', 'ST5', 'ST6'];
const dischargingInProgress = ['ST3'];

const concludedStatus = ['ST6'];
const concludedInProgress = ['ST5'];

export function TrackingCard() {
  
  const { tracking, isFetching } = useTravelDetail();

  const hasOriginArrivalTime = tracking?.loads?.loadOrigins?.[0]?.arrivalTime;
  const hasOriginEffectiveTime = tracking?.loads?.loadOrigins?.[0]?.effectiveTime;

  const hasLastDestinationArrivalTime =
    tracking?.loads?.loadDestinations?.[tracking?.loads?.loadDestinations?.length - 1]
      ?.arrivalTime;
  const hasLastDestinationEffectiveTime =
    tracking?.loads?.loadDestinations?.[tracking?.loads?.loadDestinations?.length - 1]
      ?.effectiveTime;
  const isCollectConcluded = collectConcludedStatus.includes(
    tracking?.travelStatus?.code
  );
  const isLoadingConcluded = loadingConcludedStatus.includes(
    tracking?.travelStatus?.code
  );
  const isLoadingInProgress = loadingInProgressStatus.includes(
    tracking?.travelStatus?.code
  );
  const isTransitConcluded = transitConcludedStatus.includes(
    tracking?.travelStatus?.code
  );
  const isInTransitInProgress = transitInProgressStatus.includes(tracking?.travelStatus?.code
  );

  const hasOriginCustomHouse =
    tracking?.tracking?.find(item => item.code === 'custom_house_origin')?.ative ===
    true;
  const hasDestinationCustomHouse =
    tracking?.tracking?.find(item => item.code === 'custom_house_destination')
      ?.ative === true;

  const isDischargingConclued = dischargingConcludedStatus.includes(
    tracking?.travelStatus?.code
  );
  const isDischargingInProgress = dischargingInProgress.includes(
    tracking?.travelStatus?.code
  );
  const isConcluded = concludedStatus.includes(tracking?.travelStatus?.code);
  const isConcludedInProgress = concludedInProgress.includes(
    tracking?.travelStatus?.code
  );
  const collectTracking = tracking?.tracking?.find(item => item.code === 'collect');
  const loadingTracking = tracking?.tracking?.find(item => item.code === 'loading');
  const travelingTracking = tracking?.tracking?.find(
    item => item.code === 'traveling'
  );
  const customHouseOriginTracking = tracking?.tracking?.find(
    item => item.code === 'custom_house_origin'
  );
  const customHouseDestinationTracking = tracking?.tracking?.find(
    item => item.code === 'custom_house_destination'
  );
  const customHouseTravelingTracking = tracking?.tracking?.find(
    item => item.code === 'custom_house_traveling'
  );
  const dischargingTracking = tracking?.tracking?.find(
    item => item.code === 'unloading'
  );
  const finishedTracking = tracking?.tracking?.find(
    item => item.code === 'finished'
  );

  const isCustomHouseOriginConcluded =
    customHouseOriginTracking?.finished &&
    customHouseOriginStatus.includes(tracking?.travelStatus?.code);
  const isCustomHouseOriginInProgress = customHouseOriginStatus.includes(
    tracking?.travelStatus?.code
  );

  const isCustomHouseDestinationConcluded =
    customHouseDestinationTracking?.finished &&
    customHouseOriginStatus.includes(tracking?.travelStatus?.code);
  const isCustomHouseDestinationInProgress =
    customHouseOriginTracking?.finished &&
    customHouseDestinationStatus.includes(tracking?.travelStatus?.code);

  function getInTransitLineIcon() {
    if (hasOriginCustomHouse) {
      if (isTransitConcluded && customHouseOriginTracking?.finished) {
        return 'line-active.svg';
      } else if (isTransitConcluded && isCustomHouseOriginInProgress) {
        return 'line-progress.svg';
      } else {
        return 'line-disabled.svg';
      }
    } else {
      if (isTransitConcluded && isDischargingConclued) {
        return 'line-active.svg';
      } else if (isTransitConcluded && isDischargingInProgress) {
        return 'line-progress.svg';
      } else {
        return 'line-disabled.svg';
      }
    }
  }
  function getCustomHouseOriginLineIcon() {
    if (hasDestinationCustomHouse) {
      if (isCustomHouseOriginConcluded && isCustomHouseDestinationConcluded) {
        return 'line-active.svg';
      } else if (
        isCustomHouseOriginConcluded &&
        isCustomHouseDestinationInProgress
      ) {
        return 'line-progress.svg';
      } else {
        return 'line-disabled.svg';
      }
    } else {
      if (isCustomHouseOriginConcluded && customHouseTravelingTracking?.date) {
        return 'line-active.svg';
      } else if (
        customHouseOriginTracking?.date &&
        customHouseDestinationStatus.includes(tracking.travelStatus?.code)
      ) {
        return 'line-progress.svg';
      } else {
        return 'line-disabled.svg';
      }
    }
  }
  function getCustomHouseDestinationLineIcon() {
    if (customHouseTravelingTracking?.date) {
      return 'line-active.svg';
    } else if (
      isCustomHouseOriginConcluded &&
      isCustomHouseDestinationConcluded
    ) {
      return 'line-progress.svg';
    }
    return 'line-disabled.svg';
  }
  function getCustomHouseTransitLineIcon() {
    if (isDischargingConclued) {
      return 'line-active.svg';
    } else if (
      isDischargingInProgress &&
      customHouseTravelingTracking?.finished
    ) {
      return 'line-progress.svg';
    } else return 'line-disabled.svg';
  }

  const firstCustomHouseLabel = `Aduana ${
    customHouseDestinationTracking?.ative ? 'Origem' : ''
  }`;

  const locationTracking =
  tracking?.location == 0
  ? ' '
  : tracking?.location == 1
  ? 'Localização obtida via rastreador'
  : 'Localização obtida via aplicativo';

  return (
    <Card
      loading={isFetching}
      LoadingBodyComponent={() => (
        <DefaultLoadingBodyComponent linesCount={4} perLine={2} />
      )}
      bodyCardPadding="1rem"
    >
      <Text type="medium" style={{fontStyle: 'italic'}}>{locationTracking}</Text>
      <TrackingContainer>
        <StyledTrackingCol>
          <TrackingItem
            label={'Coleta'}
            icon={`${
              isCollectConcluded
                ? 'icon-collect.svg'
                : 'icon-collect-disabled.svg'
            }`}
            lineIcon={`${
              isCollectConcluded && isLoadingConcluded
                ? 'line-active.svg'
                : isCollectConcluded && isLoadingInProgress
                ? 'line-progress.svg'
                : 'line-disabled.svg'
            }`}
            date={hasOriginArrivalTime ?? collectTracking?.date}
            stepFinished={isCollectConcluded}
          />

          <TrackingItem
            label={'Carregamento'}
            icon={`${
              isLoadingConcluded
                ? 'icon-charging.svg'
                : 'icon-charging-disabled.svg'
            }`}
            lineIcon={`${
              isLoadingConcluded && isTransitConcluded
                ? 'line-active.svg'
                : isLoadingConcluded && isInTransitInProgress
                ? 'line-progress.svg'
                : 'line-disabled.svg'
            }`}
            date={hasOriginEffectiveTime ?? loadingTracking?.date}
            stepFinished={isLoadingConcluded}
          />

          <TrackingItem
            label={'Trânsito'}
            icon={`${
              isTransitConcluded
                ? 'icon-transit.svg'
                : 'icon-transit-disabled.svg'
            }`}
            date={travelingTracking?.date}
            lineIcon={getInTransitLineIcon()}
            stepFinished={isTransitConcluded}
          />

          {hasOriginCustomHouse && (
            <TrackingItem
              label={firstCustomHouseLabel}
              icon={`${
                isCustomHouseOriginConcluded
                  ? 'icon-in-customhouse.svg'
                  : 'icon-in-customhouse-disabled.svg'
              }`}
              date={customHouseOriginTracking?.date}
              lineIcon={getCustomHouseOriginLineIcon()}
              stepFinished={isCustomHouseOriginConcluded}
            />
          )}

          {hasDestinationCustomHouse && (
            <TrackingItem
              label={'Aduana Destino'}
              icon={`${
                isCustomHouseDestinationConcluded
                  ? 'icon-in-customhouse.svg'
                  : 'icon-in-customhouse-disabled.svg'
              }`}
              date={customHouseDestinationTracking?.date}
              lineIcon={getCustomHouseDestinationLineIcon()}
              stepFinished={isCustomHouseDestinationConcluded}
            />
          )}

          {(hasOriginCustomHouse || hasDestinationCustomHouse) && (
            <TrackingItem
              label={'Trânsito'}
              icon={`${
                customHouseTravelingTracking?.finished
                  ? 'icon-transit.svg'
                  : 'icon-transit-disabled.svg'
              }`}
              date={customHouseTravelingTracking?.date}
              lineIcon={getCustomHouseTransitLineIcon()}
              stepFinished={customHouseTravelingTracking?.finished}
            />
          )}

          <TrackingItem
            label={'Descarregamento'}
            icon={`${
              isDischargingConclued
                ? 'icon-discharging.svg'
                : 'icon-discharging-disabled.svg'
            }`}
            date={hasLastDestinationArrivalTime ?? dischargingTracking?.date}
            lineIcon={`${
              isDischargingConclued &&
              (isConcluded || hasLastDestinationEffectiveTime)
                ? 'line-active.svg'
                : isDischargingConclued && isConcludedInProgress
                ? 'line-progress.svg'
                : 'line-disabled.svg'
            }`}
            stepFinished={isDischargingConclued}
          />

          <FinishedDeliveryTrack
            label={'Entrega Concluída'}
            icon={`${
              isConcluded || hasLastDestinationEffectiveTime
                ? 'icon-concluded.svg'
                : 'icon-concluded-disabled.svg'
            }`}
            date={hasLastDestinationEffectiveTime ?? finishedTracking?.date}
            stepFinished={isConcluded || hasLastDestinationEffectiveTime}
          />
        </StyledTrackingCol>
      </TrackingContainer>
    </Card>
  );
}

function TrackingItem({ label, icon, date, lineIcon, stepFinished }) {
  return (
    <StyledTrackDiv>
      <Text type="medium">{label}</Text>
      <div>
        <img
          src={`/images/travel-notify/${icon}`}
        />
        <img
          src={`/images/travel-notify/${lineIcon}`}
        />
      </div>
      {date !== undefined && stepFinished ? (
        <Text type="medium">{formatDateTime(date)}</Text>
      ) : (
        <Text type="medium" style={{ opacity: stepFinished ? 1 : 0 }}>{`${
          stepFinished ? 'Não informado' : '-'
        }`}</Text>
      )}
    </StyledTrackDiv>
  );
}

function FinishedDeliveryTrack({ label, icon, date, stepFinished }) {
  return (
    <StyledTrackDiv>
      <Text type="medium" style={{ whiteSpace: 'nowrap' }}>
        {label}
      </Text>
      <div>
        <img
          src={`/images/travel-notify/${icon}`}
        />
        <img
          style={{ opacity: 0 }}
          src={`/images/travel-notify/line-disabled.svg`}
        />
      </div>
      {date !== undefined && stepFinished ? (
        <Text type="medium">{formatDateTime(date)}</Text>
      ) : (
        <Text type="medium" style={{ opacity: stepFinished ? 1 : 0 }}>{`${
          stepFinished ? 'Não informado' : '-'
        }`}</Text>
      )}
    </StyledTrackDiv>
  );
}

/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';
import {
  Dropdown,
  Nav,
  Col,
  NavLink,
  Collapse,
  Button,
  Row,
} from 'react-bootstrap';
import { FaBell } from 'react-icons/fa';

import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import AuthActions from 'store/modules/auth/actions';
import Notifications, { useNotificationsController } from './Notifications';
import HelpModal from '../HelpModal';
import * as Styled from './styles';

import './style.css';

const links = [
  { name: 'Motoristas', path: '/motoristas' },
  { name: 'Veículos', path: '/veiculos' },
  { name: 'Implementos ', path: '/implementos' },
  { name: 'Viagens', path: '/viagens' },
  { name: 'Cargas', path: '/cargas' },
  { name: 'Negociações', path: '/negociacoes' },
  { name: 'Fila de Veículos', path: '/fila-de-veiculos' },
];

export default function Menu({ path }) {
  const { unread, fetchUnreadNotifications } = useNotificationsController();
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    fetchUnreadNotifications();
  }, []);

  const [open, setOpen] = useState(true);
  const [isOpem, setIsOpem] = useState(false)

  const helpNotification = JSON.parse(localStorage.getItem('helpNotification'))

  function signOut() {
    dispatch(AuthActions.signOut(history));
  }

  const handleClose = () => {
    if (helpNotification?.notification) {
      localStorage.setItem('helpNotification', JSON.stringify({ notification: false }));
    }
    setIsOpem(!isOpem)
  }


  return (
    <>
      <Row>
        <Col className="d-block d-lg-none text-right">
          <Button onClick={() => setOpen(!open)} aria-expanded={open}>
            <i className="fas fa-bars" />
          </Button>
        </Col>

        <Col xs={12}>
          <Collapse in={open} className="nav-header">
            <Nav variant="pills" className="mt-5 mt-md-0 d-lg-block">
              <Row>
                <Col md={10} className="d-block  d-lg-flex">
                  {links.map((link, index) => {
                    const active = path && path.path === link.path;
                    return (
                      <Nav.Item>
                        <NavLink
                          as={Link}
                          to={link.path}
                          key={index}
                          active={active}
                          className="text-uppercase"
                        >
                          {link.name}
                        </NavLink>
                      </Nav.Item>
                    );
                  })}
                </Col>
                <Col
                  md={12}
                  lg={2}
                  className="d-block d-lg-flex justify-content-end"
                >

                  <Nav.Item>
                    <Nav.Link
                      onClick={handleClose}>
                      {helpNotification?.notification && <Styled.NotificationHelp />}
                      <i class="fa fa-question-circle" aria-hidden="true"></i>
                    </Nav.Link>
                  </Nav.Item>
                  <Dropdown>
                    <Dropdown.Toggle
                      as={React.forwardRef(({ onClick }, ref) => (
                        <NavLink ref={ref} onClick={onClick}>
                          <FaBell color="#fff" />
                          {unread && <Styled.NotificationUnreadIcon />}
                        </NavLink>
                      ))}
                    />
                    <Dropdown.Menu as={Notifications} />
                  </Dropdown>

                  <Nav.Item>
                    <NavLink
                      as={Link}
                      onClick={signOut}
                      to="#"
                      className="text-uppercase"
                    >
                      Sair
                    </NavLink>
                  </Nav.Item>
                </Col>
              </Row>
            </Nav>
          </Collapse>
        </Col>
      </Row>
      <HelpModal isOpem={isOpem} handleClose={handleClose} />
    </>
  );
}
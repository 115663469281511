import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useQuery from 'hooks/useQuery';

import Container from "react-bootstrap/Container";

import InfiniteScroll from 'components/InfiniteScroll';
import Header from 'components/Header';
import { useSnackbar } from 'components/SnackbarV3';
import Text from 'components/Text';
import Button from 'components/Button';
import apiCooperplace from 'services/api/cooperplace';
import Space from 'components/Space';

import { ImplementCard } from './Card';
import { ImplementFilter } from './Filter';

export default function ImplementsList() {
  const snackbar = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [allImplements, setAllImplements] = useState([]);
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
  const [qtdAdvancedFilters, setQtdAdvancedFilters] = useState(0);

  const [pagination, setPagination] = useState({
    last_page: 1,
    current_page: 1,
    total: 0,
  });

  const queryParams = useQuery();

  const handleClose = () => setShowAdvancedFilters(false);
  const handleShow = () => setShowAdvancedFilters(true);
  function handleTitleAdvancedFilters() {
    let title = 'Filtro avançado';
    if (qtdAdvancedFilters) {
      title += ` (${qtdAdvancedFilters})`;
    }
    return title;
  }

  async function fetchImplements(page = 1) {
    if (page === 1) {
      setLoading(true);
    }
    try {
      const response = await apiCooperplace.get(`carrierWeb/wagons/byUserId/?${queryParams}&page=${page}`);
      if (response?.data?.data) {
        const { data, meta } = response.data;
        setAllImplements(oldImplements => {
          if (page === 1) {
            return data;
          }
          return [...oldImplements, ...data];
        });
        setPagination(meta);
      } else {
        setAllImplements([]);
        setPagination({
          last_page: 1,
          current_page: 1,
          total: 0,
        });
      }
    } catch (error) {
      if (error?.response?.status !== 401) {
        snackbar.show(
          <Text>
            Não foi possível recuperar os implementos. Tente novamente mais
            tarde
          </Text>,
          {
            type: 'error',
          }
        );
      }
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchImplements();
  }, []);

  function renderItem(item) {
    return <ImplementCard implement={item} key={item.id} />;
  }

  return (
    <>
      <Header title="Implementos"
        RightComponent={
          <Space x={'0.5rem'}>
            <Button variant="secondary" onClick={() => handleShow()}>
              <Text weight={500} color="dark" type="regular">
                {handleTitleAdvancedFilters()}
              </Text>
            </Button>
            <Link to='/implementos/cadastrar'>
              <Button style={{ padding:'11px 30px'}}>
                <Text weight={500} color="white" type="regular">
                  Novo implemento
                </Text>
              </Button>
            </Link>
          </Space>
        } />
      <Container>
        <ImplementFilter
          handleClose={handleClose}
          setQtdAdvancedFilters={setQtdAdvancedFilters}
          showAdvancedFilters={showAdvancedFilters}
          fetchImplements={fetchImplements}
        />
      </Container>
      <Container>
        <InfiniteScroll
          data={allImplements}
          onEndReach={() => fetchImplements(pagination?.current_page + 1)}
          hasMore={pagination?.current_page < pagination?.last_page}
          scrollThreshold={0.7}
          renderItem={renderItem}
          loading={loading}
        />
      </Container>
    </>
  );
}

import React from 'react';

import StyledListItemText from './styles';

function ListItemText({ primary, children, ...rest }) {
  return (
    <StyledListItemText primary={primary} {...rest}>
      {children}
    </StyledListItemText>
  );
}

export default ListItemText;

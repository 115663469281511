import React, {
    useRef,
    useEffect,
    createRef,
    useState,
    useMemo,
    useCallback,
  } from 'react';
  import AsyncReactSelect from 'react-select/async';
  import AsyncCreatableReactSelect from 'react-select/async-creatable';
  import { components } from 'react-select';
  import { Row, Container, Col } from 'react-bootstrap';
  import Modal from 'components/Modal';
  import Text from 'components/Text';
  import Button from 'components/Button';
  
  import * as Styled from '../styles';
  
  export default function AsyncSelect({
    label,
    labelWhiteSpace = 'normal',
    labelColor = '#464e5f',
    error,
    placeholder = 'Selecione...',
    onChange,
    value,
    onSearch,
    multiple,
    creatable,
    inputValue,
    onInputChange,
    onCreateOption,
    disableClear = false,
    modalHeading,
    modalBodyTitle,
    removeInputMin = false,
    options,
    horizontal = false,
    isDisabled = false,
    getOptionLabel = option => option.label,
    getOptionValue = option => option.value,
    components: propsComponents = {},
    NoOptionsComponent = () => <Text>Nenhuma opção encontrada</Text>,
    style,
  }) {
    const inputRef = useRef(null);
    const ref = useRef(null);
    const [parsedValue, setParsedValue] = useState([]);
    const [isBig, setIsBig] = useState(false);
    const [show, setShow] = useState(false);
    const [displayedRefs, setDisplayedRefs] = useState([]);
    const [measurements, setMeasurements] = useState({
      tagsWidth: 0,
      tagsWidthAvg: 0,
      width: 0,
    });
    const [isFocused, setFocused] = useState(false);
  
    useEffect(() => setParsedValue(value || []), [value]);
  
    useEffect(() => {
      if (multiple) {
        setDisplayedRefs(prev =>
          Array(parsedValue.length)
            .fill()
            .map((_, i) => prev[i] || createRef())
        );
      }
    }, [multiple, parsedValue]);
  
    useEffect(() => {
      if (multiple && horizontal) {
        const width = ref.current.offsetWidth;
        const tagsWidth = displayedRefs.reduce(
          (acc, item) => (item.current ? acc + item.current.offsetWidth : acc),
          0
        );
        const tagsWidthCount = displayedRefs.filter(item => item.current).length;
        const tagWidthAvg = tagsWidth / displayedRefs.length - Number(isBig);
        if (
          (tagsWidthCount !== displayedRefs.length &&
            measurements.tagsWidth > tagsWidth) ||
          (displayedRefs.length > 1 && tagsWidth + tagWidthAvg + 30 > width)
        ) {
          setIsBig(true);
        } else {
          setIsBig(false);
        }
  
        if (tagsWidthCount === displayedRefs.length) {
          setMeasurements({ tagsWidth, width, tagWidthAvg });
        }
      }
    }, [displayedRefs, multiple, horizontal]);
  
    const timeout = useRef(null);
    const styledSelect = {
      singleValue: provided => ({
        ...provided,
        color: '#494950',
      }),
      ...style,
    };
  
    function debounceValue(input) {
      if (input.length > 2 || removeInputMin) {
        return new Promise(resolve => {
          if (timeout.current) {
            clearTimeout(timeout.current);
          }
  
          timeout.current = setTimeout(async () => {
            let searched = await Promise.resolve(onSearch(input.trim()));
  
            if (show) {
              searched = searched.filter(
                option =>
                  !parsedValue.some(
                    v => getOptionValue(option) === getOptionValue(v)
                  )
              );
            }
  
            resolve(searched);
          }, 1000);
        });
      }
  
      return new Promise(resolve => resolve());
    }
  
    function addToValue(item) {
      setParsedValue(prev => {
        if (onChange) {
          onChange([...prev, item]);
        }
        return [...prev, item];
      });
    }
  
    function removeValue(item) {
      setParsedValue(prev => {
        if (onChange) {
          onChange(prev.filter(i => getOptionValue(i) !== getOptionValue(item)));
        }
        return prev.filter(i => getOptionValue(i) !== getOptionValue(item));
      });
    }
  
    const customComponents = useMemo(
      () => ({
        Control: props => {
          const { innerRef, innerProps, children, selectProps } = props;
          const customStyle = selectProps.styles?.control?.({});
          return (
            <Styled.Control
              innerRef={innerRef}
              {...innerProps}
              {...props}
              error={error}
              style={customStyle}
            >
              {children}
            </Styled.Control>
          );
        },
        ValueContainer: ({ innerRef, innerProps, children }) => (
          <Styled.ValueContainer
            onClick={event => {
              event.preventDefault();
              if (isBig) {
                setShow(true);
              }
            }}
            ref={ref}
            innerRef={innerRef}
            {...innerProps}
          >
            {children}
            {isBig && (
              <Button variant="secondary" size="sm">
                +
                {displayedRefs.filter(item => item.current).length === 1
                  ? displayedRefs.length - 1
                  : displayedRefs.length - 2 === 0
                  ? displayedRefs.length - 1
                  : displayedRefs.length - 2}
              </Button>
            )}
          </Styled.ValueContainer>
        ),
        Option: props => (isBig ? null : <components.Option {...props} />),
        MultiValue: ({ children, components: Components, data, removeProps }) => {
          const index = parsedValue.findIndex(
            item => getOptionValue(item) === getOptionValue(data)
          );
  
          if (
            isBig &&
            (index >=
              Math.floor(measurements.tagsWidth / measurements.tagWidthAvg - 1) ||
              // TODO: Find some logic to do it better
              index >= 1)
          ) {
            return null;
          }
  
          return (
            <Styled.MultiValue ref={displayedRefs[index]}>
              <Styled.OptionLabel
                type="label"
                maxWidth={measurements.width - (36 + 36 + 38 + 1)}
                className="optionLabel"
              >
                {children}
              </Styled.OptionLabel>
              <Styled.MultiValueRemove
                {...removeProps}
                onClick={event => {
                  event.stopPropagation();
                  removeProps.onClick(event);
                }}
              >
                <Components.Remove />
              </Styled.MultiValueRemove>
            </Styled.MultiValue>
          );
        },
        Input: props =>
          isBig ? null : (
            <components.Input
              {...props}
              autoFocus={isFocused}
              onFocus={() => {
                if (props.onFocus) {
                  props.onFocus();
                }
  
                setFocused(true);
              }}
              onBlur={() => {
                if (props.onBlur) {
                  props.onBlur();
                }
  
                setFocused(false);
              }}
            />
          ),
        Placeholder: ({ innerRef, ...innerProps }) => (
          <Styled.Placeholder innerRef={innerRef} {...innerProps} />
        ),
        Menu: ({ innerRef, ...innerProps }) =>
          isBig ? null : <components.Menu innerRef={innerRef} {...innerProps} />,
        ...propsComponents,
      }),
      [isBig, error, parsedValue, displayedRefs]
    );
  
    const isValidNewOption = useCallback(
      (option, _, availableOptions) => {
        if (!Array.isArray(parsedValue) || !option || option.length < 3) {
          return false;
        }
  
        return ![...parsedValue, ...availableOptions].some(
          item => getOptionLabel(item) === option.trim().toLowerCase()
        );
      },
      [parsedValue, getOptionLabel]
    );
  
    const RenderedReactSelect = useMemo(() => {
      if (creatable) {
        return AsyncCreatableReactSelect;
      }
  
      return AsyncReactSelect;
    }, [creatable]);
  
    useEffect(() => {
      if (show) {
        inputRef.current.focus();
      }
    }, [show]);
  
    const isSelected = useCallback(
      option => {
        if (parsedValue && Array.isArray(parsedValue)) {
          return parsedValue.some(
            selected => getOptionValue(selected) === getOptionValue(option)
          );
        }
  
        return false;
      },
      [parsedValue, getOptionValue]
    );
  
    const modalSelectDefaultOptions = useMemo(() => {
      if (options && Array.isArray(options)) {
        return options.filter(option => !isSelected(option));
      }
  
      return [];
    }, [options, isSelected]);
  
    return (
      <Styled.Container>
        <Modal
          show={show}
          heading={modalHeading}
          animation
          backdrop="static"
          onHide={() => {
            setShow(false);
          }}
          body={
            <div>
              <div className="mb-2">
                <Text weight={500}>{modalBodyTitle} </Text>
              </div>
              <RenderedReactSelect
                ref={inputRef}
                className="pt-3"
                loadOptions={debounceValue}
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue}
                onChange={item => addToValue(item)}
                value={null}
                defaultOptions={modalSelectDefaultOptions}
                noOptionsMessage={NoOptionsComponent}
                loadingMessage={() => 'Carregando...'}
                placeholder={placeholder}
                onInputChange={onInputChange}
                inputValue={inputValue}
                onCreateOption={onCreateOption}
                isValidNewOption={isValidNewOption}
                getNewOptionData={(option, optionLabel) => ({
                  id: optionLabel,
                  name: `Criar ${option}`,
                })}
              />
              <Container fluid className="mt-4">
                <Row>
                  <Col className="d-flex flex-wrap">
                    {multiple &&
                      parsedValue.map(item => (
                        <Styled.MultiValue>
                          <Text type="label">{getOptionLabel(item)}</Text>
                          <Styled.MultiValueRemove
                            onClick={() => removeValue(item)}
                          >
                            <components.MultiValueRemove />
                          </Styled.MultiValueRemove>
                        </Styled.MultiValue>
                      ))}
                  </Col>
                </Row>
              </Container>
            </div>
          }
          footer={
            <Row style={{ justifyContent: 'flex-end' }}>
              <Button
                variant="primary"
                onClick={() => setShow(false)}
                className="mr-2 mt-1 py-2"
              >
                <Text weight="500">Fechar</Text>
              </Button>
            </Row>
          }
        />
  
        {label && (
          <Styled.Label
            color={labelColor}
            type="label"
            weight="500"
            labelWhiteSpace={labelWhiteSpace}
          >
            {label}
          </Styled.Label>
        )}
  
        <RenderedReactSelect
          value={parsedValue}
          styles={styledSelect}
          placeholder={placeholder}
          onChange={onChange}
          loadOptions={debounceValue}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          isClearable={disableClear ? false : !!parsedValue}
          isMulti={multiple}
          isDisabled={isDisabled}
          defaultOptions={options}
          noOptionsMessage={NoOptionsComponent}
          loadingMessage={() => 'Carregando...'}
          onInputChange={onInputChange}
          inputValue={inputValue}
          onCreateOption={onCreateOption}
          isValidNewOption={isValidNewOption}
          getNewOptionData={(option, optionLabel) => ({
            id: optionLabel,
            name: `Criar ${option}`,
          })}
          components={customComponents}
        />
        {error && (
          <Styled.ErrorText color="error" type="little">
            {error}
          </Styled.ErrorText>
        )}
      </Styled.Container>
    );
  }
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import { useSnackbar } from 'components/SnackbarV3';
import Text from 'components/Text';

import apiCooperplace from 'services/api/cooperplace';

import { ImplementProvider } from './context';
import { ImplementRegister as Register } from '../Register';
import { ImplementDetails as Details } from '../Details';
import { format } from 'date-fns';
import moment from 'moment';

export default function ImplementShared() {
  const snackbar = useSnackbar();
  const history = useHistory();

  const [implement, setImplement] = useState({});
  const [prevImplement, setPrevImplement] = useState(implement);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const { implement_id } = useParams()

  function showSnackbar(text, options) {
    snackbar.show(<Text> {text} </Text>, options);
  }

  async function fetchImplement() {
    try {
      setLoading(true);
      const response = await apiCooperplace.get(`wagons/${implement_id}`);
      let data = response.data;
      data = {
        ...data,
        color: { label: data?.color, value: data?.color },
        province: data?.plateState,
        city: {
          name: data?.plateCity,
          province: {
            uf: data?.plateState,
          },
        },
        brand: {
          id: data?.manufacturerId,
          name: data?.brand,
        },
        model: data?.model ? { label: data?.model } : null,
        anttType: { id: data?.anttTypes?.id, name: data?.anttTypes?.name },
        anttOwner: {
          label: data?.ownerAntt?.socialName,
          cpfCnpj: data?.ownerAntt?.cpfCnpj,
          id: data?.ownerAntt?.id,
          socialName: data?.ownerAntt?.socialName,
        },
        owner: {
          label: data?.ownerWagon?.socialName,
          cpfCnpj: data?.ownerWagon?.cpfCnpj,
          id: data?.ownerWagon?.id,
          socialName: data?.ownerWagon?.socialName,
        },
        beneficiary: {
          cpfCnpj: data?.beneficiaryWagon?.cpfCnpj,
          id: data?.beneficiaryWagon?.id,
          socialName: data?.beneficiaryWagon?.socialName,
        },
        anttAdherence: data?.anttAdherence
          ? format(
            new Date(moment(data?.anttAdherence).add(1, 'day')),
            'yyyy-MM-dd'
          )
          : null,
        bodyType: data?.bodyType,
      };
      setImplement(data);
      setPrevImplement(data);
      setLoading(false);
    } catch (error) {
      showSnackbar('Não foi possível carregar esse implemento.', {
        type: 'error',
      });
      setLoading(false);
      history.push('/implementos');
    }
  }

  useEffect(() => {
    if (implement_id) {
      fetchImplement();
    }
  }, [implement_id]);

  function setData(data) {
    setImplement(prev => ({ ...prev, ...data }));
  }

  function updatePrevImplement() {
    setPrevImplement(implement);
  }

  function handleCancel() {
    setImplement(prevImplement);
  }

  return (
    <ImplementProvider
      value={{
        data: implement,
        setData,
        loading,
        errors,
        setErrors,
        handleCancel,
        updatePrevImplement,
        showSnackbar,
        fetchImplement,
        prevImplement,
      }}
    >
      {implement_id ? <Details /> : <Register />}
    </ImplementProvider>
  );
}

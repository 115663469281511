/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaImage, FaPhoneAlt, FaTrash } from "react-icons/fa";

import { useSnackbar } from "components/SnackbarV3";

import {
  fetchCountries,
  fetchAllProvinces,
  fetchCities
} from "utils/fetches";

import Input from "components/Input";
import Text from "components/Text";
import Radio from "components/Radio";
import Table from "components/Table";
import Button from "components/Button";
import Upload from "components/Upload";
import Select from "components/Select";
import DatePicker from "components/DatePicker";
import InputAddress from "components/InputAddress";
import Card, { DefaultLoadingBodyComponent } from "components/Card";
import { BsCalendar } from "react-icons/bs";
import Container from "react-bootstrap/Container";
import Space from "components/Space"

import moment from "moment";
import api from "services/api";
import driverApi from "services/api/driver";
import { InputNumber } from "./styles";

import Resume from "./Resume";
import CnhData from "./CnhData";
import Payment from "./Payment";
import AddressData from "./AddressData";
import PersonalData from "./PersonalData";
import ContactDetails from "./ContactDetails";
import Attachments from "./Attachments";
import ShippingCompany from "./ShippingCompany";
import {
  cnhTypes,
  homeTypes,
  addressTypes,
  civilStatusList,
  cpfMask,
  pisMask,
  phoneMask,
} from "./Types";
import { Header } from "components";

export default function DriverDetails() {
  const params = useParams();

  const dispatch = useDispatch();

  const history = useHistory();

  const snackbar = useSnackbar();

  const [loadingCard, setLoadingCard] = useState(true);

  const [driver, setDriver] = useState();
  const [personalEditing, setPersonalEditing] = useState(false);
  const [cnhEditing, setCnhEditing] = useState(false);
  const [contactsEditing, setContactsEditing] = useState(false);
  const [addressEditing, setAddressEditing] = useState(false);
  const [loadPersonalEditing, setLoadPersonalEditing] = useState(false);
  const [loadCnhEditing, setLoadCnhEditing] = useState(false);
  const [loadContactsEditing, setLoadContactsEditing] = useState(false);
  const [loadAddressEditing, setLoadAddressEditing] = useState(false);

  const [errors, setErrors] = useState({});

  const [socialName, setSocialName] = useState("");
  const [cpf, setCpf] = useState("");
  const [birthdate, setBirthdate] = useState(null);
  const [birthCity, setBirthCity] = useState("");
  const [rg, setRg] = useState("");
  const [issuingBody, setIssuingBody] = useState("");
  const [issuingState, setIssuingState] = useState("");
  const [issuingDate, setIssuingDate] = useState("");
  const [motherName, setMotherName] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [pis, setPis] = useState("");
  const [isInternational, setIsInternational] = useState(false);
  const [civilStatus, setCivilStatus] = useState("");
  const [cnhNumber, setCnhNumber] = useState("");
  const [cnhType, setCnhType] = useState(null);
  const [cnhIssuingCity, setCnhIssuingCity] = useState("");
  const [firstCnhDate, setFirstCnhDate] = useState(null);
  const [cnhDispatchDate, setCnhDispatchDate] = useState(null);
  const [cnhDueDate, setCnhDueDate] = useState(null);
  const [hasMopp, setHasMopp] = useState(true);
  const [moppDescription, setMoppDescription] = useState("");
  const [moppDueDate, setMoppDueDate] = useState(null);
  const [moppInitialDate, setMoppInitialDate] = useState(null);
  const [frontImageCnh, setFrontImageCnh] = useState("");
  const [backImageCnh, setBackImageCnh] = useState("");
  const [cnhKey, setCnhKey] = useState("");
  const [isExclusive, setIsExclusive] = useState(false);
  const [isAutonomous, setIsAutonomous] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [isCnhInternational, setIsCnhInternational] = useState(false);

  const [mainPhone, setMainPhone] = useState("");
  const [email, setEmail] = useState("");
  const [otherName, setOtherName] = useState("");
  const [otherPhone, setOtherPhone] = useState("");
  const [otherContacts, setOtherContacts] = useState([]);

  const [address, setAddress] = useState("");
  const [number, setNumber] = useState("");
  const [complement, setComplement] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [cep, setCep] = useState("");
  const [city, setCity] = useState("");
  const [homeType, setHomeType] = useState("");
  const [addressType, setAddressType] = useState("");
  const [numberless, setNumberless] = useState(false);
  const [ufName, setUfName] = useState("");
  const [uf, setUf] = useState("");
  const [countries, setCountries] = useState();
  const [country, setCountry] = useState();

  const [selectedTags, setSelectedTags] = useState("");
  const [provinces, setProvinces] = useState([]);

  async function getCountry(country_id = 1) {
    const response = await api.get(`countries/${country_id}`);
    setCountry(response.data[0]);
  }
  const [attachments, setAttachments] = useState([]);

  function validatePersonalData() {
    const err = {};
    if (socialName) {
      if (socialName.length < 3) {
        err.socialName = "É necessário pelo menos 3 caracteres.";
      }
    } else err.socialName = "Insira um nome.";

    if (!isInternational) {
      if (cpf) {
        const cpfWithRegex = cpf?.match(/\d/g)?.join("");
        if (cpfWithRegex.length !== 11) err.cpf = "CPF inválido.";
      } else err.cpf = "Insira um CPF.";

      if (rg) {
        const rgWithRegex = rg?.match(/\d/g)?.join("");
        if (rgWithRegex.length < 7) err.rg = "RG inválido, dígito faltante.";
      } else err.rg = "Insira um RG.";

      if (issuingBody) {
        if (issuingBody.length < 1)
          err.issuingBody = "Insira um Orgão Emissor.";
      } else err.issuingBody = "Insira um Orgão Emissor.";
    }

    if (birthdate) {
      const date = moment(birthdate, "DD/MM/YYYY", true);
      if (!date.isValid()) err.birthdate = "Data Inválida.";
    } else err.birthdate = "Insira uma data de nascimento.";

    if (!civilStatus) {
      err.civilStatus = "Escolha um estado civil.";
    }

    if (motherName) {
      if (motherName.length < 3)
        err.motherName = "É necessário pelo menos 3 caracteres.";
    } else err.motherName = "Insira o nome da mãe.";

    setErrors(err);
    return !Object.keys(err).length;
  }

  function fillEditValues(driver, currentEdit) {
    try {
      // ---> Personal Data <---
      setIsInternational(
        driver.is_international ? driver.is_international : false
      );
      setSocialName(driver.social_name);
      setCpf(driver.cgccpf);
      setBirthdate(
        driver.birthdate
          ? moment(driver.birthdate, "YYYY-MM-DD").format("DD/MM/YYYY")
          : ""
      );
      setBirthCity(driver.born_city); // Objeto e precisa de async select
      setRg(
        driver?.rg?.match(/\d/g) &&
        driver?.rg?.match(/\d/g).join("").substring(0, 7)
      );
      setIssuingBody(driver.rg_emissor);
      setIssuingState(
        driver.rg_dispatch_province2
          ? {
            name: driver.rg_dispatch_province2.name,
            id: driver.rg_dispatch_province2.id,
          }
          : null
      ); // Obj etc etc
      setIssuingDate(
        driver.rg_dispatch_date
          ? moment(driver.rg_dispatch_date, "YYYY-MM-DD").format("DD/MM/YYYY")
          : ""
      );
      setCivilStatus(
        driver.civilStatus
          ? { label: driver.civilStatus.name, value: driver.civilStatus.id }
          : null
      );
      setMotherName(driver.mothers_name);
      setFatherName(driver.father_name);
      setPis(driver.pispasep);
      setSelectedTags(driver.driverTags || []);

      // ---> CNH <---
      setIsCnhInternational(
        driver.cnh_is_foreign ? driver.cnh_is_foreign : false
      );
      setCnhNumber(() => {
        if (driver?.cnh_number && driver?.cnh_number !== undefined) {
          return driver?.cnh_number?.match(/\d/g)?.join("");
        }
      });
      setCnhIssuingCity(driver.cnh_city); // Objeto e precisa de async select
      setCnhType({ label: driver.cnh_type });
      setFirstCnhDate(
        driver.first_cnh_date
          ? moment(driver.first_cnh_date, "YYYY-MM-DD").format("DD/MM/YYYY")
          : ""
      );
      setCnhDispatchDate(
        driver.cnh_dispatch_date
          ? moment(driver.cnh_dispatch_date, "YYYY-MM-DD").format("DD/MM/YYYY")
          : ""
      );
      setCnhDueDate(
        driver.cnh_exp_date
          ? moment(driver.cnh_exp_date, "YYYY-MM-DD").format("DD/MM/YYYY")
          : ""
      );
      setCnhKey(driver.cnh_key);
      setMoppDescription(driver.mopp_description);
      setMoppDueDate(
        driver.mopp_expiration
          ? moment(driver.mopp_expiration, "YYYY-MM-DD").format("DD/MM/YYYY")
          : ""
      );
      setMoppDueDate(
        driver.mopp_date_init
          ? moment(driver.mopp_date_init, "YYYY-MM-DD").format("DD/MM/YYYY")
          : ""
      );
      setIsExclusive(!!driver.exclusive); // tratar no backend, caso enviar TRUE salvar company_id no lugar
      setIsAutonomous(driver.is_autonomous);
      setIsOwner(driver.is_owner);

      // ---> Contacts <---
      setMainPhone(driver.phone);
      setEmail(driver.email);
      setOtherContacts(driver.phones || []);

      // ---> Address <---
      setAddress(driver.addresses[0]?.address || driver?.address_address);
      getCountry(driver.addresses[0]?.country_id || driver?.country_id);

      if (
        (driver.addresses[0]?.city_id &&
          driver.addresses[0]?.city_name &&
          driver.addresses[0]?.province_id &&
          driver.addresses[0]?.uf_name) ||
        (driver.address_city_id &&
          driver.address_city_name &&
          driver.address_city_province_id &&
          driver.address_province_uf)
      ) {
        setCity({
          id: driver.addresses[0]?.city_id || driver.address_city_id,
          name: driver.addresses[0]?.city_name || driver?.city_name,
          province: {
            id:
              driver.addresses[0]?.province_id ||
              driver.address_city_province_id,
            uf: driver.addresses[0]?.uf_name || driver?.address_province_uf,
          },
        });
      }

      setUfName(driver.addresses[0]?.uf_name);
      if (driver.address_number || driver.addresses[0].number) {
        setNumberless(false);
      } else {
        setNumberless(true);
      }
      setNumber(driver.addresses[0]?.number || driver?.address_number || "");
      setComplement(
        driver.addresses[0]?.complement || driver?.address_complement
      );
      setNeighborhood(
        driver.addresses[0]?.neighborhood || driver?.address_neighborhood
      );
      setCep(driver.addresses[0]?.zip_code || driver?.address_zip_code);
      setHomeType({ label: driver.address_property_type || "Outros" });
      setAddressType({ label: driver.address_property_status || "Outros" });
    } catch (e) {
      // error
    } finally {
      switch (currentEdit) {
        case "personal":
          setPersonalEditing(false);
          setLoadPersonalEditing(false);
          break;
        case "cnh":
          setCnhEditing(false);
          setLoadCnhEditing(false);
          break;
        case "contact":
          setContactsEditing(false);
          setLoadContactsEditing(false);
          break;
        case "address":
          setAddressEditing(false);
          setLoadAddressEditing(false);
          break;
        default:
          break;
      }
    }
  }

  async function getDriver(currentEdit = "") {
    const response = await api.get(`persons/${params.driver_id}`);
    setDriver(response.data);
    fillEditValues(response.data, currentEdit);
    setLoadingCard(false);
  }

  async function savePersonalData() {
    try {
      if (!validatePersonalData()) return;
      setLoadPersonalEditing(true);
      const bodyUpdatePersonal = {
        social_name: socialName.replace(/\s+/g, " ").trim(),
        civil_state_id: civilStatus && civilStatus.value,
        cgccpf: cpf,
        birthdate: birthdate
          ? moment(birthdate, "DD/MM/YYYY").format("YYYY-MM-DD")
          : null,
        born_city_id: birthCity && birthCity.id,
        born_province_id: birthCity && birthCity.province.id,
        rg,
        rg_emissor: issuingBody,
        rg_dispatch_province: issuingState && issuingState.id,
        rg_dispatch_date: issuingDate
          ? moment(issuingDate, "DD/MM/YYYY").format("YYYY-MM-DD")
          : null,
        mothers_name: motherName,
        father_name: fatherName,
        pispasep: pis,
        is_international: isInternational,
        selected_tags: selectedTags ? selectedTags.map((x) => x.id) : null,
      };

      const res = await api.put(
        `driver/updatePersonalData/${params.driver_id}`,
        bodyUpdatePersonal
      );

      if (res.status === 200) {
        await getDriver("personal");
      }
    } catch (e) {
      snackbar.show(
        <Text>
          {e.response?.data[0]?.message ||
            "Algo deu errado, tente novamente mais tarde!"}
        </Text>,
        {
          type: "error",
        }
      );
      setLoadPersonalEditing(false);
    }
  }

  function validateCnhData() {
    const err = {};
    if (cnhNumber) {
      if (cnhNumber.length < 3) {
        err.cnhNumber = "É necessário pelo menos 3 caracteres.";
      }
      if (!/^\d+$/.test(cnhNumber)) {
        err.cnhNumber = "Insira apenas números.";
      }
    } else err.cnhNumber = "Insira um nome.";

    if (!cnhIssuingCity) err.cnhIssuingCity = "Escolha uma cidade.";

    if (firstCnhDate) {
      const date = moment(firstCnhDate, "DD/MM/YYYY", true);
      if (!date.isValid()) err.firstCnhDate = "Data Inválida.";
    } else err.firstCnhDate = "Insira a data da 1° habilitação.";

    if (cnhDispatchDate) {
      const date = moment(cnhDispatchDate, "DD/MM/YYYY", true);
      if (!date.isValid()) {
        err.cnhDispatchDate = "Data inválida.";
      }
    }

    if (cnhDueDate) {
      const date = moment(cnhDueDate, "DD/MM/YYYY", true);
      if (!date.isValid()) err.cnhDueDate = "Data Inválida.";
    } else err.cnhDueDate = "Insira a data de validade da habilitação.";

    if (hasMopp) {
      if (moppDescription) {
        if (moppDescription.length < 3)
          err.moppDescription = "É necessário pelo menos 3 caracteres.";
      } else err.moppDescription = "Insira uma descrição de mopp.";

      if (moppDueDate) {
        const date = moment(moppDueDate, "DD/MM/YYYY", true);
        if (!date.isValid()) err.moppDueDate = "Data Inválida.";
      } else err.moppDueDate = "Insira a data de validade do mopp.";

      if (moppInitialDate) {
        const date = moment(moppInitialDate, "DD/MM/YYYY", true);
        if (!date.isValid()) err.mopp_date_init = "Data Inválida.";
      } else err.moppInitialDate = "Insira a data do primeiro mopp.";
    }

    setErrors(err);
    return !Object.keys(err).length;
  }

  async function uploadFile(cnhFile, driverId, side) {
    const data = new FormData();
    data.append("image_cnh", cnhFile);

    try {
      const response = await driverApi.post(
        `/driver/${driverId}/${side}`,
        data
      );

      return response.data;
    } catch (err) {
      return null;
    }
  }
  async function saveCnhData() {
    try {
      if (!validateCnhData()) return;
      setLoadCnhEditing(true);
      const bodyUpdateCnh = {
        cnh_number: cnhNumber,
        cnh_type: cnhType && cnhType.label,
        cnh_key: cnhKey,
        cnh_dispatch_city: cnhIssuingCity && cnhIssuingCity.id,
        cnh_dispatch_state: cnhIssuingCity && cnhIssuingCity.province.id,
        first_cnh_date: firstCnhDate
          ? moment(firstCnhDate, "DD/MM/YYYY").format("YYYY-MM-DD")
          : null,
        cnh_dispatch_date: cnhDispatchDate
          ? moment(cnhDispatchDate, "DD/MM/YYYY").format("YYYY-MM-DD")
          : null,
        cnh_exp_date: cnhDueDate
          ? moment(cnhDueDate, "DD/MM/YYYY").format("YYYY-MM-DD")
          : null,
        mopp: hasMopp,
        mopp_description: hasMopp ? moppDescription : null,
        mopp_expiration: hasMopp
          ? moment(moppDueDate, "DD/MM/YYYY").format("YYYY-MM-DD")
          : null,
        mopp_date_init: hasMopp
          ? moment(moppInitialDate, "DD/MM/YYYY").format("YYYY-MM-DD")
          : null,
        exclusive: isExclusive,
        is_autonomous: isAutonomous,
        is_owner: isOwner,
        cnh_is_foreign: isCnhInternational,
      };

      const res = await api.put(`driver/updateCnh/${params.driver_id}`, bodyUpdateCnh);

      if (frontImageCnh) {
        await uploadFile(frontImageCnh[0], params.driver_id, "front_cnh");
      }
      if (backImageCnh) {
        await uploadFile(backImageCnh[0], params.driver_id, "back_cnh");
      }

      if (res.status === 200) {
        getDriver("cnh");
        setFrontImageCnh("");
        setBackImageCnh("");
      }
    } catch (e) {
      setLoadCnhEditing(false);
    }
  }

  async function saveContactData() {
    try {
      setLoadContactsEditing(true);
      const bodyUpdatePersonal = {
        phone: mainPhone,
        email,
        others: otherContacts,
      };

      const res = await api.put(
        `driver/updateContacts/${params.driver_id}`,
        bodyUpdatePersonal
      );

      if (res.status === 200) {
        getDriver("contact");
      }
    } catch (e) {
      setLoadContactsEditing(false);
    }
  }

  function validateAddressData() {
    const err = {};
    if (address) {
      if (address.length < 3) {
        err.address = "É necessário pelo menos 3 caracteres.";
      }
    } else err.address = "Insira um endereço.";

    if (!numberless) {
      if (number) {
        if (!/^\d+$/.test(number)) err.number = "Insira apenas números.";
      } else err.number = "Insira o número da rua.";
    }

    if (!neighborhood) err.neighborhood = "Insira um bairro.";

    if (!cep) err.cep = "Insira o CEP.";

    if (!city) err.city = "Escolha uma cidade.";

    setErrors(err);
    return !Object.keys(err).length;
  }

  async function saveAddressData() {
    if (!validateAddressData()) return;

    try {
      setLoadAddressEditing(true);
      const bodyUpdateAddress = {
        address,
        number: !numberless ? number : null,
        complement,
        neighborhood,
        zip_code: cep,
        country_id: country?.id,
        uf_name: ufName,
        city,
        property_type: homeType && homeType.label,
        property_status: addressType && addressType.label,
      };

      await api.put(`driver/updateAddress/${params.driver_id}`, bodyUpdateAddress);

      await getDriver("address");
      setAddressEditing(false);
    } catch (ex) {
      snackbar.show(<Text>Algo deu errado ao alterar o endereço</Text>, {
        type: "error",
      });
    } finally {
      setLoadAddressEditing(false);
      getDriver("address");
    }
  }

  async function addContact() {
    const phone = otherPhone;
    const name = otherName;
    setOtherContacts([...otherContacts, { name, phone }]);
    setOtherName("");
    setOtherPhone("");
  }

  async function deleteContact(name) {
    const newOtherContacts = otherContacts.filter((x) => x.name !== name);
    setOtherContacts(newOtherContacts || []);
  }

  function completAddress(place) {
    if (place) {
      const province = {
        name: place.province_name,
        uf: place.province,
      };

      if (place.city) {
        setCity({ name: place.city, province });
      }

      setUf(province.uf);
      setUfName(province.name);
      setAddress(place.address || "");
      setNeighborhood(place.neighborhood || "");
      setNumber(place.number || "");
      setNumberless(!place.number);
      setCep(place.zip_code || "");
      setComplement("");
    }
  }
  useEffect(() => {
    fetchAllProvinces().then(setProvinces);
    fetchCountries().then(setCountries);
  }, []);

  useEffect(() => {
    if (params.driver_id) {
      getDriver();
    }
  }, [params.driver_id]);

  const isBrazilian = country?.abbreviation === "br";

  useEffect(() => {
    if (
      !!driver?.mopp ||
      !!driver?.mopp_date_init ||
      !!driver?.mopp_description ||
      !!driver?.mopp_expiration
    ) {
      setHasMopp(true);
    }
  }, [driver, personalEditing]);

  useEffect(() => {
    if (params.driver_id) {
      dispatch({
        type: "VIEW_INSTANCE",
        instance: driver?.social_name,
      });
    }
  }, [driver]);

  return (
    <>
      <Header title={"Detalhes do motorista"}
        RightComponent={
          <Space x="4px">
            <Button variant="secondary" onClick={() => history.goBack()}>
              <Text color="dark" weight={500}>
                Voltar
              </Text>
            </Button>
            <Link to="cadastrar">
              <Button>
                <Text color="white" weight={500}>
                  Novo motorista
                </Text>
              </Button>
            </Link>
          </Space>
        } />
      <Container>
        <Resume driver={driver} loading={loadingCard} />
        <Row>
          <Col md={6} xs={12}>
            <Card
              loading={loadingCard}
              LoadingBodyComponent={() => (
                <DefaultLoadingBodyComponent linesCount={9} perLine={2} />
              )}
              header={
                <Text color="#464E5F" type="header">
                  Dados pessoais
                </Text>
              }
              HeaderRightComponent={
                <div>
                  {personalEditing && (
                    <Button
                      onClick={() => setPersonalEditing(false)}
                      variant="secondary"
                      loading={loadPersonalEditing}
                      className="mr-2"
                    >
                      <Text color="dark" type="regular">
                        Cancelar
                      </Text>
                    </Button>
                  )}
                  <Button
                    variant={personalEditing ? "success" : "secondary"}
                    loading={loadPersonalEditing}
                    onClick={
                      personalEditing
                        ? savePersonalData
                        : () => setPersonalEditing(true)
                    }
                  >
                    <Text weight={500} type="regular">
                      {personalEditing ? "Salvar" : "Editar"}
                    </Text>
                  </Button>
                </div>
              }
            >
              {personalEditing ? (
                <Row className="form">
                  <Col xs={12} className="mb-3">
                    <Radio.Group
                      label="Estrangeiro *"
                      onChange={({ target }) =>
                        setIsInternational(target.value)
                      }
                      value={isInternational}
                      horizontal
                    >
                      <Radio value>
                        <Text color="dark" type="label">
                          Sim
                        </Text>
                      </Radio>
                      <Radio value={false}>
                        <Text color="dark" type="label">
                          Não
                        </Text>
                      </Radio>
                    </Radio.Group>
                  </Col>

                  <Col xs={12}>
                    <Input
                      label="Nome *"
                      type="text"
                      value={socialName}
                      onChange={(e) => setSocialName(e.target.value)}
                      error={errors.socialName}
                    />
                  </Col>

                  <Col lg={6} md={6} xs={12}>
                    <Input
                      value={cpf}
                      onChange={(e) => setCpf(e.target.value)}
                      label={isBrazilian ? "CPF *" : "Documento *"}
                      masked={!isInternational}
                      mask={cpfMask}
                      error={errors.cpf}
                    />
                  </Col>
                  <Col lg={6} md={6} xs={12}>
                    <DatePicker
                      guide
                      value={birthdate}
                      onChange={(e) => setBirthdate(e.target.value)}
                      label="Data de nascimento *"
                      error={errors.birthdate}
                    />
                  </Col>

                  <Col xs={12}>
                    <Select.Async
                      label="Cidade natal"
                      onSearch={fetchCities}
                      value={birthCity}
                      onChange={(value) => setBirthCity(value)}
                      getOptionLabel={(option) =>
                        `${option.name}, ${option.province.uf}`
                      }
                      getOptionValue={(option) => option.id}
                    />
                  </Col>

                  <Col lg={6} md={6} xs={12}>
                    <InputNumber
                      type="number"
                      value={rg}
                      onChange={(e) => setRg(e.target.value)}
                      label={`RG ${isInternational ? "" : "*"}`}
                      error={errors.rg}
                    />
                  </Col>
                  <Col lg={6} md={6} xs={12}>
                    <Input
                      label={`Órgão emissor ${isInternational ? "" : "*"}`}
                      value={issuingBody}
                      onChange={(e) => setIssuingBody(e.target.value)}
                      error={errors.issuingBody}
                    />
                  </Col>

                  <Col lg={6} md={6} xs={12}>
                    <Select
                      label="Estado emissor"
                      options={provinces}
                      value={issuingState}
                      onChange={(value) => setIssuingState(value)}
                      getOptionLabel={(option) => `${option.name}`}
                      getOptionValue={(option) => option.id}
                    />
                  </Col>

                  <Col lg={6} md={6} xs={12}>
                    <DatePicker
                      guide
                      value={issuingDate}
                      onChange={(e) => setIssuingDate(e.target.value)}
                      label="Data de emissão RG"
                    />
                  </Col>

                  <Col xs={12}>
                    <Input
                      label="Mãe *"
                      value={motherName}
                      onChange={(e) => setMotherName(e.target.value)}
                      error={errors.motherName}
                    />
                  </Col>
                  <Col xs={12}>
                    <Input
                      label="Pai"
                      value={fatherName}
                      onChange={(e) => setFatherName(e.target.value)}
                    />
                  </Col>

                  <Col lg={6} md={6} xs={12}>
                    <Select
                      label="Estado Civil *"
                      value={civilStatus}
                      onChange={(event) => {
                        setCivilStatus(event);
                      }}
                      options={civilStatusList}
                      getOptionValue={(option) => option.label}
                      error={errors.civilStatus}
                    />
                  </Col>

                  <Col lg={6} md={6} xs={12}>
                    <Input
                      value={pis}
                      onChange={(e) => setPis(e.target.value)}
                      label="PIS"
                      masked
                      mask={pisMask}
                    />
                  </Col>
                </Row>
              ) : (
                <PersonalData driver={driver} isBrazilian={isBrazilian} />
              )}
            </Card>
            <Card
              loading={loadingCard}
              LoadingBodyComponent={() => (
                <DefaultLoadingBodyComponent linesCount={2} perLine={2} />
              )}
              header={
                <Text color="#464E5F" type="header">
                  Contatos
                </Text>
              }
              HeaderRightComponent={
                <div>
                  {contactsEditing && (
                    <Button
                      onClick={() => setContactsEditing(false)}
                      variant="secondary"
                      loading={loadContactsEditing}
                      className="mr-2"
                    >
                      <Text color="dark" type="regular">
                        Cancelar
                      </Text>
                    </Button>
                  )}
                  <Button
                    variant={contactsEditing ? "success" : "secondary"}
                    loading={loadContactsEditing}
                    onClick={
                      contactsEditing
                        ? saveContactData
                        : () => setContactsEditing(true)
                    }
                  >
                    <Text weight={500} type="regular">
                      {contactsEditing ? "Salvar" : "Editar"}
                    </Text>
                  </Button>
                </div>
              }
            >
              {contactsEditing ? (
                <Row className="form">
                  <Col lg={6} md={6} xs={12}>
                    <Input
                      value={mainPhone}
                      onChange={(e) => setMainPhone(e.target.value)}
                      icon={<FaPhoneAlt color="#494950" />}
                      masked
                      mask={phoneMask}
                      label="Telefone principal"
                    />
                  </Col>
                  <Col xs={12}>
                    <Input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      label="Email"
                      type="email"
                    />
                  </Col>
                  <Col xs={12} className="my-3">
                    <Text type="body" color="#464E5F">
                      Outros contatos
                    </Text>
                  </Col>
                  <Col xs={12}>
                    <Input
                      label="Nome"
                      value={otherName}
                      onChange={(e) => setOtherName(e.target.value)}
                    />
                  </Col>
                  <Col lg={6} md={6} xs={12}>
                    <Input
                      value={otherPhone}
                      onChange={(e) => setOtherPhone(e.target.value)}
                      icon={<FaPhoneAlt color="#494950" />}
                      masked
                      mask={phoneMask}
                      label="Telefone"
                    />
                  </Col>
                  <Col lg={6} md={6} xs={12}>
                    <Button
                      variant="success"
                      onClick={() => addContact()}
                      style={{ marginTop: "30px" }}
                    >
                      <Text weight={600} type="regular">
                        Adicionar
                      </Text>
                    </Button>
                  </Col>
                  <Col xs={12} className="mt-3">
                    <Table>
                      <thead>
                        <tr>
                          <th>
                            <Text type="label" weight="500">
                              Nome
                            </Text>
                          </th>
                          <th className="text-center">
                            <Text type="label" weight="500">
                              Telefone
                            </Text>
                          </th>
                          <th className="text-center">
                            <Text type="label" weight="500">
                              Ação
                            </Text>
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {otherContacts &&
                          otherContacts.map((x) => (
                            <tr>
                              <td>
                                <Text type="regular" weight="400">
                                  {x.name}
                                </Text>
                              </td>
                              <td width="240" className="text-center">
                                <Text type="regular" weight="400">
                                  {x.phone}
                                </Text>
                              </td>
                              <td width="60" className="text-center">
                                <Button
                                  variant="error"
                                  size="sm"
                                  className="mx-1"
                                  onClick={() => deleteContact(x.name)}
                                >
                                  <FaTrash />
                                </Button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              ) : (
                <>
                  <ContactDetails driver={driver} />
                </>
              )}
            </Card>
            <Payment
              driver={driver}
              getDriver={getDriver}
              loadingCard={loadingCard}
            />
          </Col>

          <Col lg={6} md={6} xs={12}>
            <ShippingCompany
              driver={driver}
              getDriver={getDriver}
              loadingCard={loadingCard}
            />
            <Card
              loading={loadingCard}
              LoadingBodyComponent={() => (
                <DefaultLoadingBodyComponent linesCount={7} perLine={2} />
              )}
              header={
                <Text color="#464E5F" type="header">
                  CNH
                </Text>
              }
              HeaderRightComponent={
                <div>
                  {cnhEditing && (
                    <Button
                      onClick={() => setCnhEditing(false)}
                      variant="secondary"
                      loading={loadCnhEditing}
                      className="mr-2"
                    >
                      <Text color="dark" type="regular">
                        Cancelar
                      </Text>
                    </Button>
                  )}
                  <Button
                    variant={cnhEditing ? "success" : "secondary"}
                    loading={loadCnhEditing}
                    onClick={
                      cnhEditing ? saveCnhData : () => setCnhEditing(true)
                    }
                  >
                    <Text weight={500} type="regular">
                      {cnhEditing ? "Salvar" : "Editar"}
                    </Text>
                  </Button>
                </div>
              }
            >
              {cnhEditing ? (
                <Row className="form">
                  <Col xs={12} className="mb-3">
                    <Radio.Group
                      label="CNH Estrangeira *"
                      onChange={({ target }) =>
                        setIsCnhInternational(target.value)
                      }
                      value={isCnhInternational}
                      horizontal
                    >
                      <Radio value>
                        <Text color="dark" type="label">
                          Sim
                        </Text>
                      </Radio>
                      <Radio value={false}>
                        <Text color="dark" type="label">
                          Não
                        </Text>
                      </Radio>
                    </Radio.Group>
                  </Col>

                  <Col lg={6} md={6} xs={12}>
                    <Input
                      label="CNH *"
                      value={cnhNumber}
                      onChange={(e) => setCnhNumber(e.target.value)}
                      error={errors.cnhNumber}
                    />
                  </Col>
                  <Col lg={6} md={6} xs={12}>
                    <Select
                      label="Categoria *"
                      value={cnhType}
                      onChange={(value) => setCnhType(value)}
                      options={cnhTypes}
                      getOptionValue={(option) => option.label}
                    />
                  </Col>
                  <Col xs={12}>
                    <Input
                      label="Chave da CNH"
                      value={cnhKey}
                      onChange={(e) => setCnhKey(e.target.value)}
                    />
                  </Col>
                  <Col xs={12}>
                    <Select.Async
                      label="Cidade emissão CNH *"
                      onSearch={fetchCities}
                      value={cnhIssuingCity}
                      onChange={(value) => setCnhIssuingCity(value)}
                      getOptionLabel={(option) =>
                        `${option.name}, ${option.province.uf}`
                      }
                      getOptionValue={(option) => option.id}
                      error={errors.cnhIssuingCity}
                    />
                  </Col>
                  <Col lg={6} md={6} xs={12}>
                    <DatePicker
                      guide
                      label="1° habilitação *"
                      value={firstCnhDate}
                      onChange={(e) => setFirstCnhDate(e.target.value)}
                      error={errors.firstCnhDate}
                    />
                  </Col>
                  <Col lg={6} md={6} xs={12}>
                    <DatePicker
                      guide
                      label="Data de emissão CNH"
                      value={cnhDispatchDate}
                      onChange={(e) => setCnhDispatchDate(e.target.value)}
                      error={errors.cnhDispatchDate}
                    />
                  </Col>
                  <Col lg={6} md={6} xs={12}>
                    <DatePicker
                      guide
                      label="Validade habilitação *"
                      value={cnhDueDate}
                      onChange={(e) => setCnhDueDate(e.target.value)}
                      error={errors.cnhDueDate}
                    />
                  </Col>
                  <Col xs={12} className="mb-3">
                    <Radio.Group
                      label="Possui Mopp *"
                      onChange={({ target }) => setHasMopp(target.value)}
                      value={hasMopp}
                      horizontal
                    >
                      <Radio value>
                        <Text color="dark" type="label">
                          Sim
                        </Text>
                      </Radio>
                      <Radio value={false}>
                        <Text color="dark" type="label">
                          Não
                        </Text>
                      </Radio>
                    </Radio.Group>
                  </Col>

                  {hasMopp && (
                    <>
                      <Col xs={12}>
                        <Input
                          label="Descrição do Mopp *"
                          value={moppDescription}
                          onChange={(e) => setMoppDescription(e.target.value)}
                          error={errors.moppDescription}
                        />
                      </Col>
                      <Col lg={6} md={6} xs={12}>
                        <DatePicker
                          guide
                          label="Validade do Mopp *"
                          value={moppDueDate}
                          onChange={(e) => setMoppDueDate(e.target.value)}
                          error={errors.moppDueDate}
                        />
                      </Col>

                      <Col lg={6} md={6} xs={12}>
                        <Input
                          icon={<BsCalendar color="#464E5F" />}
                          label="Primeiro Mopp *"
                          value={moppInitialDate}
                          onChange={(e) => setMoppInitialDate(e.target.value)}
                          masked
                          mask={[
                            /[0-9]/,
                            /[0-9]/,
                            "/",
                            /[0-9]/,
                            /[0-9]/,
                            "/",
                            /[0-9]/,
                            /[0-9]/,
                            /[0-9]/,
                            /[0-9]/,
                          ]}
                          error={errors.mopp_date_init}
                        />
                      </Col>
                    </>
                  )}

                  <Col lg={4} md={4} xs={12} className="mb-3">
                    <Radio.Group
                      label="Exclusivo *"
                      onChange={({ target }) => setIsExclusive(target.value)}
                      value={isExclusive}
                      horizontal
                    >
                      <Radio value>
                        <Text color="dark" type="label">
                          Sim
                        </Text>
                      </Radio>
                      <Radio value={false}>
                        <Text color="dark" type="label">
                          Não
                        </Text>
                      </Radio>
                    </Radio.Group>
                  </Col>

                  <Col lg={4} md={4} xs={12} className="mb-3">
                    <Radio.Group
                      label="Autônomo *"
                      onChange={({ target }) => setIsAutonomous(target.value)}
                      value={isAutonomous}
                      horizontal
                    >
                      <Radio value>
                        <Text color="dark" type="label">
                          Sim
                        </Text>
                      </Radio>
                      <Radio value={false}>
                        <Text color="dark" type="label">
                          Não
                        </Text>
                      </Radio>
                    </Radio.Group>
                  </Col>
                  <Col lg={4} md={4} xs={12} className="mb-3">
                    <Radio.Group
                      label="Proprietário *"
                      onChange={({ target }) => setIsOwner(target.value)}
                      value={isOwner}
                      horizontal
                    >
                      <Radio value>
                        <Text color="dark" type="label">
                          Sim
                        </Text>
                      </Radio>
                      <Radio value={false}>
                        <Text color="dark" type="label">
                          Não
                        </Text>
                      </Radio>
                    </Radio.Group>
                  </Col>

                  <Col xs={12} className="mb-3">
                    <Text type="label" color="#464E5F">
                      Upload de CNH
                    </Text>

                    <Row>
                      <Col lg={6} md={6} xs={12} className="text-center mt-3">
                        <Text type="label" color="#464E5F">
                          Frente
                        </Text>
                        <Upload
                          sizeIcon={50}
                          icon={<FaImage size={50} />}
                          onChange={(event) =>
                            setFrontImageCnh(event.target.files)
                          }
                          value={frontImageCnh}
                          id="imgFront"
                          name="driverFront"
                          accept={[".png", ".jpg"]}
                          label="Arquivo máximo de 1MB"
                        />
                      </Col>
                      <Col lg={6} md={6} xs={12} className="text-center mt-3">
                        <Text type="label" color="#464E5F">
                          Verso
                        </Text>
                        <Upload
                          sizeIcon={50}
                          icon={<FaImage size={50} />}
                          onChange={(event) =>
                            setBackImageCnh(event.target.files)
                          }
                          value={backImageCnh}
                          id="imgFront"
                          name="driverFront"
                          accept={[".png", ".jpg"]}
                          label="Arquivo máximo de 1MB"
                        />
                      </Col>
                      <Col xs={12} className="text-center mt-3">
                        <Text color="#464E5F" type="little">
                          Para manter as fotos atuais, deixe os campos em branco{" "}
                        </Text>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ) : (
                <CnhData driver={driver} />
              )}
            </Card>

            <Card
              loading={loadingCard}
              LoadingBodyComponent={() => (
                <DefaultLoadingBodyComponent linesCount={5} perLine={2} />
              )}
              header={
                <Text color="#464E5F" type="header">
                  Endereço
                </Text>
              }
              HeaderRightComponent={
                <div>
                  {addressEditing && (
                    <Button
                      onClick={() => setAddressEditing(false)}
                      variant="secondary"
                      loading={loadAddressEditing}
                      className="mr-2"
                    >
                      <Text color="dark" type="regular">
                        Cancelar
                      </Text>
                    </Button>
                  )}
                  <Button
                    variant={addressEditing ? "success" : "secondary"}
                    loading={loadAddressEditing}
                    onClick={
                      addressEditing
                        ? saveAddressData
                        : () => setAddressEditing(true)
                    }
                  >
                    <Text weight={500} type="regular">
                      {addressEditing ? "Salvar" : "Editar"}
                    </Text>
                  </Button>
                </div>
              }
            >
              {addressEditing ? (
                <Row className="form">
                  <Col xs={12}>
                    <Select
                      label="País"
                      options={countries}
                      value={country}
                      onChange={(value) => setCountry(value)}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      disableClear
                    />
                  </Col>
                  <Col xs={12}>
                    <InputAddress
                      name="origin"
                      label="Endereço"
                      country={country?.abbreviation || "br"}
                      value={address}
                      onSelectedAddress={(place) => completAddress(place)}
                      onChange={(event) => setAddress(event.target.value)}
                      types={[]}
                      placeholder=""
                      error={errors.address}
                    />
                  </Col>
                  <Col lg={6} md={6} xs={12}>
                    <Input
                      disabled={numberless}
                      label={numberless ? "Número" : "Número *"}
                      value={number}
                      onChange={(e) => setNumber(e.target.value)}
                      error={errors.number}
                    />
                  </Col>
                  <Col
                    lg={6}
                    md={6}
                    xs={12}
                    className="d-flex align-items-center"
                  >
                    <Radio.Group
                      onChange={() => setNumberless((old) => !old)}
                      value={numberless}
                      horizontal
                    >
                      <Radio value={false}>
                        <Text color="dark" type="label">
                          Com Nº
                        </Text>
                      </Radio>
                      <Radio value>
                        <Text color="dark" type="label">
                          Sem Nº
                        </Text>
                      </Radio>
                    </Radio.Group>
                  </Col>
                  <Col lg={6} md={6} xs={12}>
                    <Input
                      label="Complemento"
                      value={complement}
                      onChange={(e) => setComplement(e.target.value)}
                    />
                  </Col>
                  <Col lg={6} md={6} xs={12}>
                    <Input
                      label="Bairro *"
                      value={neighborhood}
                      onChange={(e) => setNeighborhood(e.target.value)}
                      error={errors.neighborhood}
                    />
                  </Col>
                  <Col lg={6} md={6} xs={12}>
                    <Input
                      label="CEP *"
                      value={cep}
                      onChange={(e) => setCep(e.target.value)}
                      error={errors.cep}
                    />
                  </Col>
                  <Col xs={12}>
                    <Select.Async
                      label="Cidade *"
                      onSearch={fetchCities}
                      value={city}
                      onChange={(value) => {
                        setUfName(value?.province?.uf);
                        setCity(value);
                      }}
                      getOptionLabel={(option) =>
                        `${option.name}, ${option.province?.uf}`
                      }
                      getOptionValue={(option) => option.id}
                      error={errors.city}
                    />
                  </Col>
                  <Col lg={6} md={6} xs={12}>
                    <Select
                      label="Tipo de Moradia"
                      value={homeType}
                      onChange={(event) => {
                        setHomeType(event);
                      }}
                      options={homeTypes}
                      getOptionValue={(option) => option.label}
                    />
                  </Col>
                  <Col lg={6} md={6} xs={12}>
                    <Select
                      label="Tipo de Endereço"
                      value={addressType}
                      onChange={(event) => {
                        setAddressType(event);
                      }}
                      options={addressTypes}
                      getOptionValue={(option) => option.label}
                    />
                  </Col>
                </Row>
              ) : (
                <AddressData driver={driver} country_name={country?.name} />
              )}
            </Card>
          </Col>

          <Col xs={12}>
            <Attachments
              attachments={attachments}
              setAttachments={setAttachments}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}

import { combineReducers } from 'redux';

import auth from './modules/auth';
import deals from './modules/deals';
import provinces from './modules/provinces';
import cities from './modules/cities';
import vehicle from './modules/vehicle';
import driver from './modules/driver';

const reducer = combineReducers({
  auth,
  deals,
  provinces,
  cities,
  vehicle,
  driver,
});

export default reducer;

/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';

import api from 'services/api';

import Text from 'components/Text';
import Select from 'components/Select';
import Button from 'components/Button';
import Card, { DefaultLoadingBodyComponent } from 'components/Card';

function Payment({ driver, getDriver, loadingCard }) {
  const [paymentMethod, setPaymentMethod] = useState({
    id: '',
    name: 'Não informado',
  });
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (driver && driver.driver) {
      setPaymentMethod({
        id: driver.driver.payment ? driver.driver.payment.id : '',
        name: driver.driver.payment
          ? driver.driver.payment.name
          : 'Não informado',
      });
    }
  }, [driver]);

  async function fetchPaymentMethods(search) {
    try {
      const response = await api.get('payment/methods', { params: { search } });
      return response.data;
    } catch (ex) {
      return [];
    }
  }

  useEffect(() => {
    async function initialFetchPaymentMethods() {
      try {
        const response = await api.get('payment/methods', {
          params: { initialFetch: true },
        });

        setOptions(response.data);
      } catch (ex) {
        // Handle exception
      }
    }

    initialFetchPaymentMethods();
  }, []);

  async function handleSubmit() {
    if (!paymentMethod) {
      setError({ paymentMethod: 'Campo obrigatório' });
      return;
    }

    try {
      await api.put(`driver/payment/${driver.id}`, {
        paymentMethod: paymentMethod && paymentMethod.id,
      });
      setError({});
    } catch (er) {
      //
    } finally {
      getDriver();

      setEditing(false);
      setLoading(false);
    }
  }

  return (
    <Card
      loading={loadingCard}
      LoadingBodyComponent={() => (
        <DefaultLoadingBodyComponent linesCount={1} perLine={1} />
      )}
      header={
        <Text color="#464E5F" type="header">
          Método de pagamento
        </Text>
      }
      HeaderRightComponent={
        editing ? (
          <div>
            <Button
              onClick={() => setEditing(false)}
              variant="secondary"
              loading={loading}
              className="mr-2"
            >
              <Text color="dark" type="regular">
                Cancelar
              </Text>
            </Button>
            <Button variant="success" loading={loading} onClick={handleSubmit}>
              <Text weight={500} type="regular">
                Salvar
              </Text>
            </Button>
          </div>
        ) : (
          <Button variant="secondary" onClick={() => setEditing(true)}>
            <Text weight={500} type="regular">
              Editar
            </Text>
          </Button>
        )
      }
    >
      {editing ? (
        <div className="form">
          <Select.Async
            label="Método de pagamento *"
            onSearch={fetchPaymentMethods}
            value={paymentMethod}
            options={options}
            onChange={value => setPaymentMethod(value)}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            error={error.paymentMethod}
          />
        </div>
      ) : (
        <Text type="regular" color="#494950" as="p" className="mt-2">
          {paymentMethod ? paymentMethod.name : 'Não informado'}
        </Text>
      )}
    </Card>
  );
}

export default Payment;

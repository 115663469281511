import styled, { css } from 'styled-components';

import { InputLabel } from '@material-ui/core';

const Label = styled(InputLabel)`
  && {
    font-size: 0.875rem;
    line-height: 1rem;
    margin-top: 12px;
    color: ${({ theme }) => theme.colors.secondary};
    font-weight: 400;

    ${({ nomargin }) =>
      nomargin &&
      css`
        margin: 0px;
      `}

    ${({ pb }) =>
      pb &&
      css`
        padding-bottom: ${pb}rem;
      `}

    text-transform: uppercase;

    &.textLabel {
      font-size: 12px;
      font-weight: 300;
      margin-top: 10px;
      margin-bottom: 5px;
    }
  }
`;
export default Label;

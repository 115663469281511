import React from 'react';

import StyledListItem from './styles';

function ListItem({ onClick, children, ...rest }) {
  return (
    <StyledListItem onClick={onClick} {...rest}>
      {children}
    </StyledListItem>
  );
}

export default ListItem;

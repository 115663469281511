/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';

import Text from 'components/Text';
import Select from 'components/Select';
import Button from 'components/Button';
import Toggle from 'components/Toggle';
import Modal from 'components/Modal';
import DatePicker from 'components/DatePicker';

import useQuery from 'hooks/useQuery';
import {
  fetchVehiclesTypes,
  fetchCities
} from 'utils/fetches';

const DATE_OPTIONS = [
  {
    label: '3 dias',
    value: moment().subtract(3, 'days').format('DD/MM/YYYY'),
  },
  {
    label: '30 dias',
    value: moment().subtract(30, 'days').format('DD/MM/YYYY'),
  },
];

export default function LoadFilter({
  setFinalFilter,
  showAdvancedFilters,
  handleClose,
  setQtdAdvancedFilters,
  resultsTotal,
  isFetching,
}) {
  const history = useHistory();
  const query = useQuery();
  const [errors, setErrors] = useState({});

  const [origin, setOrigin] = useState();
  const [destination, setDestination] = useState();
  const [firstRender, setFirstRender] = useState(true);
  const [vehicleTypes, setVehicleTypes] = useState([]);

  function parseDate(value) {
    if (moment(value, 'DD/MM/YYYY').isValid()) {
      return moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }

    return null;
  }

  const [advancedFilters, setAdvancedFilters] = useState({
    startDate: DATE_OPTIONS[0].value,
    endDate: null,
    vehicleTypeIds: [],
  });

  function applyQueryParameters() {
    const queryParams = query.toString();
    setFinalFilter(queryParams);
    history.replace({ search: queryParams });
  }

  async function initializeDefaultQuery() {
    if (!query.has('startDate')) {
      query.set('startDate', advancedFilters.startDate);
    }

    applyQueryParameters()
  }

  function buildLocationFromQueryParams(key) {
    const province = query.get(`province_${key}`);
    if (province) {
      const location = {
        name: province,
      };

      const city = query.get(`city_${key}`);
      if (city) {
        location.province = { uf: location.name };
        location.name = city;
      }

      return location;
    }

    return null;
  }

  function handleAdvancedFilter(key, value, process = v => v) {
    setAdvancedFilters(previous => ({ ...previous, [key]: value }));

    const processedValue = process(value);
    if (processedValue) {
      if (Array.isArray(processedValue)) {
        query.delete(key);
        processedValue.forEach(processed => {
          query.append(key, processed);
        });
      } else {
        query.set(key, processedValue);
      }
    } else {
      query.delete(key);
    }
  }


  async function getQueryParams() {
    setOrigin(buildLocationFromQueryParams('origin'));
    setDestination(buildLocationFromQueryParams('destination'));

    if (query.has('startDate')) {
      handleAdvancedFilter(
        'startDate',
        moment(query.get('startDate')).format('DD/MM/YYYY'),
        parseDate
      );
    }

    if (query.has('endDate')) {
      handleAdvancedFilter(
        'endDate',
        moment(query.get('endDate')).format('DD/MM/YYYY'),
        parseDate
      );
    }
  }

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
      handleAdvancedFilter(
        'startDate',
        moment().subtract(3, 'days').format('DD/MM/YYYY'),
        parseDate
      );
      initializeDefaultQuery();
    }
    getQueryParams();
  }, []);

  useEffect(() => {
    const isNotEmpty = value =>
      value !== null &&
      value !== undefined &&
      value !== '' &&
      value !== '0' &&
      value?.length > 0;

    setQtdAdvancedFilters(
      Object.values(advancedFilters).filter(isNotEmpty).length
    );
  }, [advancedFilters]);

  useEffect(() => {
    fetchVehiclesTypes().then(setVehicleTypes);
  }, []);

  function setError(field, error) {
    setErrors(prev => ({ ...prev, [field]: error }));
  }
  useEffect(() => {
    if (advancedFilters.startDate && advancedFilters.endDate) {
      const startDate = moment(advancedFilters.startDate, 'DD/MM/YYYY', true);
      const endDate = moment(advancedFilters.endDate, 'DD/MM/YYYY', true);

      if (startDate.isAfter(endDate)) {
        setError(
          'startDate',
          'A data de ínicio precisa ser menor que a data final.'
        );
        setError(
          'endDate',
          'A data de fim deve ser maior que a data de início.'
        );
      }
    }
  }, [advancedFilters.startDate, advancedFilters.endDate]);

  return (
    <>
      <Modal
        size="lg"
        show={showAdvancedFilters}
        backdrop="static"
        heading="Filtro Avançado"
        animation
        onHide={handleClose}
        style={{ height: '100%' }}
        body={
          <Row className="p-1 form">
            <Col md={4} xs={12}>
              <DatePicker
                value={advancedFilters.startDate}
                onChange={e => {
                  handleAdvancedFilter('startDate', e.target.value, parseDate);
                  setError('startDate', null);
                  setError('endDate', null);
                }}
                label="Período de"
                error={errors.startDate}
              />
            </Col>
            <Col md={4} xs={12}>
              <DatePicker
                value={advancedFilters.endDate}
                onChange={e => {
                  handleAdvancedFilter('endDate', e.target.value, parseDate);
                  setError('endDate', null);
                  setError('startDate', null);
                }}
                label="Até"
                error={errors.endDate}
              />
            </Col>
            <Col md={4} xs={12}>
              <Select
                label="Tipo de veículo"
                value={advancedFilters.vehicleTypeIds}
                multiple
                options={vehicleTypes}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                onChange={value =>
                  handleAdvancedFilter('vehicleTypeIds', value || [], v =>
                    v.map(i => i.id)
                  )
                }
              />
            </Col>
          </Row>
        }
        footer={
          <Row style={{ justifyContent: 'flex-end' }}>
            <Button
              variant="primary"
              onClick={() => {
                applyQueryParameters();
                handleClose();
              }}
            >
              <Text weight={500} type="regular">
                Filtrar
              </Text>
            </Button>
          </Row>
        }
      />
      <Row className="filter">
        <Col md={2} xs={12}>
          <Toggle
            label="Coleta agendada há"
            labelColor="#fff"
            value={advancedFilters.startDate}
            defaultValue={advancedFilters.startDate}
            options={DATE_OPTIONS}
            onSelected={value => {
              handleAdvancedFilter('startDate', value, parseDate);
              setError('startDate', null);
              handleAdvancedFilter('endDate', null);
            }}
          />
        </Col>
        <Col lg={3} xs={12}>
          <Select.Async
            label="Origem"
            onSearch={fetchCities}
            value={origin}
            onChange={value => {
              setOrigin(value);

              if (value) {
                if (value.province) {
                  query.set('province_origin', value.province.uf);
                  query.set('city_origin', value.name);
                } else {
                  query.set('province_origin', value.uf);
                }
              } else {
                query.delete('province_origin');
                query.delete('city_origin');
              }
            }}
            getOptionLabel={option => {
              if (option.province) {
                return `${option.name}, ${option.province.uf}`;
              }

              return `Estado de ${option.name}`;
            }}
            getOptionValue={option => option.id}
            labelColor="#fff"
          />
        </Col>
        <Col lg={3} xs={12}>
          <Select.Async
            label="Destino"
            onSearch={fetchCities}
            value={destination}
            onChange={value => {
              setDestination(value);

              if (value) {
                if (value.province) {
                  query.set('province_destination', value.province.uf);
                  query.set('city_destination', value.name);
                } else {
                  query.set('province_destination', value.uf);
                }
              } else {
                query.delete('province_destination');
                query.delete('city_destination');
              }
            }}
            getOptionLabel={option => {
              if (option.province) {
                return `${option.name}, ${option.province.uf}`;
              }

              return `Estado de ${option.name}`;
            }}
            getOptionValue={option => option.id}
            labelColor="#fff"
          />
        </Col>
        <Col lg={1} xs={12} className="vertical bottom">
          <Button variant="success" onClick={() => applyQueryParameters()} style={{ padding: '12px 30px' }}>
            <Text type="regular" weight={500}>
              Filtrar
            </Text>
          </Button>
        </Col>
        <Col>
          {!isFetching && !!resultsTotal && (
            <div className="text-right">
              <Text type="regular" color="white" weight="300">
                {resultsTotal} cargas
              </Text>
            </div>
          )}
        </Col>
      </Row>
    </>
  );
}

/* eslint-disable import/no-unresolved */
import api from 'services/api';

export async function fetchUnreadNotifications() {
  const response = await api.get('notifications/unread');
  return response.data;
}

export async function fetchNotifications(page = 1) {
  const response = await api.get('notifications', {
    params: { page },
  });
  const { data, ...pagination } = response.data;

  return { data, pagination };
}

export async function markNotificationAsRead(notification) {
  const response = await api.put(`notifications/${notification.id}`, {
    read: true,
  });

  return response.data;
}

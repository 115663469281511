import React from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Card from "components/Card";
import Text from "components/Text";
import Button from "components/Button";
import { Link } from "react-router-dom";

export default function DriverCard({ driver }) {
  return (
    <Col lg={6} md={6} xs={12} className="p-2">
      <Card
        header={
          <>
            <Col lg={4} md={4} xs={6}>
              <Text type="regular" transform="uppercase" weight={500}>
                {driver.socialName}
              </Text>
            </Col>
            <Col lg={4} md={4} xs={6}>
              <Text type="regular" color="gray" weight={500}>
                {driver.carrierSocialName}
              </Text>
            </Col>
          </>
        }
        HeaderRightComponent={
          <Link to={`/motoristas/${driver.driverId}`}>
            <Button variant="primary">
              <Text weight={500} color="white" type="regular">
                Detalhes
              </Text>
            </Button>
          </Link>
        }
      >
        <Row>
          <Col lg={4} md={4} xs={6}>
            <Text type="regular" color="gray" weight={500}>
              {driver.cpfCnpj}
            </Text>
          </Col>
          <Col lg={4} md={4} xs={6}>
            <Text type="regular" color="gray" weight={500}>
              {driver.carrierCpfCnpj}
            </Text>
          </Col>
        </Row>
      </Card>
    </Col>
  );
}

/* eslint-disable import/no-cycle */
/* eslint-disable import/no-unresolved */
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Row, Col, } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';

import Header from 'components/Header';
import Button from 'components/Button';
import Text from 'components/Text';

import { LoadingPage } from 'components/LoadingPage';
import { useLoad } from 'contexts/load';

import Vehicle from './Vehicle';
import Data from './Data';
import Address from './Address';

export default function LoadDetail() {
  const { load, isFetching, } = useLoad()
  const history = useHistory();
  return (
    <>
      <Header
        header="Detalhes da carga"
        RightComponent={
          <>
            <Button variant="secondary" onClick={() => history.goBack()} loading={isFetching}>
              <Text color="dark" weight={500}>
                Voltar
              </Text>
            </Button>

            <Link to={`/negociacoes/cadastrar/${load && load.id}`}>
              {load?.deals?.length > 0 ? (
                <Button variant="primary" style={{ marginLeft: '10px', height: '44px' }} loading={isFetching} disabled>
                  <Text weight={500} color="white" type="regular">
                    Em Negociação
                  </Text>
                </Button>
              ) : (
                <Button variant="primary" loading={isFetching} style={{ marginLeft: '10px', height: '46px' }}>
                  <Text weight={500} color="white" type="regular">
                    Tenho Interesse
                  </Text>
                </Button>)}
            </Link>
          </>
        }
      />
      <Container>
        {load ? (
          <>
            <Row>
              <Col xs={6}>
                <Row>
                  <Col>
                    <Data load={load} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Vehicle load={load} />
                  </Col>
                </Row>
              </Col>
              <Col xs={6}>
                <Row>
                  <Address
                    originsData={load.loadOrigins}
                    destinationsData={load.loadDestinations}
                  />
                </Row>
              </Col>
            </Row>
          </>
        ) : (<LoadingPage isLoading={isFetching} />)}
      </Container>
    </>
  );
}

import styled from 'styled-components';

export const Container = styled.div`
  height: 28px;
  border-radius: 5px 0px 0px 0px;
  background: #fff;
  position: absolute;
  right: 0;
  bottom: 0;
  padding-right: 7.5px;
  padding-left: 7.5px;
`;

export const SubContainer = styled.div`
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  display: flex;
`;

export const BoxContainer = styled.div`
  align-items: center;
  display: flex;
  align-content: center;
  margin-top: 7px;
  margin-left: 8px;
`;

export const OriginBox = styled.div`
  width: 10px;
  height: 10px;
  margin-right: 8px;
  margin-left: 8px;
  background: #0bb7af;
`;

export const DestinationBox = styled.div`
  width: 10px;
  height: 10px;
  margin-right: 8px;
  margin-left: 8px;

  background: #266ca8;
`;

export const FinalBox = styled.div`
  width: 10px;
  height: 10px;
  margin-right: 8px;
  margin-left: 8px;
  background: #f64e60;
`;

export const Speed = styled.div`
  width: 28px;
  height: 10px;
  border-top: 2px solid #266ca8;
  margin-right: 8px;
  margin-left: 8px;
  margin-top: 5px;
`;

export const AverageSpeed = styled.div`
  width: 28px;
  height: 10px;
  border-top: 2px dashed #ffc107;
  margin-right: 8px;
  margin-left: 8px;
  margin-top: 5px;
`;

export const MaxSpeed = styled.div`
  width: 28px;
  height: 10px;
  border-top: 2px dashed #f64e60;
  margin-right: 8px;
  margin-left: 8px;
  margin-top: 5px;
`;

export const DriverJourney = styled.div`
  width: 28px;
  height: 10px;
  border-top: 2px solid #fbad26;
  margin-right: 8px;
  margin-left: 8px;
  margin-top: 5px;
`;

// eslint-disable-next-line import/no-cycle
import React from "react";
import { useRouteMatch, Link, useLocation } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Text from "components/Text";

import { privateRoutes } from "routes/map";
import Menu from "components/Menu";
import * as Styled from "./styles";
import "./style.css";

export default function Header({
  title,
  RightComponent,
  contentFluid,
  children,
  isOld = false,
}) {
  const location = useLocation();
  const match = useRouteMatch(location.pathname);

  const breadcrumbs = privateRoutes
    .filter(({ path }) => match.path.includes(path))
    .map(({ path, ...rest }) => ({
      path: Object.keys(match.params).length
        ? Object.keys(match.params).reduce(
            (acc, param) => acc.replace(`:${param}`, match.params[param]),
            path
          )
        : path,
      ...rest,
    }))
    .sort((a, b) => a.path.length - b.path.length);

  const { length, [length - 1]: last, 1: second } = breadcrumbs;
  return (
    <>
      <Styled.Header className="header-main">
        <Container fluid className="header py-2">
          <Row>
            <Col md={2}>
              <Navbar.Brand href="/">
                <img src="/images/Logo-Cooperplace-Branca.png" alt="Cooperplace" width={150}/>
              </Navbar.Brand>
            </Col>

            <Col md={10}>
              <Menu path={second} />
            </Col>
          </Row>
        </Container>

        <Container className={`mt-5 pb-5}`}>
          <Row>
            <Col>
              <Text type="subtitle" color="white" className="text-title">
                {title || last.title}
              </Text>

              <Styled.Breadcrumb listProps={{ className: "p-0" }}>
                {breadcrumbs.map((bread) => (
                  <Styled.BreadcrumbItem
                    key={bread.path}
                    linkAs={Link}
                    linkProps={{ to: bread.path }}
                  >
                    <Text type="label" color="white">
                      {bread.title}
                    </Text>
                  </Styled.BreadcrumbItem>
                ))}
              </Styled.Breadcrumb>
            </Col>
            <Col lg={6} md={6} xs={8} className="py-3 right-component">
              {RightComponent}
            </Col>
          </Row>
        </Container>
      </Styled.Header>
      <Styled.Main isOld={isOld} fluid={contentFluid}>
        {children}
      </Styled.Main>
    </>
  );
}

import produce from 'immer';

import Types from './types';

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: null,
  success: false,
  pagination: { total: 0, lastPage: 0, currentPage: 0 },
  fetched: false,
};

export default function deals(state = INITIAL_STATE, { type, payload }) {
  return produce(state, draft => {
    switch (type) {
      case Types.DEALS_REQUEST:
        draft.loading = true;
        draft.fetched = false;
        break;
      case Types.DEALS_SUCCESS:
        draft.loading = false;
        draft.fetched = true;
        draft.data = payload.deals.data;
        break;
      case Types.DEALS_FAILURE:
        draft.loading = false;
        draft.fetched = true;
        break;
      case Types.DEAL_CREATE_REQUEST:
        draft.loading = true;
        break;
      case Types.DEAL_CREATE_SUCCESS:
        draft.loading = false;
        draft.success = true;
        break;
      case Types.DEAL_CREATE_FAILURE:
        draft.loading = false;
        draft.success = false;
        draft.error = payload.error;
        break;
      default:
        break;
    }
  });
}

import styled from 'styled-components';

import { Card as MaterialCard } from '@material-ui/core';

import Button from 'components/Button/Login';
import Label from 'components/Label';


export const Card = styled(MaterialCard)`
  position: relative;

  width: 390px;
  min-height: 350px;

  && {
    box-shadow: ${({ theme }) => theme.shadow};
  }

  margin: 20px;
  padding: 60px 35px;

  ${Button} {
    margin-top: 10px;
  }

  ${Label} {
    text-transform: none;
  }
`;

export const BackWrapper = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
`;

export const Logo = styled.img`
  margin-bottom: 10px;
  height: 30px;
  width: 140px;
`;

export const Line = styled.div`
  width: 65px;
  height: 2px;

  background-color: ${({ theme }) => theme.colors.secondary_light};

  margin-bottom: 30px;
`;

export const ForgotPasswordd = styled.button`
  background-color: transparent;

  color: ${({ theme }) => theme.colors.secondary};
  font-size: 1rem;
  text-transform: uppercase;

  margin-top: 15px;
`;

export const Version = styled.span`
  position: absolute;
  right: 20px;
  bottom: 2px;

  color: #fff;
  font-size: 0.9rem;
  text-transform: uppercase;
`;

import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import api from 'services/api';
import {
  fetchAnttTypes,
  fetchBodies,
  fetchCities,
  fetchImplements,
  fetchTrackerTypes,
  fetchTrackers,
  fetchVehiclesTypes,
  fetchShippingCompanies,
} from 'utils/fetches';

import {
  Badge,
  Button,
  Input,
  Modal,
  Radio,
  Select,
  Text,
} from 'components';
import { useSnackbar } from 'components/SnackbarV3';
import { updateVehicleSchema } from './validator';

export function UpdateVehicleData({
  isOpen,
  onClose,
  vehicle,
  setVehicle,
}) {
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [vehicleBodyTypes, setVehicleBodyTypes] = useState([]);
  const [vehicleImplementTypes, setVehicleImplementTypes] = useState([]);
  const [trackerTypes, setTrackerTypes] = useState([]);
  const [anttTypes, setAnttTypes] = useState([]);
  const [trackers, setTrackers] = useState(false);
  const snackbar = useSnackbar();
  const shouldDisplayImplement = useMemo(
    () => vehicle?.vehicleType?.qty_plates > 1,
    [vehicle?.vehicleType]
  );

  useEffect(() => {
    if (isOpen && vehicle?.id) {
      verifySchema(vehicle);
    }
    if (isOpen) {
      fetchOptions();
    }
  }, [isOpen]);

  useEffect(() => {
    if (!!vehicle?.tracker) {
      fetchTrackerTypesOptions(vehicle.tracker);
    }
  }, [vehicle?.tracker]);

  async function fetchTrackerTypesOptions(tracker) {
    try {
      const response = await api.get('select/tracker-types', {
        params: {
          tracker: tracker.id,
        },
      });
      setTrackerTypes(response.data);
    } catch (error) { }
  }

  async function fetchOptions() {
    try {
      setLoading(true);
      let [
        vehicleTypesData,
        vehiclesBodyTypesData,
        anttTypesData,
        trackersData,
        implementsData,
      ] = await Promise.all([
        fetchVehiclesTypes(),
        fetchBodies(),
        fetchAnttTypes(),
        fetchTrackers(),
        fetchImplements(),
      ]);
      vehicleTypesData = vehicleTypesData.filter(
        vtype => vtype.name !== 'Dolly' && vtype.name !== 'Semi Reboque'
      );
      setVehicleTypes(vehicleTypesData);
      setVehicleBodyTypes(vehiclesBodyTypesData);
      setAnttTypes(anttTypesData);
      setTrackers(trackersData);
      implementsData = implementsData.filter(vtype => vtype.name !== 'Dolly');
      setVehicleImplementTypes(implementsData);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  function handleCloseModal() {
    setVehicle(null);
    onClose();
  }
  async function verifySchema(payload) {

    try {
      await updateVehicleSchema.validate(payload, {
        abortEarly: false,
      });
      return true;
    } catch (error) {
      const errorResponse = {};
      if (error.inner) {
        error.inner.forEach(error => {
          errorResponse[error.path] = error.message;
        });
        setErrors(errorResponse);
      }
      return false;
    }
  }

  async function handleSubmit() {
    try {
      setLoading(true);
      const isValid = await verifySchema(vehicle);

      if (isValid === false) {
        setLoading(false);
        return;
      }
      const payload = {
        ...vehicle,
        plate_city_id: vehicle?.city?.id,
        plate_city_name: vehicle?.city?.name,
        plate_state: vehicle?.city?.province?.uf,
        tracker_type_id: vehicle?.tracker_type?.id,
        tracker_id: vehicle?.tracker?.id
      };
      delete payload.city;
      const response = await api.put(`v3/vehicle/main/${vehicle?.id}`, payload);

      snackbar.show(<Text>Salvo com sucesso</Text>, { type: 'success' });

      setLoading(false);
      setErrors({});
      onClose();
    } catch (error) {
      setVehicle(null);
      setLoading(false);
      snackbar.show(<Text>Erro ao editar veículo</Text>, {
        type: 'error',
      });
      return;
    }
  }

  return (
    <Modal
      size={'md'}
      show={isOpen}
      onHide={handleCloseModal}
      heading={
        <Text type="header" color="dark" weight="500">
          Atualizar dados do veículo
        </Text>
      }
      body={
        <Row className="mx-0 mt-1">
          <Col md={4} xs={12} className="mb-3">
            <Input
              label="Placa *"
              value={vehicle?.plate}
              onChange={event =>
                event.target.value.length > 7
                  ? snackbar.show(<Text>Limite de dígitos para placa</Text>, {
                    type: 'error',
                  })
                  : setVehicle(old => ({
                    ...old,
                    plate: event.target.value?.toUpperCase(),
                  }))
              }
              error={errors?.plate}
            />
          </Col>
          <Col md={4} xs={12} className="mb-3">
            <Select.Async
              label={'Cidade *'}
              onSearch={fetchCities}
              value={vehicle?.city}
              getOptionLabel={option =>
                `${option?.name}, ${option?.province?.uf ?? option?.uf}`
              }
              getOptionValue={option => option.id}
              onChange={value => {
                setVehicle(old => ({
                  ...old,
                  city: value,
                }));
              }}
              error={errors?.city}
            />
          </Col>
          <Col md={4} xs={12} className="mb-3">
            <Input
              label="Ano de fabricação *"
              type="number"
              value={vehicle?.year_manufacture}
              onChange={event =>
                event.target.value.length > 4
                  ? snackbar.show(<Text> Limite de dígitos para Ano </Text>, {
                    type: 'error',
                  })
                  : setVehicle(old => ({
                    ...old,
                    year_manufacture: event.target.value,
                  }))
              }
              error={errors?.year_manufacture}
            />
          </Col>
          <Col md={4} xs={12} className="mb-3">
            <Input
              label="Ano modelo *"
              type="number"
              value={vehicle?.year_model}
              onChange={event =>
                event.target.value.length > 4
                  ? snackbar.show(<Text> Limite de dígitos para Ano </Text>, {
                    type: 'error',
                  })
                  : setVehicle(old => ({
                    ...old,
                    year_model: event.target.value,
                  }))
              }
              error={errors?.year_model}
            />
          </Col>
          <Col md={4} xs={12} className="mb-3">
            <Input
              label="Chassi *"
              value={vehicle?.chassi}
              onChange={event =>
                event.target.value.length > 20
                  ? snackbar.show(
                    <Text> Limite de dígitos para Chassi </Text>,
                    {
                      type: 'error',
                    }
                  )
                  : setVehicle(old => ({ ...old, chassi: event.target.value }))
              }
              error={errors?.chassi}
            />
          </Col>
          <Col md={4} xs={12} className="mb-3">
            <Select
              label={'Tipo do veículo *'}
              options={vehicleTypes}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              value={vehicle?.vehicleType}
              onChange={value => {
                setVehicle(old => ({
                  ...old,
                  vehicleType: value,
                  vehicle_type_id: value?.id,
                }));
                setErrors(old => ({ ...old, vehicle_implement_type: null }));
              }}
              error={errors?.vehicleType}
            />
          </Col>
          {shouldDisplayImplement && (
            <Col md={4} xs={12} className="mb-3">
              <Select
                label="Tipo de implemento *"
                options={vehicleImplementTypes}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                value={vehicle?.implementType}
                onChange={value =>
                  setVehicle(old => ({
                    ...old,
                    implementType: value,
                    vehicle_implement_type: value?.id,
                  }))
                }
                error={errors?.vehicle_implement_type}
              />
            </Col>
          )}
          <Col md={4} xs={12} className="mb-3">
            <Select
              label={'Tipo do carroceria *'}
              options={vehicleBodyTypes}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              value={vehicle?.vehicleBodyType}
              onChange={value =>
                setVehicle(old => ({
                  ...old,
                  vehicleBodyType: value,
                  vehicle_body_type_id: value?.id,
                }))
              }
              error={errors?.vehicleBodyType}
            />
          </Col>
          <Col md={4} xs={12} className="mb-3">
            <Input
              label="Renavam *"
              type="number"
              value={vehicle?.renavam}
              onChange={event =>
                event.target.value.length > 11
                  ? snackbar.show(
                    <Text> Limite de dígitos para Renavam </Text>,
                    {
                      type: 'error',
                    }
                  )
                  : setVehicle(old => ({ ...old, renavam: event.target.value }))
              }
              error={errors?.renavam}
            />
          </Col>
          <Col md={4} xs={12} className="mb-3">
            <Select
              label={'Tipo de RTNRC *'}
              options={anttTypes}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              value={vehicle?.anttType}
              onChange={value =>
                setVehicle(old => ({
                  ...old,
                  anttType: value,
                  antt_type: value?.id,
                }))
              }
              error={errors?.anttType}
            />
          </Col>
          <Col md={4} xs={12} className="mb-3">
            <Input
              label={'RNTRC *'}
              type="number"
              value={vehicle?.antt}
              onChange={event =>
                setVehicle(old => ({ ...old, antt: event.target.value }))
              }
              error={errors?.antt}
            />
          </Col>
          <Col md={4} xs={12} className="mb-3">
            <Input
              label={'Adesão RNTRC *'}
              type="date"
              value={vehicle?.antt_adherence}
              onChange={event =>
                setVehicle(old => ({
                  ...old,
                  antt_adherence: event.target.value,
                }))
              }
              error={errors?.antt_adherence}
            />
          </Col>
          <Col md={4} xs={12} className="mb-3">
            <Select.Async
              label={'Proprietário do documento *'}
              onSearch={search => fetchShippingCompanies(search)}
              onChange={value =>
                setVehicle(old => ({
                  ...old,
                  owner_id: value?.id,
                  ownerData: value,
                }))
              }
              value={vehicle?.ownerData}
              getOptionLabel={option => `${option.socialName || option.social_name} - ${option.cpfCnpj || option.cgccpf}`}
              getOptionValue={option => option?.id}
              error={errors?.ownerData}
            />
          </Col>
          <Col md={4} xs={12} className="mb-3">
            <Select.Async
              label="Beneficiário do veiculo *"
              onSearch={search => fetchShippingCompanies(search)}
              onChange={value =>{
                setVehicle(old => ({
                  ...old,
                  beneficiary_id: value?.id,
                  beneficiary: value,
                }))
              }
              }
              value={vehicle?.beneficiary}
              getOptionLabel={option => `${option.socialName || option.social_name} - ${option.cpfCnpj || option.cgccpf}`}
              getOptionValue={option => option?.id}
              error={errors?.beneficiary}
            />
          </Col>

          <Col md={4} xs={12} className="mb-3">
            <Radio.Group
              label="Rastreado *"
              onChange={({ target }) =>
                setVehicle(old => ({ ...old, tracked: target.value }))
              }
              value={vehicle?.tracked}
              horizontal
            >
              <Radio value>
                <Text color="dark" type="label">
                  Sim
                </Text>
              </Radio>
              <Radio value={false}>
                <Text color="dark" type="label">
                  Não
                </Text>
              </Radio>
            </Radio.Group>
            {errors?.tracked && (
              <Badge className="mt-1" light variant="error">
                Campo obrigatório
              </Badge>
            )}
          </Col>
          <Col md={4} xs={12} className="mb-3">
            <Select
              placeholder={
                vehicle?.tracked ? 'Selecione...' : 'Veículo não rastreado'
              }
              isDisabled={!vehicle?.tracked}
              label={vehicle?.tracked ? 'Marca rastreador *' : 'Marca rastreador'}
              options={trackers}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              value={vehicle?.tracked ? vehicle?.tracker : null}
              onChange={value =>
                setVehicle(old => ({
                  ...old,
                  tracker: value,
                  tracker_type: null,
                }))
              }
              error={errors?.tracker}
            />
          </Col>
          <Col md={4} xs={12} className="mb-3">
            <Select.Async
              onSearch={search => fetchTrackerTypes(search, vehicle?.tracker)}
              value={vehicle?.tracker ? vehicle?.tracker_type : null}
              onChange={value =>
                setVehicle(old => ({ ...old, tracker_type: value }))
              }
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              label="Tipo de rastreador"
              placeholder={
                vehicle?.tracked
                  ? vehicle?.tracker
                    ? 'Selecione...'
                    : 'Veículo sem rastreador'
                  : 'Veículo não rastreado'
              }
              options={trackerTypes}
              isDisabled={!vehicle?.tracked || !vehicle?.tracker}
              error={errors?.tracker_type}
              NoOptionsComponent={() => (
                <Text>Nenhum tipo cadastrado para o rastreador informado</Text>
              )}
            />
          </Col>
          <Col md={4} xs={12} className="mb-3">
            <Input
              placeholder={
                vehicle?.tracked ? 'Digite...' : 'Veículo não rastreado'
              }
              disabled={!vehicle?.tracked}
              label={
                'ID do rastreador'
              }
              value={vehicle?.tracker_code}
              onChange={event =>
                setVehicle(old => ({
                  ...old,
                  tracker_code: event.target.value,
                }))
              }
              error={errors?.tracker_code}
            />
          </Col>
          <Col
            md={12}
            className="mb-3 d-flex align-items-center justify-content-center"
          >
            <Button
              className="mt-1 py-2"
              onClick={() => handleSubmit()}
              loading={loading}
            >
              <Text weight="500" type="regular">
                Salvar
              </Text>
            </Button>
          </Col>
        </Row>
      }
    />
  );
}
import api from 'services/api';
import vehicleSchema from './validator';
import vehicleTollTagSchema from '../Register/TollTags/validator';

export async function fetchCities(search) {
  try {
    const response = await api.get('cities', { params: { search } });
    return response.data;
  } catch (ex) {
    return [];
  }
}


export async function fetchBrands(search) {
  try {
    const response = await api.get(`manufacturers/select?search=${search}`);
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function fetchWagonsBrands(search) {
  try {
    const response = await api.get(`manufacturers/wagons?search=${search}`);
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function fetchSelectOptions(url, params = {}) {
  try {
    const { data } = await api.get(url, { params });

    return data;
  } catch (err) {
    return [];
  }
}

export const colors = [
  { label: 'AMARELO', value: 'AMARELO' },
  { label: 'AZUL', value: 'AZUL' },
  { label: 'BEGE', value: 'BEGE' },
  { label: 'BORDO', value: 'BORDO' },
  { label: 'BRANCO', value: 'BRANCO' },
  { label: 'CAQUI', value: 'CAQUI' },
  { label: 'CINZA', value: 'CINZA' },
  { label: 'DOURADO', value: 'DOURADO' },
  { label: 'GRENA', value: 'GRENA' },
  { label: 'GELO', value: 'GELO' },
  { label: 'LARANJA', value: 'LARANJA' },
  { label: 'MADERIA', value: 'MADERIA' },
  { label: 'MARROM', value: 'MARROM' },
  { label: 'METALICO', value: 'METALICO' },
  { label: 'PRATA', value: 'PRATA' },
  { label: 'PRETO', value: 'PRETO' },
  { label: 'ROXO', value: 'ROXO' },
  { label: 'VERDE', value: 'VERDE' },
  { label: 'VERMELHO', value: 'VERMELHO' },
  { label: 'VINHO', value: 'VINHO' },
  { label: 'VIOLETA', value: 'VIOLETA' },
];

export async function validate(data, setErrors) {
  const errorList = {};
  try {
    await vehicleSchema.validate(data, {
      abortEarly: false,
      context: { country: data.country, tollTags: data.tollTags },
    });

    setErrors({ ...errorList });

    return [true, errorList];
  } catch (err) {
    if (err.inner) {
      err.inner.forEach(error => {
        errorList[error.path] = error?.message;
      });
    }
    setErrors({ ...errorList });

    return [false, errorList];
  }
}

export async function validateTollTags(data, setErrors) {
  const errorList = {};
  try {
    await vehicleTollTagSchema.validate(data, {
      abortEarly: false,
    });
    setErrors({ ...errorList });
    return [true, errorList];
  } catch (err) {
    if (err.inner) {
      err.inner.forEach(error => {
        errorList[error.path] = error?.message;
      });
    }
    setErrors({ ...errorList });

    return [false, errorList];
  }
}

export const driverDocumentTypes = [
    { label: 'OUTROS_DOCUMENTOS', value: 1 },
    { label: 'RG', value: 2 },
    { label: 'LIBERACAO_GR', value: 3 },
    { label: 'COMPROVANTE_ENDERECO', value: 4 },
  ];
  
  export const shippingCompanyDocumentTypes = [
    { label: 'ALVARA SANITARIO', value: 1 },
    { label: 'ALVARA FUNCIONAMENTO', value: 2 },
    { label: 'DEDETIZACAO', value: 3 },
  ];
  
  export const shippingCompanyDocumentTypesInBrazilianPortuguese = {
    ALVARA_FUNCIONAMENTO: 'ALVARÁ FUNCIONAMENTO',
    ALVARA_SANITARIO: 'ALVARÁ SANITÁRIO',
    DEDETIZACAO: 'DEDETIZAÇÃO',
  };
  
  export const vehicleDocumentTypes = [
    { label: 'LICENCIAMENTO', value: 1 },
    { label: 'TACOGRAFO', value: 2 },
    { label: 'DEDETIZACAO', value: 3 },
  ];
  
  export const vehicleDocumentTypesInBrazilianPortuguese = {
    LICENCIAMENTO: 'LICENCIAMENTO',
    TACOGRAFO: 'TACÓGRAFO',
    DEDETIZACAO: 'DEDETIZAÇÃO',
  };
  export const driverDocumentTypesConst = {
    OUTROS_DOCUMENTOS: 'OUTROS DOCUMENTOS',
    RG: 'RG',
    LIBERACAO_GR: 'LIBERAÇÃO GR',
    COMPROVANTE_ENDERECO: 'COMPROVANTE ENDEREÇO',
  };
  
  export const wagonsDocumentTypes = [
    { label: 'LICENCIAMENTO', value: 1 },
    { label: 'LIBERAÇÃO', value: 2 },
    { label: 'OUTROS', value: 3 },
  ];
  
import React from 'react';

import Group, { GroupController } from './Group';
import * as Styled from './styles';

const [, useRadioGroupContext] = GroupController.RadioGroupContext;

const isDefined = value => value !== null && value !== undefined;

function Radio({ id, value, isChecked, disabled, onChange, children }) {
  const group = useRadioGroupContext();

  let isCheckedLocal = isChecked || false;
  if (group && isDefined(group.value) && isDefined(value)) {
    isCheckedLocal = group.value === value;
  }

  let onChangeLocal = onChange;
  if (group && group.onChange && isDefined(value)) {
    onChangeLocal = group.onChange;
  }

  function handleChange(event) {
    let val;
    try {
      val = JSON.parse(event.target.value);
    } catch (ex) {
      val = event.target.value;
    }

    onChangeLocal({
      ...event,
      target: { ...event.target, value: val },
    });
  }

  let disabledLocal = disabled;
  if (isDefined(group?.disabled)) {
    disabledLocal = group.disabled;
  }

  return (
    <Styled.Container>
      <Styled.Label htmlFor={id} disabled={disabledLocal}>
        <Styled.Input
          id={id}
          type="radio"
          onChange={handleChange}
          checked={isCheckedLocal}
          value={value}
          disabled={disabledLocal}
        />
        <div type="radio" />
        <Styled.Wrapper>{children}</Styled.Wrapper>
      </Styled.Label>
    </Styled.Container>
  );
}

Radio.Group = Group;

export default Radio;

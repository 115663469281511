import api from 'services/api';

export async function fetchCities(search) {
  try {
    const response = await api.get('cities', { params: { search } });
    return response.data;
  } catch (ex) {
    return [];
  }
}

export async function fetchAnttTypes(setAnttTypes) {
  try {
    const response = await api.get(`antt-types`);
    setAnttTypes(response.data);
  } catch (error) {
    setAnttTypes([]);
  }
}

export async function fetchWagonsBrands(search) {
  try {
    const response = await api.get(`manufacturers/wagons?search=${search}`);
    return response.data;
  } catch (error) {
    return [];
  }
}

export const colors = [
  { label: 'AMARELO', value: 'AMARELO' },
  { label: 'AZUL', value: 'AZUL' },
  { label: 'BEGE', value: 'BEGE' },
  { label: 'BORDO', value: 'BORDO' },
  { label: 'BRANCO', value: 'BRANCO' },
  { label: 'CAQUI', value: 'CAQUI' },
  { label: 'CINZA', value: 'CINZA' },
  { label: 'DOURADO', value: 'DOURADO' },
  { label: 'GRENA', value: 'GRENA' },
  { label: 'GELO', value: 'GELO' },
  { label: 'LARANJA', value: 'LARANJA' },
  { label: 'MADERIA', value: 'MADERIA' },
  { label: 'MARROM', value: 'MARROM' },
  { label: 'METALICO', value: 'METALICO' },
  { label: 'PRATA', value: 'PRATA' },
  { label: 'PRETO', value: 'PRETO' },
  { label: 'ROXO', value: 'ROXO' },
  { label: 'VERDE', value: 'VERDE' },
  { label: 'VERMELHO', value: 'VERMELHO' },
  { label: 'VINHO', value: 'VINHO' },
  { label: 'VIOLETA', value: 'VIOLETA' },
];


/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';

import apiCooperplace from 'services/api/cooperplace';
import Text from 'components/Text';
import Select from 'components/Select';
import Button from 'components/Button';
import Card, { DefaultLoadingBodyComponent } from 'components/Card';
import { fetchShippingCompanies } from 'utils/fetches'

function ShippingCompany({ driver, getDriver, loadingCard }) {
  const [shippingCompany, setShippingCompany] = useState({});
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});

  useEffect(() => {
    if (driver) {
      setShippingCompany(driver.driverShipping.shipping)
    }
  }, [driver]);

  async function handleSubmit() {

    if (!shippingCompany?.id) {
      setError({ shippingCompanyError: 'Campo obrigatório' });
      return;
    }
    setLoading(true)
    try {
      const response = await apiCooperplace.put(`carrierWeb/driver/${driver.id}/shippingCompany/${shippingCompany.id}`);
      setError({});
      return response.data
    } catch (er) {
      //
    } finally {
      setEditing(false);
      setLoading(false);
      getDriver();
    }
  }

  return (
    <Card
      loading={loadingCard}
      LoadingBodyComponent={() => (
        <DefaultLoadingBodyComponent linesCount={1} perLine={1} />
      )}
      header={
        <Text color="#464E5F" type="header">
          Transportadora
        </Text>
      }
      HeaderRightComponent={
        editing ? (
          <div>
            <Button
              onClick={() => setEditing(false)}
              variant="secondary"
              loading={loading}
              className="mr-2"
            >
              <Text color="dark" type="regular">
                Cancelar
              </Text>
            </Button>
            <Button onClick={handleSubmit} variant="success" loading={loading}>
              <Text weight={500} type="regular">
                Salvar
              </Text>
            </Button>
          </div>
        ) : (
          <Button variant="secondary" onClick={() => setEditing(true)}>
            <Text weight={500} type="regular">
              Editar
            </Text>
          </Button>
        )
      }
    >
      {editing ? (
        <div className="form">
          <Select.Async
            label="Selecione uma transportadora *"
            onSearch={value => fetchShippingCompanies(value)}
            value={shippingCompany}
            horizontal
            onChange={value => {
              if (value) {
                setShippingCompany(value)
              } else {
                setShippingCompany([])
              }
            }}
            getOptionLabel={option => `${option.socialName || option.social_name} - ${option.cpfCnpj || option.cgccpf}`}
            getOptionValue={option => option.id}
            error={error.shippingCompanyError}
          />
        </div>
      ) : (
        <Text type="regular" color="#494950" as="p" className="mt-2">
          {shippingCompany.label}
        </Text>
      )}
    </Card>
  );
}

export default ShippingCompany;

import styled, { css } from 'styled-components';

import theme from 'styles/theme';

export const StyledButton = styled.button`
  padding: ${({ size }) => theme.spacing.buttonY[size]}
    ${({ size }) => theme.spacing.buttonX[size]};
  outline: none !important;
  ${({ circle }) =>
    !circle &&
    css`
      border-radius: 5px;
    `}
  transition: all ease-in 0.15s;
  background-color: ${({ type = 'primary' }) =>
    theme.colors.button[type].background} !important;
  color: ${({ type = 'primary' }) => theme.colors.button[type].text} !important;

  ${({ outlined }) =>
    outlined &&
    css`
      background-color: ${({ type = 'primary' }) =>
        theme.colors.button[type].outline.background} !important;
      border: 1px solid
        ${({ type = 'primary' }) => theme.colors.button[type].outline.text} !important;
      color: ${({ type = 'primary' }) =>
        theme.colors.button[type].outline.text} !important;
    `}
  ${({ disabled }) =>
    disabled
      ? css`
          cursor: default;
          opacity: 0.5;
        `
      : css`
          &:active {
            transform: scale(0.9);
          }
          &:hover {
            opacity: 0.9;
          }
        `}

  ${({ circle }) =>
    circle &&
    css`
      width: 20px;
      height: 20px;
      text-align: center;
      padding: 0px 3px;
      font-size: 12px;
      line-height: 1.428571429;
      border-radius: 100%;
    `}

    ${({ link }) =>
    link &&
    css`
      background: none !important;
      color: ${theme.colors.light_blue} !important;
      padding: 0;
    `}
`;

import styled from 'styled-components';

export const ContainerFile = styled.div`
  border: 1px dashed #e5eaee;
  border-radius: 5px;
  background-color: #fcfcfc;
  padding: 15px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 100%;

  overflow: hidden;

  cursor: pointer;

  .icon {
    margin-bottom: 10px;
  }

  .form-control-file {
    display: none;
  }
`;

import styled from 'styled-components';

export const ModalLauncher = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  margin: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  &:focus {
    outline: none;
  }
`;

import api from 'services/api';
import { convertToDatabase } from 'utils/number';
import moment from 'moment';

import { validate, validateTollTags } from '../shared/controller';

export async function handleCreation(
  data,
  setData,
  setErrors,
  showSnack,
  setLoading
) {
  const isValid = await validate(data, setErrors);

  if (!isValid[0]) {
    if (!isValid[1].drivers)
      showSnack('Verifique os campos obrigatórios!', { type: 'error' });
    return false;
  }

  if (data.tollTags?.length > 0) {
    const isValidTollTags = await validateTollTags(data.tollTags, setErrors);
    if (!isValidTollTags) {
      showSnack('Verifique as tags de pedágio!', { type: 'error' });
      return false;
    }
  }

  let wagons = [];
  if (
    data.implements &&
    data.implements?.length > 0 &&
    data.implements?.[0] !== undefined
  ) {
    wagons = data.implements?.map(item => ({
      id: item?.identifier?.id,
    }));
  }

  let tollTags = [];
  if (data.tollTags?.length > 0) {
    tollTags = data.tollTags;
  }

  try {
    setLoading(true);
    const payload = {
      antt: data.antt,
      antt_owner: data.anttOwner.id || null,
      antt_type: data.anttType?.id || null,
      capacity: data.capacity,
      chassi: data.chassi,
      crlv: data.crlv,
      chronotachograph_number: data.chronotachograph_number,
      chronotachograph_exp_date: data.chronotachograph_exp_date
        ? moment(data.chronotachograph_exp_date, 'DD/MM/YYYY').format()
        : undefined,
      country_id: data.country?.id || null,
      color: data.color?.label,
      cubage: data.cubage,
      drivers: data.drivers,
      driver_id: data.drivers.find(driver => driver.current === true)?.value,
      fuel_id: data.fuel?.id || null,
      vehicle_implement_type: data.implement?.id || null,
      wagons: wagons || [],
      manufacturer_id: data.brand?.id || null,
      model: data.model?.label,
      brand: data.brand?.name,
      owner_id: data.owner.id || null,
      plate: data.plate,
      plate_city_id: data.city?.id,
      plate_city: data.city?.name,
      plate_state: data.province?.uf,
      rntrc_exp_date: data.rntrc_exp_date
        ? moment(data.rntrc_exp_date, 'DD/MM/YYYY').format()
        : undefined,
      renavam: data.renavam,
      tags: data.tags ? data.tags.map(tag => tag.id) : [],
      tare_mass: data.tare_mass,
      tracked: data.isTracked,
      tracker_id: data.tracker?.id,
      tracker_type_id: data.tracker_type?.id,
      tracker_code: data.tracker_code,
      tracker_login: data.tracker_login,
      tracker_password: data.tracker_password,
      toll_tags: tollTags,
      total_gross_weight: +convertToDatabase(data.total_gross_weight),
      vehicle_type_id: data.type?.id,
      vehicle_axis_id: data.axis?.id,
      vehicle_body_type_id: data.body?.id,
      year_manufacture: data.year_manufacture,
      year_model: data.year_model,
      antt_adherence: data?.antt_adherence,
      beneficiary_id:data?.beneficiary.id
    };

    const response = await api.post('vehicles', payload);
    showSnack('Veículo Cadastrado', { type: 'success' });
    setLoading(false);
    setData({ id: response.data?.vehicle?.id });
    return true;
  } catch (err) {
    setLoading(false);
    if (err.response) {
      const errorsNew = err.response?.data;
      if (errorsNew && Array.isArray(errorsNew)) {
        setErrors(old => ({
          ...old,
          ...Object.fromEntries(
            errorsNew.map(error => [error.field, error.message])
          ),
        }));
      }
    }
    showSnack('Erro ao cadastrar veículo!', { type: 'error' });
    return false;
  }
}
import apiCooperplace from 'services/api/cooperplace';

export async function getVehicles(
  filter,
  page = 1,
  { setVehicles, setLoading, setPagination }
) {

  if (page === 1) setLoading(true);

  try {
    if (filter === '?') filter = '';
    const response = await apiCooperplace.get(`carrierWeb/vehicles?${filter}&page=${page}`);

    const { data, meta } = response.data;
    setVehicles(oldVehicles => {
      if (page === 1) {
        return data;
      }
      return [...oldVehicles, ...data];
    });
    setPagination(meta);
  } catch (error) {
    setVehicles([])
    setPagination({
      last_page: 1,
      current_page: 1,
      total: 0,
    });

  } finally {
    setLoading(false);
  }
}

/* eslint-disable import/no-unresolved */
import styled from 'styled-components';
import theme from '../../../styles/theme';

export const Container = styled.div`
  position: absolute;
  top: 41px;
  overflow-y: scroll;
  width: 300px;
  transform: translateX(-200px);

  @media (max-width: 576px) {
    transform: translateX(0px);
  }

  @media (max-width: 767px) {
    transform: translateX(10px);
  }

  @media (max-width: 991px) {
    transform: translateX(0px);
  }

  ::-webkit-scrollbar-track {
    background-color: #fff;
  }
  ::-webkit-scrollbar {
    width: 8px;
    height: 4px;
    background: #f6f2f9;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 2rem;
    background: ${theme.colors.light_blue};
  }

  max-height: 80vh;
`;

export const Notification = styled.div`
  cursor: pointer;
  transition: opacity 0.1s ease-in;
  border-bottom: 1px solid ${theme.colors.gray};
  background-color: ${theme.colors.light};
  padding: 15px;

  &.border-none {
    border: none;
  }

  &:hover {
    opacity: 0.8;
  }

  &.is-read {
    background-color: #fff;

    &:hover {
      h2,
      span {
        color: ${theme.colors.dark} !important;
      }
    }
  }
`;
import React from 'react';
import { Link } from 'react-router-dom';

import { Col, Row } from 'react-bootstrap';

import Card from "react-bootstrap/Card";
import Button from 'components/Button'
import Tooltip from "components/Tooltip"
import Text from "components/Text"

import { FaQuestionCircle } from 'react-icons/fa';
import theme from 'styles/theme';

export function ImplementCard({ implement }) {

  const buttonDetaisValid = () => {
    const implementBeneficiary = implement?.beneficiaryWagon?.usersShippingCompanies.find(shipping => {
      if (shipping.personId === implement?.beneficiaryId) {
        return shipping
      }
    })

    const implementOwner = implement?.ownerWagon?.usersShippingCompanies.find(shipping => {
      if (shipping.personId === implement?.ownerId) {
        return shipping
      }
    })

    if (implementBeneficiary) {
      return true
    }

    if (!implementBeneficiary && implementOwner) {
      return false
    }
  }

  return (
    <Col lg={6} md={6} xs={12} className="p-2">
      <Card className="card-list">
        <Card.Body>
          <Row className="p-3" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Col xs="8">
              <span className="card-title">
                <i className="fas fa-truck mr-2" />
                {implement.plate === null ? "não encontrada" : implement.plate}
              </span>
              <br />
            </Col>
            {
              buttonDetaisValid() ? (
                <Col xs="4" className="text-right">
                  <Link to={`/implementos/${implement.id}`}>
                    <Button variant="primary">Detalhes</Button>
                  </Link>
                </Col>
              ) : (
                <Tooltip
                  content={
                    <Text type="label" color="white">
                      Implemento editável somente pelo beneficiário do mesmo. Para mais informações,
                      entre em contato através do email coopercarga@coopercarga.com.br.
                    </Text>
                  }
                >
                  <FaQuestionCircle style={{ color: theme.colors.light_blue }} />
                </Tooltip>
              )
            }
            <Col xs={12}>
              <span className="card-label">Proprietário:</span>
              <span className="card-text">
                {implement?.ownerWagon?.socialName || 'Não informado'}
              </span>
            </Col>
            <Col xs={12}>
              <span className="card-label">Beneficiário:</span>
              <span className="card-text">
                {implement?.beneficiaryWagon?.socialName || 'Não informado'}
              </span>
            </Col>
            <Col xs={12}>
              <span className="card-label">Beneficiário CNPJ/CPF:</span>
              <span className="card-text">
                {implement?.ownerWagon?.cpfCnpj || 'Não informado'}
              </span>
            </Col>
            <Col xs={12}>
              <span className="card-label">Modelo:</span>
              <span className="card-text">
                {implement?.model || 'Não informado'}
              </span>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
}

function PolylineItem({
  markers,
  map,
  maps,
  strokeColor = '#0BB7AF',
}) {
  const lineSymbol = {
    path: maps.SymbolPath.FORWARD_OPEN_ARROW,
    fillColor: '#1c4b75',
    strokeColor: '#1c4b75',
    strokeWeight: 0.9,
    scale: 2,
  };
  const geodesicPolyline = new maps.Polyline({
    path: markers,
    geodesic: true,
    strokeColor: strokeColor,
    strokeWeight: 6,
    scale: 2,

    icons: [
      {
        fillColor: '#333',
        strokeColor: '#333',
        icon: lineSymbol,
        offset: '100%',
        repeat: '100px',
      },
    ],
  });

  geodesicPolyline.setMap(map);

  return null;
}

export default PolylineItem;

import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import Header from 'components/Header';

import Container from "react-bootstrap/Container";

import Button from 'components/Button';
import Text from 'components/Text';
import Space from 'components/Space';

import { Section } from '../shared/Section';
import { Data } from '../shared/Data';

import { useImplement } from '../shared/context';

import { handleCreation } from './controller';
import { OwnerData } from '../shared/Owner';


export function ImplementRegister() {
  const history = useHistory();
  const implement = useImplement();

  const [loading, setLoading] = useState(false);

  return (
    <>
      <Header
        title="Cadastrar implemento"
        RightComponent={
          <Space x={'0.5rem'}>
            <Button
              variant="secondary"
              onClick={() => history.push('/implementos')}
            >
              <Text color="dark" weight={500}>
                Voltar
              </Text>
            </Button>
            <Button
              variant="primary"
              loading={loading}
              onClick={() =>
                handleCreation(
                  implement.data,
                  implement.setData,
                  implement.setErrors,
                  implement.showSnackbar,
                  setLoading,
                  history
                )
              }
            >
              <Text color="white" weight={500}>
                Salvar
              </Text>
            </Button>
          </Space>
        }
      />
      <Container>
        <Row>
          <Col xs={12} md={6}>
            <Section Title="Dados do implemento">
              <Data />
            </Section>
          </Col>
          <Col xs={12} md={6}>
            <Section Title="Proprietário">
              <OwnerData />
            </Section>
          </Col>
        </Row>
      </Container>
    </>
  );
}

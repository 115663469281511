export const cnhTypes = [
    { label: 'A' },
    { label: 'AB' },
    { label: 'AC' },
    { label: 'AD' },
    { label: 'AE' },
    { label: 'B' },
    { label: 'C' },
    { label: 'D' },
    { label: 'E' },
  ];
  
  export const homeTypes = [
    { label: 'Outros' },
    { label: 'Próprio' },
    { label: 'Alugado' },
    { label: 'Financiado' },
  ];
  
  export const addressTypes = [
    { label: 'Casa' },
    { label: 'Apartamento' },
    { label: 'Zonal Rural' },
  ];
  
  export const civilStatusList = [
    {
      value: 3,
      label: 'Solteiro',
    },
    {
      value: 2,
      label: 'Casado',
    },
    {
      value: 1003,
      label: 'Divorciado',
    },
    {
      value: 1,
      label: 'Viúvo',
    },
  ];
  
  export const rgMask = [
    /[0-9]/,
    '.',
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    '.',
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
  ];
  
  export const cpfMask = [
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    '.',
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    '.',
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    '-',
    /[0-9]/,
    /[0-9]/,
  ];
  
  export const pisMask = [
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    '.',
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    '.',
    /[0-9]/,
    /[0-9]/,
    '-',
    /[0-9]/,
  ];
  
  export const phoneMask = [
    '(',
    /[0-9]/,
    /\d/,
    ')',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];
  
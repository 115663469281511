import axios from 'axios';

// eslint-disable-next-line import/no-cycle
import { getToken } from './auth';

const apiCooperplace = axios.create({
  baseURL: process.env.REACT_APP_COOPERPLACE_API_URL,
});

apiCooperplace.interceptors.request.use(async config => {
  const outputConfig = config;

  const token = getToken();

  if (token) {
    outputConfig.headers.Authorization = `Bearer ${token}`;
  }

  return outputConfig;
});

export default apiCooperplace;

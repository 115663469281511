const Yup = require('yup');

const schema = Yup.array().of(
  Yup.object().shape({
    tollTag: Yup.object()
      .required('Campo obrigatório')
      .typeError('Campo obrigatório'),
    number: Yup.string().required('Campo obrigatório'),
  })
);

export default schema;

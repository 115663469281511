import Types from './types';

const Actions = {
  fetchDrivers: params => ({
    type: Types.FETCH_DRIVERS,
    payload: { params },
  }),
  fetchDriversSuccess: drivers => ({
    type: Types.FETCH_DRIVERS_SUCCESS,
    payload: { drivers },
  }),
  fetchDriversFailure: error => ({
    type: Types.FETCH_DRIVERS_FAILURE,
    payload: { error },
  }),
};

export default Actions;

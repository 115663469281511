/* eslint-disable import/no-cycle */
import { all } from 'redux-saga/effects';

import auth from './modules/auth/sagas';
import provinces from './modules/provinces/sagas';
import cities from './modules/cities/sagas';
import vehicle from './modules/vehicle/sagas';
import driver from './modules/driver/sagas';
import deals from './modules/deals/sagas';

export default function* sagas() {
  return yield all([auth, provinces, cities, vehicle, driver, deals]);
}

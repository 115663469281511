import api from 'services/api';

export async function getTravels(
  filter,
  page = 1,
  { setTravels, setLoading, setPagination }
) {
  if (page === 1) setLoading(true);

  try {
    const response = await api.get(`travel-data?${filter}&page=${page}`,{
      params:{
        carrier:true
      }
    });
    
    const { data, ...rest } = response.data;
    setPagination(rest);
    setTravels(oldTravels => {
      if (page === 1) {
        return data;
      }
      return [...oldTravels, ...data];
    });
  } catch (error) {
    // snackbar
  } finally {
    setLoading(false);
  }
}

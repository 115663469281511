export const CTE_STATUS_VARIANTS = {
    1: 'success',
    2: 'primary',
    3: 'warning',
    4: 'error',
    5: 'error',
    6: 'warning',
    7: 'success',
    8: 'warning',
    9: 'primary',
    10: 'primary',
  };

  export const isEmailValidRegex =
  /^(([^<>()\[\]\\.,;:\s@áàâãéèêíïóôõöúùçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  export const vehicleRequiredFields = [
    'plate',
    'renavam',
    'chassis',
    'yearModel',
    'yearManufacture',
    'plateCityId',
    'vehicleTypeId',
    'vehicleBodyTypeId',
    'anttType',
    'ownerId',
    'anttAdherence',
    'antt',
    'beneficiaryId'
  ];

  export const validAnttRegex = /[0-9.]$/;

  export const trackedVehicleRequiredFiels = [
    'tracked',
    'tracker',
  ];
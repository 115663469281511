import React, { forwardRef, useState, useEffect } from 'react';
import { FaQuestionCircle } from 'react-icons/fa';
import MaskedInput from 'react-text-mask';
import Tooltip from 'components/Tooltip';
import theme from 'styles/theme';

import {
  Input,
  Container,
  ContainerInside,
  IconSpace,
  RightIcon,
  ErrorText,
  Label,
} from './Styled';

function StyledInput(
  {
    defaultValue,
    label,
    labelWhiteSpace = 'normal',
    labelColor = '#464e5f',
    placeholderSize = '14px',
    type,
    onChange,
    onBlur,
    onFocus,
    placeholder,
    icon,
    rightIcon,
    error,
    masked,
    guide,
    mask = [],
    containerStyle,
    inputAs,
    rightIconColor = '#e5eaee',
    rightIconAction,
    tooltip = '',
    IconSpaceColor = '#e5eaee',
    fontSize = '0.875rem',
    fontColor = '#494950',
    ...rest
  },
  ref
) {
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    if (ref?.current) {
      ref.current.value = rest.value || '';
    }
  }, [rest.value]);

  function handleFocus() {
    setFocus(true);

    if (onFocus) {
      onFocus();
    }
  }

  if (masked)
    return (
      <Container style={containerStyle}>
        {label && (
          <div className="w-100">
            <Label
              labelColor={labelColor}
              type="label"
              weight="500"
              labelWhiteSpace={labelWhiteSpace}
            >
              {label}
            </Label>
            {tooltip.length > 0 && (
              <Tooltip
                content={
                  <Label type="label" color="white">
                    {tooltip}
                  </Label>
                }
              >
                <FaQuestionCircle
                  className="ml-2 mt-1"
                  style={{ color: theme.colors.light_blue }}
                />
              </Tooltip>
            )}
          </div>
        )}
        <ContainerInside focus={focus}>
          {icon && (
            <IconSpace IconSpaceColor={IconSpaceColor}>{icon}</IconSpace>
          )}
          <MaskedInput
            defaultValue={defaultValue}
            mask={mask}
            guide={guide}
            onChange={onChange}
            onBlur={onBlur}
            render={(r, props) => (
              <Input
                focus={focus}
                error={error}
                onFocus={handleFocus}
                {...rest}
                fontSize={fontSize}
                fontColor={fontColor}
                placeholder={placeholder}
                placeholderSize={placeholderSize}
                onChange={onChange}
                ref={inputElement => {
                  if (inputElement) {
                    if (r) {
                      r(inputElement);
                    }

                    if (ref) {
                      ref.current = inputElement;
                    }
                  }
                }}
                type={type}
                {...props}
                autoComplete="new-password"
              />
            )}
          />
          {rightIcon && <RightIcon>{rightIcon}</RightIcon>}
        </ContainerInside>
        {error && (
          <ErrorText color="error" type="little">
            {error}
          </ErrorText>
        )}
      </Container>
    );

  return (
    <Container style={containerStyle}>
      {label && (
        <div
          className="w-100"
          style={{ display: tooltip?.length > 0 ? 'flex' : '' }}
        >
          <Label
            labelColor={labelColor}
            type="label"
            weight="500"
            style={{ display: 'block' }}
            labelWhiteSpace={labelWhiteSpace}
          >
            {label}
          </Label>
          {tooltip.length > 0 && (
            <Tooltip
              content={
                <Label type="label" color="white">
                  {tooltip}
                </Label>
              }
            >
              <FaQuestionCircle
                className="ml-2 mt-1"
                style={{ color: theme.colors.light_blue }}
              />
            </Tooltip>
          )}
        </div>
      )}
      <ContainerInside>
        {icon && <IconSpace IconSpaceColor={IconSpaceColor}>{icon}</IconSpace>}
        <Input
          {...rest}
          ref={ref}
          defaultValue={defaultValue}
          focus={focus}
          error={error}
          onFocus={handleFocus}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          type={type}
          as={inputAs}
          autoComplete="new-password"
          fontSize={fontSize}
          fontColor={fontColor}
        />
        {rightIcon && (
          <RightIcon onClick={rightIconAction} rightIconColor={rightIconColor}>
            {rightIcon}
          </RightIcon>
        )}
      </ContainerInside>
      {error && (
        <ErrorText color="error" type="little">
          {error}
        </ErrorText>
      )}
    </Container>
  );
}

export default forwardRef(StyledInput);

/* eslint-disable import/no-unresolved */
import React, { useState, useEffect, useRef, useMemo, } from 'react';
import moment from 'moment';
import Map from 'components/Map';
import Badge from 'components/Badge';
import Text from 'components/Text';
import Modal from 'components/Modal';
import List from 'components/BulletList';
import MarkerLetter from 'components/Map/MarkerLetter';
import { formatAddress } from 'utils/formatter';
import { Row, Col } from 'react-bootstrap';
import theme from 'styles/theme';
import * as Styled from './styles';
import { fetchCountries } from 'utils/fetches';

const actionOptions = [
  { name: 'Coleta', id: 'collect' },
  { name: 'Entrega', id: 'delivery' },
  { name: 'Aduaneiro', id: 'customhouse' },
];

export default function Address({
  originsData,
  destinationsData,
}) {

  const mapsRef = useRef(null);
  const mapRef = useRef(null);

  const [origins, setOrigins] = useState([]);
  const [destinations, setDestinations] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [show, setShow] = useState(false);

  const locations = useMemo(
    () => [
      ...origins.map(origin => ({
        latitude: origin.lat,
        longitude: origin.lng,
      })),
      ...destinations.map(destination => ({
        latitude: destination.lat,
        longitude: destination.lng,
      })),
    ],
    [destinations, origins]
  );

  const getMapBounds = () => {
    const maps = mapsRef.current;
    if (maps) {
      const bounds = new maps.LatLngBounds();
      if (locations[0].latitude && locations[0].longitude) {
        locations.forEach(item => {
          bounds.extend(new maps.LatLng(item.latitude, item.longitude));
        });
      }

      return bounds;
    }

    return null;
  };


  useEffect(() => {
    fetchCountries(setCountryList);
  }, []);


  useEffect(() => {
    const bounds = getMapBounds();
    if (mapRef.current) {
      mapRef.current.fitBounds(bounds);
    }
  }, [destinations, origins]);

  const ordered = useMemo(() => {
    return {
      origins: origins.sort(
        (a, b) =>
          moment(a.scheduledTime, 'DD/MM/YYYY HH:mm').toDate().valueOf() -
          moment(b.scheduledTime, 'DD/MM/YYYY HH:mm').toDate().valueOf()
      ),
      destinations: destinations.sort(
        (a, b) =>
          moment(a.destinationDate, 'DD/MM/YYYY HH:mm').toDate().valueOf() -
          moment(b.destinationDate, 'DD/MM/YYYY HH:mm').toDate().valueOf()
      ),
    };
  }, [origins, destinations]);

  function getDestinations() {
    if (destinationsData) {
      const formatted_destinations = destinationsData.map(destination => ({
        ...destination,
        country: countryList.find(
          element => element.abbreviation === destination.countrySlug
        ),
        zipCode: destination.zipCode || '',
        number: destination.number || '',
        neighborhood: destination.neighborhood || '',
        complement: destination.complement || '',
        action: actionOptions.find(
          ({ id }) => id === destination.action.toLowerCase()
        ),
        scheduledTime: destination.scheduledTime
          ? moment(destination.scheduledTime).format('DD/MM/YYYY HH:mm')
          : '',
        startSchedule: destination.startSchedule
          ? moment(destination.startSchedule).format('DD/MM/YYYY HH:mm')
          : '',
        type: 'destination',
      }));

      setDestinations(formatted_destinations);
    }
  }
  useEffect(() => getDestinations(), [destinationsData]);

  function getOrigins() {
    if (originsData) {
      const formatted_origins = originsData.map(origin => ({
        ...origin,
        country: countryList.find(
          element => element.abbreviation === origin.countrySlug
        ),
        zipCode: origin.zipCode || '',
        number: origin.number || '',
        neighborhood: origin.neighborhood || '',
        complement: origin.complement || '',
        action: actionOptions.find(({ id }) => id === 'collect'),
        scheduledTime: origin.scheduledTime
          ? moment(origin.scheduledTime).format('DD/MM/YYYY HH:mm')
          : '',
        formattedAddress: origin.formatted,
        startSchedule: origin.startSchedule
          ? moment(origin.startSchedule).format('DD/MM/YYYY HH:mm')
          : '',
        type: 'origin',
      }));

      setOrigins(formatted_origins);
    }
  }
  useEffect(() => getOrigins(), [originsData]);


  function onGoogleAPILoaded(map, maps) {
    if (map) {
      mapRef.current = map;
      mapsRef.current = maps;

      setTimeout(() => {
        const bounds = getMapBounds();

        map.fitBounds(bounds);
      }, 100);
    }
  }

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  function locationsObject(showAllDestinations = true) {
    const destinationsToDisplay = showAllDestinations
      ? ordered.destinations
      : ordered.destinations.slice(-1);

    const items = [
      ...ordered.origins.map(item => ({
        header: (
          <div className="d-flex flex-row justify-content-between">
            <Text>{item.action.name}</Text>
          </div>
        ),
        body: (
          <>
            <Text className="my-2" color="gray" type="regular">
              {formatAddress(item)}
            </Text>
            <Text color="gray" type="regular">
              {item.startSchedule && `${item.startSchedule} até `}
              {item.scheduledTime}
            </Text>
          </>
        ),
        filledCircle: false,
      })),
    ];

    if (!showAllDestinations && ordered.destinations.length > 1)
      items.push({
        header: (
          <Text color="light_blue" type="regular">
            <Styled.ModalLauncher onClick={handleShow} className="">
              {ordered.destinations.length - 1} destinos
            </Styled.ModalLauncher>
          </Text>
        ),
        link: true,
      });

    items.push(
      ...destinationsToDisplay.map(item => ({
        header: (
          <div className="d-flex flex-row justify-content-between">
            <Text>{item.action.name}</Text>
          </div>
        ),
        body: (
          <>
            <Text className="my-2" color="gray" type="regular">
              {formatAddress(item)}
            </Text>
            <Text color="gray" type="regular">
              {item.startSchedule && `${item.startSchedule} até `}
              {item.scheduledTime}
            </Text>
          </>
        ),
        filledCircle: true,
      }))
    );

    return items;
  }

  return (
    <>
      <Modal
        scrollable
        show={show}
        onHide={handleClose}
        heading={<Text>Endereço</Text>}
        body={
          <Row>
            <Col xs={12} className="mt-4 pl-4">
              <List items={locationsObject()} />
            </Col>
          </Row>
        }
      />
      <Col xs={12}>
        <Col lg={12} md={12} xs={12}>
          <div className="container-fluid load-adresses list-maps pt-3 pb-5">
            <Col xs={12}>
              {locations.length > 0 && (
                <Map
                  height={300}
                  yesIWantToUseGoogleMapApiInternals
                  position={{
                    lat: locations[0].latitude,
                    lng: locations[0].longitude,
                  }}
                  onGoogleApiLoaded={({ map, maps }) =>
                    onGoogleAPILoaded(map, maps)
                  }
                >
                  {origins.length > 0 &&
                      origins.map(origin => (
                        <MarkerLetter
                          background={theme.colors.light_blue}
                          lat={origin.lat}
                          lng={origin.lng}
                          color="#fff"
                          letter="C"
                        />
                      ))}
                  {destinations.length > 0 &&
                      destinations.map(destination => (
                        <MarkerLetter
                          background={theme.colors.light_blue}
                          lat={destination.lat}
                          lng={destination.lng}
                          color="#fff"
                          letter={
                            destination.action.id === 'delivery' ? 'E' : 'A'
                          }
                        />
                      ))}
                </Map>
              )}
            </Col>
            {origins.length === 0 && destinations.length === 0 && (
              <Col xs={12} className="text-center">
                <Badge type="warning" outlined className="rounded">
                  Essa carga ainda nao possui origem e nem destino.
                </Badge>
              </Col>
            )}
            <Col xs={12} className="pt-3">
              <List items={locationsObject(false)} />
            </Col>
          </div>
        </Col>
      </Col>
    </>
  );
}

import React, { useEffect, useState } from 'react';

import Container from 'react-bootstrap/Container';
import InfiniteScroll from 'components/InfiniteScroll';
import Button from 'components/Button';
import Text from 'components/Text';
// eslint-disable-next-line import/no-cycle
import Header from 'components/Header';
import { DefaultLoadingComponent } from 'components/List';
import CardLoad from './Card';
import LoadFilter from '../Filter'
import api from 'services/api';

export default function LoadDetail() {
  const [isLoading, setLoading] = useState(false);
  const [loads, setLoads] = useState([]);
  const [pagination, setPagination] = useState({
    lastPage: 0,
    page: 1,
    total: 0,
  });

  const [finalFilter, setFinalFilter] = useState('');
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
  const [qtdAdvancedFilters, setQtdAdvancedFilters] = useState(0);

  const handleClose = () => setShowAdvancedFilters(false);
  const handleShow = () => setShowAdvancedFilters(true);

  function handleTitleAdvancedFilters() {
    let title = 'Filtro avançado';
    if (qtdAdvancedFilters) {
      title += ` (${qtdAdvancedFilters})`;
    }
    return title;
  }

  async function fetchLoads(finalFilter, page = 1) {
    if (page === 1) setLoading(true);

    try {
      const response = await api.get(`carrier-loads?${finalFilter}&page=${page}`);
      const { data, ...rest } = response.data;
      setPagination(rest);
      setLoads(oldLoads => {
        if (page === 1) {
          return data;
        }

        return [...oldLoads, ...data];
      });
    } catch (error) {
      // Handle exception
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (finalFilter) {
      fetchLoads(finalFilter, 1);
    }
  }, [finalFilter]);

  return (
    <>
      <Header
        RightComponent={
          <Button variant="secondary" onClick={() => handleShow()}>
            <Text weight={500} color="black" type="regular">
              {handleTitleAdvancedFilters()}
            </Text>
          </Button>
        }
      />

      <Container>
        <LoadFilter
          setFinalFilter={setFinalFilter}
          showAdvancedFilters={showAdvancedFilters}
          handleClose={handleClose}
          handleShow={handleShow}
          setQtdAdvancedFilters={setQtdAdvancedFilters}
          resultsTotal={pagination.total}
          isFetching={isLoading && loads.length === 0}
        />
      </Container>
      <Container>
        <InfiniteScroll
          loading={isLoading}
          data={loads}
          renderItem={item => <CardLoad load={item} />}
          onEndReach={() => fetchLoads(finalFilter, pagination.page + 1)}
          hasMore={pagination.page < pagination.lastPage}
          scrollThreshold={0.7}
          loader={pagination.page > 1 ? <DefaultLoadingComponent /> : null}
        />
      </Container>
    </>
  );
}

import React, { useEffect, useState } from "react";
import Text from "components/Text";
import Button from "components/Button";
import InfiniteScroll from "components/InfiniteScroll";
import Header from "components/Header";
import Container from "react-bootstrap/Container";

import CardTravel from "./Card";
import TravelFilter from "./Filter";

import * as Controller from "./controller";

export default function Travels() {
  const [finalFilter, setFinalFilter] = useState("");
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
  const [qtdAdvancedFilters, setQtdAdvancedFilters] = useState(0);

  const [loading, setLoading] = useState(false);
  const [travels, setTravels] = useState([]);
  const [pagination, setPagination] = useState({
    lastPage: 1,
    page: 1,
    total: 0,
  });

  function fetchTravels(filters = {}, page = 0) {
    Controller.getTravels(filters, page || pagination.page, {
      setTravels,
      setLoading,
      setPagination,
    });
  }

  const handleClose = () => setShowAdvancedFilters(false);
  const handleShow = () => setShowAdvancedFilters(true);

  function handleTitleAdvancedFilters() {
    let title = "Filtro avançado";
    if (qtdAdvancedFilters) {
      title += ` (${qtdAdvancedFilters})`;
    }
    return title;
  }

  // Ao atualizar o filtro e "filtrar"
  useEffect(() => {
    if (finalFilter) {
      fetchTravels(finalFilter, 1);
    }
  }, [finalFilter]);

  function renderItem(items) {
    return <CardTravel travel={items} />;
  }

  return (
    <>
      <Header
        title="Viagens"
        RightComponent={
          <>
            <Button
              variant="secondary"
              onClick={() => handleShow()}
            >
              <Text weight={500} color="dark" type="regular">
                {handleTitleAdvancedFilters()}
              </Text>
            </Button>
          </>
        }
      />
      <Container>
        <TravelFilter
          setFinalFilter={setFinalFilter}
          showAdvancedFilters={showAdvancedFilters}
          handleClose={handleClose}
          handleShow={handleShow}
          setQtdAdvancedFilters={setQtdAdvancedFilters}
        />
      </Container>

      <Container>
        <InfiniteScroll
          loading={loading}
          data={travels}
          renderItem={renderItem}
          onEndReach={() => {
            Controller.getTravels(finalFilter, pagination.page + 1, {
              setTravels,
              setLoading,
              setPagination,
            });
          }}
          hasMore={pagination.page < pagination.lastPage}
          scrollThreshold={0.7}
        />
      </Container>
    </>
  );
}

/* eslint-disable import/no-unresolved */
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Text from 'components/Text';
import Card, { DefaultLoadingBodyComponent } from 'components/Card';
import { useTravelDetail } from '../context';

function Negotiator() {
  const { data, isFetching } = useTravelDetail();
  if (isFetching) {
    return null;
  }

  return (
    <Card
      loading={isFetching}
      LoadingBodyComponent={() => (
        <DefaultLoadingBodyComponent linesCount={3} perLine={2} />
      )}
      header={
        <Row>
          <Col xs={12} className="mb-2">
            <Text color="dark" type="header">
              Negociação
            </Text>
          </Col>
        </Row>
      }
    >
      <Row>
        <Col md={12} className="mb-3">
          <Text type="label" color="dark">
            Negociador
          </Text>
          <div className="d-flex flex-column">
            <Text type="regular" color="gray">
              {data?.load.negotiator
                ? `${data?.load?.negotiator?.username}`
                : 'Não informado'}
            </Text>
          </div>
        </Col>

        <Col md={12}>
          <Text type="label" color="dark">
            Observações
          </Text>

          <Text type="regular" color="gray" as="p" className="mt-2" wordwrap={true}>
            {data?.negotiator_comments
              ? data?.negotiator_comments
              : 'Não informado'}
          </Text>
        </Col>
      </Row>
    </Card>
  );
}

export default Negotiator;

export default {
  VALIDATE_EMAIL: 'auth/VALIDATE_EMAIL',
  VALIDATE_EMAIL_SUCCESS: 'auth/VALIDATE_EMAIL_SUCCESS',
  VALIDATE_EMAIL_FAILURE: 'auth/VALIDATE_EMAIL_FAILURE',
  VALIDATE_RESET: 'auth/VALIDATE_RESET',
  SIGN_IN: 'auth/SIGN_IN',
  SIGN_IN_SUCCESS: 'auth/SIGN_IN_SUCCESS',
  SIGN_IN_FAILURE: 'auth/SIGN_IN_FAILURE',
  SIGN_OUT: 'auth/SIGN_OUT',
};

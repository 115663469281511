import React, { useEffect, useState } from "react";
import { createContext } from 'utils/react-helpers';

import { useParams } from "react-router-dom";

import { useSnackbar } from "components/SnackbarV3";
import Text from "components/Text";

import apiCooperplace from "services/api/cooperplace";

export const LoadContext = createContext({
  name: 'LoadContext',
  strict: false,
});

const [LoadProvider, useLoad] = LoadContext

export default function LoadUseContext({ children }) {
  const snackbar = useSnackbar();
  const { load_id } = useParams();
  const [load, setLoad] = useState({});
  const [isFetching, setFetching] = useState(false);

  function showSnackbar(text, options) {
    snackbar.show(<Text> {text} </Text>, options);
  }
  async function fetchLoadById() {
    try {
      setFetching(true);
      const response = await apiCooperplace.get(`carrierWeb/load/${load_id}`);
      setLoad(response.data);
    } catch (ex) {
      console.error(ex);
    } finally {
      setFetching(false);
    }
  }

  useEffect(() => {
    setLoad(null)
    if (load_id) {
      fetchLoadById();
    }
  }, [load_id]);

  return (
    <LoadProvider
      value={{
        load,
        isFetching,
        showSnackbar,
      }}
    >
      {children}
    </LoadProvider>
  );
}

export { useLoad }
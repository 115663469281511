import React from 'react';

import Row from 'react-bootstrap/Row';
import { Button, Text } from 'components';
import Spinner from 'react-bootstrap/Spinner';
import Col from 'react-bootstrap/Col';

import theme from 'styles/theme';

export function DefaultEmptyComponent() {
  return (
    <Col className="pt-5 d-flex justify-content-center align-items-center">
      <Button colors={theme.colors.primary} size="md" disabled>
        Não há itens a serem exibidos
      </Button>
    </Col>
  );
}

export function DefaultLoadingComponent() {
  return (
    <Col className="pt-5 d-flex justify-content-center align-items-center">
      <Button variant="secondary" size="md" disabled>
        <div className="d-flex flex-row align-items-center">
          <Text type="body" color="dark">
            Carregando
          </Text>
          <Spinner animation="border" role="status" className="ml-3" />
        </div>
      </Button>
    </Col>
  );
}

function List({
  data,
  loading,
  renderItem,
  EmptyComponent = DefaultEmptyComponent,
  LoadingComponent = DefaultLoadingComponent,
}) {
  const display = data.length ? data.map(renderItem) : <EmptyComponent />;
  return (
    <Row className="row-eq-height">
      {loading ? <LoadingComponent /> : display}
    </Row>
  );
}

export default List;


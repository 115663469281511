import React from 'react';

import Space from 'components/Space';
import Button from 'components/Button';
import Text from 'components/Text';
import Tooltip from "components/Tooltip"
import theme from 'styles/theme';

import { FaQuestionCircle } from 'react-icons/fa';

import { useVehicle } from '../shared/context';
import Col from 'react-bootstrap/Col';

export default function Editable({ isEditing, setEditing, onSave, isLoading, isDisabledManagement }) {
  const vehicle = useVehicle();

  function handleCancel() {
    setEditing(false);
    vehicle.setData(vehicle.prevVehicle);
    vehicle.setErrors({});
  }

  if (isEditing) {
    return (
      <Space x="5px">
        <Button variant="secondary" onClick={handleCancel}>
          <Text color="dark" weight="500" type="regular">
            Cancelar
          </Text>
        </Button>
        <Button variant="success" onClick={onSave} loading={isLoading}>
          <Text color="white" weight={500}>
            Salvar
          </Text>
        </Button>
      </Space>
    );
  }
  if (isDisabledManagement) {
    return (
      <Col style={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
        <Button variant="secondary" onClick={() => setEditing(true)} disabled={isDisabledManagement}>
          <Text color="dark" weight="500" type="regular">
            Editar
          </Text>
        </Button>
        <Tooltip
          content={
            <Text type="label" color="white">
              O veículo está cadastrado na gestão de frota e a edição de alguns campos está restrita ao seu operador.
              Para mais informações, entre em contato pelo e-mail: coopercarga@coopercarga.com.br
            </Text>
          }
        >
          <FaQuestionCircle style={{ color: theme.colors.light_blue }} />
        </Tooltip>
      </Col>
    )
  }
  return (
    <Button variant="secondary" onClick={() => setEditing(true)}>
      <Text color="dark" weight="500" type="regular">
        Editar
      </Text>
    </Button>
  );
}

import React from 'react';

import { Row, Col } from 'react-bootstrap';

import moment from 'moment';
import Text from 'components/Text';
import Card, { DefaultLoadingBodyComponent } from 'components/Card';
import { useTravelDetail } from '../context';

function Vehicle() {
  const { data, isFetching } = useTravelDetail();

  if (isFetching) {
    return null;
  }
  return (
    <>
      <Card
        loading={isFetching}
        LoadingBodyComponent={() => (
          <DefaultLoadingBodyComponent linesCount={2} perLine={2} />
        )}
        header={
          <Text color="dark" type="header">
            Veículo
          </Text>
        }
      >
          <>
            <Row>
              <Col xs={12} md={6}>
                <Text type="label" color="dark">
                  Placa principal
                </Text>
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {data?.vehicleAttended?.vehicle?.plate ||
                    'Não informada'}
                </Text>
              </Col>

              <Col xs={12} md={6}>
                <Text type="label" color="dark">
                  Telefone proprietário
                </Text>
                <Text type="regular" color="dark" as="p" className="mt-2">
                  {data?.vehicleAttended?.vehicle?.owner?.phone ||
                    'Não informado'}
                </Text>
              </Col>

              {data?.vehicleAttended?.wagonsAttended &&
                data?.vehicleAttended?.wagonsAttended.map(wagons => (
                  <>
                    <Col xs={12} md={6}>
                      <Text type="label" color="dark">
                        Placa implemento
                      </Text>
                      <Text type="regular" color="gray" as="p" className="mt-2">
                        {wagons.wagon?.plate}
                      </Text>
                    </Col>
                  </>
                ))}
              <Col xs={12} md={6}>
                <Text type="label" color="dark">
                  Validade RNTRC
                </Text>
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {data?.vehicleAttended?.vehicle?.rntrc_exp_date
                    ? moment(
                        data?.vehicleAttended?.vehicle?.rntrc_exp_date,
                        'YYYY-MM-DD'
                      ).format('DD/MM/YYYY')
                    : '-'}
                </Text>
              </Col>
              <Col xs={12} md={6}>
                <Text type="label" color="dark">
                  Validade cronotacógrafo
                </Text>
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {data?.vehicleAttended?.vehicle
                    ?.chronotachograph_exp_date
                    ? moment(
                        data?.vehicleAttended?.vehicle
                          ?.chronotachograph_exp_date,
                        'YYYY-MM-DD'
                      ).format('DD/MM/YYYY')
                    : '-'}
                </Text>
              </Col>

              <Col xs={12} md={6}>
                <Text type="label" color="dark">
                  ID do rastreador
                </Text>
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {data?.vehicleAttended?.vehicle?.tracker_code ||
                    'Não informado'}
                </Text>
              </Col>

              <Col xs={12} md={6}>
                <Text type="label" color="dark">
                  Login do rastreador
                </Text>
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {data?.vehicleAttended?.vehicle?.tracker_login ||
                    'Não informado'}
                </Text>
              </Col>

              <Col xs={12} md={6}>
                <Text type="label" color="dark">
                  Senha do rastreador
                </Text>

                <Text type="regular" color="gray" as="p" className="mt-2">
                  {data?.vehicleAttended?.vehicle?.tracker_password ||
                    'Não informado'}
                </Text>
              </Col>
              <Col xs={12} md={6}>
                <Text type="label" color="dark">
                  Tags
                </Text>
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {data?.vehicleAttended?.vehicle?.tags
                    ?.map(tag => tag.name)
                    ?.join(', ') || 'Não informado'}
                </Text>
              </Col>
            </Row>
          </>
      </Card>
    </>
  );
}

export default Vehicle;

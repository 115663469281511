import { takeLatest, call, put, all } from 'redux-saga/effects';

// eslint-disable-next-line import/no-cycle
import api from 'services/api';

import Types from './types';
import Actions from './actions';

export function* fetchProvinces() {
  try {
    const response = yield call(api.get, 'provinces');

    yield put(Actions.fetchProvincesSuccess(response.data));
  } catch (ex) {
    yield put(Actions.fetchProvincesFailure());
  }
}

export default all([takeLatest(Types.FETCH_PROVINCES, fetchProvinces)]);

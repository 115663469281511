import React from 'react';

import Spinner from 'react-bootstrap/Spinner';

export default function Loader({ loading, size = 'sm', ...props }) {
  return loading ? (
    <Spinner
      as="span"
      animation="border"
      size={size}
      role="status"
      aria-hidden="true"
      variant="secondary"
      {...props}
    />
  ) : null;
}

import React, { forwardRef } from 'react';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { ContainerCard, CardBody, CardHeader } from './styles';

export function DefaultLoadingBodyComponent({ linesCount = 4, perLine = 2 }) {
  return (
    <SkeletonTheme>
      {new Array(linesCount).fill(0).map((_, index) => (
        <p key={index}>
          <Skeleton count={perLine} key={String(index)} />
        </p>
      ))}
    </SkeletonTheme>
  );
}

export function DefaultLoadingHeaderComponent() {
  return (
    <div className="mx-5 pt-5 mb-3">
      <Skeleton height={80} />
    </div>
  );
}

function Card(
  {
    header,
    loading,
    children,
    HeaderRightComponent,
    LoadingBodyComponent = DefaultLoadingBodyComponent,
    LoadingHeaderComponent = DefaultLoadingHeaderComponent,
    bodyCardPadding = '2rem 3rem',
    containerCardBoxShadow = '0px 0px 30px rgba(0, 0, 0, 0.1)',
    ...props
  },
  ref
) {
  return (
    <ContainerCard
      className="mb-3"
      ref={ref}
      {...props}
      containerCardBoxShadow={containerCardBoxShadow}
    >
      {header && (
        <>
          {loading ? (
            <LoadingHeaderComponent />
          ) : (
            <CardHeader>
              {header}
              {HeaderRightComponent}
            </CardHeader>
          )}
        </>
      )}
      <CardBody bodyCardPadding={bodyCardPadding}>
        {loading ? <LoadingBodyComponent /> : children}
      </CardBody>
    </ContainerCard>
  );
}

export default forwardRef(Card);

import styled, { css } from 'styled-components';
import Autocomplete from 'react-google-autocomplete';
import Text from 'components/Text';

export const AutocompleteStyled = styled(Autocomplete)`
  border: 1px solid #e5eaee;
  border-radius: 5px;
  width: 100%;
  height: 44px;
  padding: 10px;

  font-family: Poppins;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.02em;
  color: #494950;

  ::placeholder {
    color: #494950;
  }

  ${props =>
    props.focus &&
    css`
      box-shadow: none !important;
    `}
  ${props =>
    props.hasError &&
    css`
      border-color: #f64e60 !important;
    `};
`;

export const Label = styled(Text)`
  margin-bottom: 10px;
`;

export const ErrorText = styled(Text)`
  margin-bottom: 5px;
  ${props =>
    props.color === 'danger' &&
    css`
      color: #f64e60 !important;
    `};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

import * as Yup from 'yup';

const schema = Yup.object().shape({
  oldPassword: Yup.string().required('Informe sua senha antiga'),
  newPassword: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!\"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~])[A-Za-z0-9!\"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]{8,}$/,
      'A senha precisa ter no mínimo 8 caracteres, 1 letra maiúscula, 1 letra minúscula, e um caracter especial'
    )
    .required('Informe a nova senha'),
  newPasswordConfirmation: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'As senhas precisam ser iguais')
    .required('Confirme a senha'),
});

export async function validatePasswordUpdate(data, setErrors) {
  const errorList = {};
  try {
    await schema.validate(data, {
      abortEarly: false,
    });
    return true;
  } catch (err) {
    err.inner.forEach(error => {
      errorList[error.path] = error?.message;
    });

    return false;
  } finally {
    setErrors(errorList);
  }
}

import { plateValidator } from 'utils/vehicle';
import * as Yup from 'yup';;

export const updateVehicleSchema = Yup.object().shape({
  plate: Yup.string()
    .required('Placa obrigatória')
    .nullable()
    .when('country', {
      is: value => ['ar', 'br', 'py', 'uy'].includes(value?.abbreviation),
      then: Yup.string().test('plateValidation', 'Placa inválida', value =>
        plateValidator(value)
      ),
    })
    .typeError('Placa obrigatória'),
  year_manufacture: Yup.number()
    .nullable()
    .transform(original => {
      if (!original) {
        return undefined;
      } else return original;
    })
    .required('Informe ano de fabricação'),
  year_model: Yup.number()
    .nullable()
    .transform(original => {
      if (!original) {
        return undefined;
      } else return original;
    })
    .required('Informe ano do modelo'),
  chassi: Yup.string()
    .nullable()
    .required('Informe o chassi')
    .max(200, 'Máximo de 200 caracteres'),
  vehicleType: Yup.object()
    .shape({
      id: Yup.number().required(),
      name: Yup.string().required(),
    })
    .nullable()
    .required('Tipo de veículo obrigatório'),
  vehicleBodyType: Yup.object()
    .shape({
      id: Yup.number().required(),
    })
    .nullable()
    .required('Tipo de carroceria obrigatória'),
  // vehicle_implement_type: Yup.number()
  //   .nullable()
  //   .when('vehicleType', {
  //     is: vehicleType => vehicleType?.name === 'Cavalo Mecânico',
  //     then: Yup.number().nullable().required('Tipo de implemento obrigatório'),
  //   }),

  renavam: Yup.string().nullable().required('Informe o renavam'),
  anttType: Yup.object()
    .shape({
      id: Yup.number().required(),
    })
    .nullable()
    .required('Tipo de RNTRC obrigatório'),
  antt: Yup.number()
    .nullable()
    .transform(original => {
      if (!original) {
        return undefined;
      }
      return original;
    })
    .required('Informe número RNTRC'),
  antt_adherence: Yup.string()
    .nullable()
    .test('not-empty', 'Informe uma data', value => {
      if (!value) {
        return false;
      }
      return true;
    })
    .test('valid-date', 'Formato inválido', value => {
      return /^\d{4}\-\d{2}\-\d{2}$/.test(value);
    })
    .test(
      'valid-past-or-present-date',
      ({ originalValue }) => {
        return originalValue !== undefined &&
          originalValue !== '' &&
          originalValue !== null
          ? 'Data não pode ser posterior à data de hoje'
          : 'Informe uma data';
      },
      value => {
        if (!value) {
          return false;
        }

        const inputDate = new Date(value);
        const currentDate = new Date();
        return inputDate <= currentDate;
      }
    ),
  tracked: Yup.boolean().nullable().required('Campo obrigatório'),
  tracker: Yup.object()
    .nullable()
    .shape({
      id: Yup.number(),
    })

    .when('tracked', {
      is: value => value,
      then: Yup.object()
        .shape({
          id: Yup.number(),
        })
        .nullable()
        .required('Rastreador obrigatório'),
    }),
  tracker_type: Yup.object()
    .nullable()
    .shape({
      id: Yup.number(),
    }),
    tracker_code: Yup.string()
    .nullable(),
  city: Yup.object()
    .shape({
      id: Yup.number().required('Cidade obrigatória'),
    })
    .nullable()
    .required('Cidade obrigatória'),
  ownerData: Yup.object()
    .nullable()
    .shape({
      id: Yup.number().required('Proprietário obrigatório'),
    })
    .required('Proprietário obrigatório'),
  beneficiary: Yup.object()
    .nullable()
    .shape({
      id: Yup.number().required('Beneficiário obrigatório'),
    })
    .required('Beneficiário obrigatório'),
});

import { useState } from 'react';
import { ValidationError } from 'yup';

import { parseErrors } from 'utils/formatter';

export default function useValidation(schema, paramOptions = {}) {
  const { pathPrefix } = paramOptions;
  const [errors, setErrors] = useState({});

  async function validate(value, options = {}) {
    try {
      const { abortEarly = false, context = {} } = options;
      const valid = await schema.validate(value, { abortEarly, context });

      setErrors({});

      return [true, valid];
    } catch (ex) {
      if (ex instanceof ValidationError) {
        setErrors(parseErrors(ex.inner, { prefix: pathPrefix }));
      }
      return [false];
    }
  }

  async function validateAt(path, value, options = {}) {
    try {
      const { abortEarly = false, context = {} } = options;
      const valid = await schema.validateAt(path, value, {
        abortEarly,
        context,
      });

      setErrors({});

      return [true, valid];
    } catch (ex) {
      if (ex instanceof ValidationError) {
        setErrors(parseErrors(ex.inner, { prefix: pathPrefix }));
      }
      return [false];
    }
  }

  return [errors, { validate, validateAt, setErrors }];
}

import React, { useEffect, useState } from 'react';
import NestedList from 'components/NestedList';
import { getTexts } from './texts'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import Modal from 'components/Modal';
import Text from 'components/Text';

export default function HelpModal({ isOpem, handleClose }) {

  const helpsTexts = getTexts()

  return (
    <>
      <Modal
        scrollable
        show={isOpem}
        onHide={handleClose}
        heading={
          <Text
            weight={600}
            fontSize={20}
            color={'#76838F'}>Ajuda e informações
          </Text >
        }
        body={
          < Row >
            <Col xs={12} className="mt-4 pl-4">
              {
                helpsTexts.map(({ title, text }, index) =>
                  <NestedList title={title} text={text.split('\n').map(line => (
                    <>
                      {line}
                      <br />
                    </>
                  ))} key={index} />)
              }
            </Col>
          </ Row>
        }
      />
    </>
  );
}

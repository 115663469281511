import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

import Text from "components/Text";
import Select from "components/Select";
import Input from "components/Input";
import Button from "components/Button";
import Modal from "components/Modal";

import useQuery from "hooks/useQuery";

import {
  fetchVehiclesTypes,
  fetchBodyTypes,
  fetchAxisTypes,
  fetchShippingCompanies,
  fetchDriverByVehicleId
} from "utils/fetches";

export default function VehicleFilter({
  setFinalFilter,
  showAdvancedFilters,
  handleClose,
  setQtdAdvancedFilters,
}) {
  const history = useHistory();
  const query = useQuery();
  const [loading] = useState(false);

  // Filtros simples
  const [selectedDriver, setSelectedDriver] = useState([]);
  const [plate, setPlate] = useState("");
  const [shippingCompanies, setShippingCompanies] = useState([])

  // Filtros avançados
  const [advancedFilters, setAdvancedFilters] = useState({});
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [bodyTypes, setBodyTypes] = useState([]);
  const [axisTypes, setAxisTypes] = useState([]);

  function applyQueryParameters() {
    let queryParams = query.toString();
    if (queryParams.trim() === "") queryParams = "?";
    setFinalFilter(queryParams);
    history.replace({ search: queryParams });
  }

  async function fetchInitial() {
    const responseVehicle = await fetchVehiclesTypes();
    setVehicleTypes(responseVehicle);

    const responseBody = await fetchBodyTypes();
    setBodyTypes(responseBody);

    const responseAxis = await fetchAxisTypes();
    setAxisTypes(responseAxis);
  }

  function handleAdvancedFilter(key, value, process = (v) => v) {
    setAdvancedFilters((previous) => ({ ...previous, [key]: value }));

    const processedValue = process(value);
    if (processedValue) {
      query.set(key, processedValue);
    } else {
      query.delete(key);
    }
  }

  useEffect(() => {
    fetchInitial();
  }, []);

  useEffect(() => {
    const isNotEmpty = (value) =>
      value !== null && value !== undefined && value !== "";

    setQtdAdvancedFilters(
      Object.values(advancedFilters).filter(isNotEmpty).length
    );
  }, [advancedFilters]);

  function onSubmit(event) {
    event.preventDefault();

    applyQueryParameters();
  }

  return (
    <>
      <Modal
        size="lg"
        show={showAdvancedFilters}
        backdrop="static"
        heading="Filtro Avançado"
        animation
        onHide={handleClose}
        style={{ height: "80%", top: "10%", overflow: "visible" }}
        body={
          <Row className="p-1 form" style={{ overflow: "visible" }}>
            <Col md={6} xs={12}>
              <Select
                label="Tipo de Veículo"
                value={advancedFilters.vehicleTypeId}
                options={vehicleTypes}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                onChange={(value) =>
                  handleAdvancedFilter("vehicleTypeId", value, (v) => v?.id)
                }
              />
            </Col>
            <Col md={6} xs={12}>
              <Select
                label="Tipo de Carroceria"
                value={advancedFilters.vehicleBodyTypeId}
                options={bodyTypes}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                onChange={(value) =>
                  handleAdvancedFilter("vehicleBodyTypeId", value, (v) => v?.id)
                }
              />
            </Col>
            <Col md={6} xs={12}>
              <Select
                label="Tipo de Eixo"
                value={advancedFilters.vehicleAxesId}
                options={axisTypes}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                onChange={(value) =>
                  handleAdvancedFilter("vehicleAxesId", value, (v) => v?.id)
                }
              />
            </Col>
          </Row>
        }
        footer={
          <Row style={{ justifyContent: "flex-end" }}>
            <Button
              variant="primary"
              loading={loading}
              onClick={() => {
                applyQueryParameters();
                handleClose();
              }}
            >
              <Text color="white" weight={500}>
                Filtrar
              </Text>
            </Button>
          </Row>
        }
      />
      <form onSubmit={onSubmit}>
        <Row className="filter">
          <Col lg={2} md={2} xs={12}>
            <Input
              label="Placa"
              labelColor="#fff"
              type="text"
              placeholder="Digite..."
              value={plate}
              onChange={(e) => {
                if (e.target.value.trim()) {
                  setPlate(e.target.value);
                  query.set("plate", e.target.value);
                } else {
                  setPlate("");
                  query.delete("plate");
                }
              }}
            />
          </Col>
          <Col lg={4} md={4} xs={12}>
            <Select.Async
              label="Motorista"
              onSearch={value => fetchDriverByVehicleId(value)}
              value={selectedDriver}
              horizontal
              onChange={(value) => {
                if (value) {
                  setSelectedDriver(value);
                  query.set("driverId", value.id);
                } else {
                  setSelectedDriver([]);
                  query.delete("driverId");
                }
              }}
              getOptionLabel={(option) => option.socialName}
              getOptionValue={(option) => option.id}
              labelColor="#fff"
            />
          </Col>
          <Col lg={4} md={4} xs={12}>
            <Select.Async
              multiple
              label="Transportadora"
              onSearch={value => fetchShippingCompanies(value)}
              value={shippingCompanies}
              horizontal
              onChange={value => {
                if (value?.length > 0) {
                  setShippingCompanies(value)
                  query.set("shippingCompanie", value.map(val => val.id))
                } else {
                  setShippingCompanies([])
                  query.delete('shippingCompanie')
                }
              }}
              getOptionLabel={option => `${option.socialName} - ${option.cpfCnpj}`}
              getOptionValue={option => option.id}
              labelColor="#fff"
            />
          </Col>
          <Col md={2} xs={12} className="vertical bottom">
            <Button
              variant="success"
              loading={loading}
              style={{ marginTop: 12, padding: '12px 30px' }}
              type="submit"
            >
              <Text type="regular" weight={500}>
                Filtrar
              </Text>
            </Button>
          </Col>
        </Row>
      </form>
    </>
  );
}

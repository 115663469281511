import React, { useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useParams } from 'react-router-dom';
import Select from 'components/Select';
import { useImplement } from '../shared/context';
import { fetchShippingCompanies } from 'utils/fetches'

export function OwnerData() {
  const implement = useImplement();
  const params = useParams();

  useEffect(() => {
    if (!params.implement_id) {
      implement.setData({
        owner: null,
        anttOwner: null,
        beneficiary: null
      });
    }
  }, []);

  return (
    <Row className="form">
      <Col xs={12}>
        <Select.Async
          label='Proprietário do documento *'
          onSearch={value => fetchShippingCompanies(value)}
          value={implement?.data?.owner?.id ? implement?.data?.owner : null}
          horizontal
          onChange={value => { implement.setData({ owner: value }) }}
          getOptionLabel={option => `${option.socialName || option.social_name} - ${option.cpfCnpj || option.cgccpf}`}
          getOptionValue={option => option.id}
          error={implement.errors?.['owner.id']}
        />
      </Col>
      <Col xs={12}>
        <Select.Async
          label='Beneficiário do implemento *'
          onSearch={value => fetchShippingCompanies(value)}
          value={implement?.data?.beneficiary?.id ? implement?.data?.beneficiary : null}
          horizontal
          onChange={value => { implement.setData({ beneficiary: value }) }}
          getOptionLabel={option => `${option.socialName || option.social_name} - ${option.cpfCnpj || option.cgccpf}`}
          getOptionValue={option => option.id}
          error={implement.errors?.['beneficiary.id']}
        />
      </Col>
      <Col xs={12}>
        <Select.Async
          label={'Proprietário RNTRC *'}
          onSearch={value => fetchShippingCompanies(value)}
          onChange={value => implement.setData({ anttOwner: value })}
          value={implement?.data?.anttOwner?.id ? implement?.data?.anttOwner : null}
          getOptionLabel={option => `${option.socialName || option.social_name} - ${option.cpfCnpj || option.cgccpf}`}
          getOptionValue={option => option.id}
          error={implement.errors?.['anttOwner.id']}
        />
      </Col>
    </Row>
  );
}

import { format, parseISO } from 'date-fns';
import moment from 'moment';
import 'moment/locale/pt'
export function convertToDatabase(value) {
  if (!value) {
    return 0;
  }

  return value.toString().split('.').join('').split(',').join('.');
}

const currencyFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

export function formatCurrency(value) {
  return currencyFormatter.format(value);
}

const numberFormatter = new Intl.NumberFormat('pt-BR');

export function formatNumber(value) {
  return numberFormatter.format(value);
}

export function formatDistance(distanceInKM = 0) {
  const fixed = distanceInKM.toFixed(0);

  if (distanceInKM < 1) {
    const distanceInMeters = fixed * 1000;
    return String(distanceInMeters).concat('m');
  }

  return String(fixed).concat(' km');
}

export function parse(value) {
  return parseISO(value);
}

export function formatDateTime(value) {
  if (!value) {
    return 'Não informado';
  }

  return format(parse(value), 'dd/MM/yyyy HH:mm');
}

export function formatTime(value) {
  if (!value) {
    return '';
  }

  return format(parse(value), 'HH:mm');
}

export function formatDate(value) {
  if (!value) {
    return 'Não informado';
  }

  return format(parse(value), 'dd/MM/yyyy');
}

export function formatCustom(value, formatter) {
  if (!value) {
    return null;
  }

  return format(parse(value), formatter);
}

export function capitalize(value) {
  if (typeof value !== 'string') return value;
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function pluralize(str, quantity, strPluralized) {
  if (quantity !== 1) {
    return strPluralized || str.concat('s');
  }

  return str;
}

export function formatPlate(plate) {
  if (!plate) {
    return '-';
  }

  if (plate.charAt(4).match(/[0-9]/)) {
    return plate.replace(/^([a-zA-Z]{3})([0-9]{4})$/i, '$1-$2').toUpperCase();
  }

  return plate.toUpperCase();
}

export function parseErrors(errors, options = {}) {
  const { prefix, getPath = error => error.path } = options;

  return errors.reduce((acc, error) => {
    const output = acc;
    let path = getPath(error);
    if (prefix) {
      path = prefix + path;
    }
    output[path] = error.message;
    return output;
  }, {});
}

export function makeConversion(value, op, exchangeRate) {
  if (exchangeRate === 0) {
    return 0;
  }
  let aux = 0;
  op === '/' ? (aux = value / exchangeRate) : (aux = value * exchangeRate);
  if (isNaN(aux)) {
    return 0;
  } else return aux;
}

export const currencyWithSymbol = [
  { currency: 'BRL', symbol: 'R$' },
  { currency: 'USD', symbol: '$' },
];

export const conversionParams = {
  $: { symbol: 'R$', currencyName: 'BRL', operation: '*' },
  R$: { symbol: '$', currencyName: 'USD', operation: '/' },
};

export function formatDateCustom(value, format) {
  if (!value) {
    return null;
  }

  return moment(value).locale('pt').format(format);
}

export function formatAddress({
  address,
  number,
  complement,
  neighborhood,
  city,
  province,
  zip_code,
  country_slug,
}) {
  const builder = [];

  if (address) {
    builder.push(address);

    if (number) {
      builder.push(`, ${number}`);
    }

    if (complement) {
      builder.push(` - ${complement}`);
    }

    if (neighborhood) {
      builder.push(` - ${neighborhood},`);
    }
  }

  if (city) {
    builder.push(` ${city}`);

    if (province) {
      builder.push(` - ${province}`);
    }

    if (zip_code) {
      builder.push(`, ${zip_code}`);
    }
  }

  if (country_slug) {
    builder.push(` - ${country_slug.toUpperCase()}`);
  }

  return builder.join('');
}

export function capitalizeFullname(value) {
  if (!value) {
    return '';
  }

  return value
    .toLowerCase()
    .split(' ')
    .map(word =>
      word.length >= 2
        ? word.replace(/(^\w{1})/g, match => match.toUpperCase())
        : word
    )
    .join(' ');
}
import React, { useMemo } from 'react';
import { Row, Col } from 'react-bootstrap';

import Card from 'components/Card';
import Text from 'components/Text';

import { formatNumber } from 'utils/formatter';

export default function Vehicle({ load }) {
  const vehicles = useMemo(() => {
    if (load.vehicleTypes) {
      return load.vehicleTypes.map(i => i.name).join(', ');
    }

    return null;
  }, [load.vehicleTypes]);

  const implement = useMemo(() => {
    if (load.vehicleImplementTypes) {
      return load.vehicleImplementTypes.map(i => i.name).join(', ');
    }

    return null;
  }, [load.vehicleImplementTypes]);

  const bodies = useMemo(() => {
    if (load.vehicleBodyTypes) {
      return load.vehicleBodyTypes.map(i => i.name).join(', ');
    }

    return null;
  }, [load.vehicleBodyTypes]);

  return (
    <Card>
      <Col xs={12} className="mb-3">
        <h5 className="card-title">Veículo</h5>
        <hr className="my-3" />
      </Col>
      <Row>
        {vehicles && (
          <Col xs={12} className="mb-3">
            <Text type="label" color="#464E5F">
              Veículos
            </Text>
            <Text type="regular" color="gray" as="p">
              {vehicles}
            </Text>
          </Col>
        )}
        {implement && (
          <Col xs={12} className="mb-3">
            <Text type="label" color="#464E5F">
              Tipos de implemento
            </Text>
            <Text type="regular" color="gray" as="p">
              {implement}
            </Text>
          </Col>
        )}
        {bodies && (
          <Col xs={12} className="mb-3">
            <Text type="label" color="#464E5F">
              Tipos de carrocerias
            </Text>
            <Text type="regular" color="gray" as="p">
              {bodies}
            </Text>
          </Col>
        )}
        <Col xs={6} className="mb-3">
          <Text type="label" color="#464E5F">
            Disposição da carga
          </Text>
          <Text type="regular" color="gray" as="p">
            {load.disposition ? load.disposition.name : 'Paletizada'}
          </Text>
        </Col>
        <Col xs={6} className="mb-3">
          <Text type="label" color="#464E5F">
            Pallets
          </Text>
          <Text type="regular" color="gray" as="p">
            {load.palletNumber || '-'}
          </Text>
        </Col>
        <Col xs={6} className="mb-3">
          <Text type="label" color="#464E5F">
            Peso
          </Text>
          <Text type="regular" color="gray" as="p">
            {load.loadWeight ? `${formatNumber(load.loadWeight)} kg` : '-'}
          </Text>
        </Col>
        <Col xs={6} className="mb-3">
          <Text type="label" color="#464E5F">
            Volume
          </Text>
          <Text type="regular" color="gray" as="p">
            {load.loadVolume ? `${load.loadVolume} m³` : '-'}
          </Text>
        </Col>
        <Col xs={6} className="mb-3">
          <Text type="label" color="#464E5F">
            Monitorado
          </Text>
          <Text type="regular" color="gray" as="p">
            {load.crawledLoad ? 'Sim' : 'Não'}
          </Text>
        </Col>
        <Col xs={6} className="mb-3">
          <Text type="label" color="#464E5F">
            Monitoramento
          </Text>
          <Text type="regular" color="gray" as="p">
            {load.riskManager ? load.riskManager.name : '-'}
          </Text>
        </Col>
        <Col xs={6} className="mb-3">
          <Text type="label" color="#464E5F">
            Necessita de MOPP
          </Text>
          <Text type="regular" color="gray" as="p">
            {load.mopp ? 'Sim' : 'Não'}
          </Text>
        </Col>
      </Row>
    </Card>
  );
}

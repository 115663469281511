/* eslint-disable import/no-unresolved */
import React, { useState, useEffect, useRef, useMemo } from 'react';
import moment from 'moment';
import Text from 'components/Text';
import Card from 'components/Card';
import Modal from 'components/Modal';
import List from 'components/BulletList';
import { fetchCountries } from "utils/fetches";
import { formatAddress } from 'utils/formatter';
import { Row, Col } from 'react-bootstrap';

import * as Styled from './styles';

const actionOptions = [
  { name: 'Coleta', id: 'collect' },
  { name: 'Entrega', id: 'delivery' },
  { name: 'Aduaneiro', id: 'customhouse' },
];

export default function Address({
  load
}) {

  const { loadOrigins, loadDestinations } = load

  const [origins, setOrigins] = useState([]);
  const [destinations, setDestinations] = useState([]);
  const [countries, setCountries] = useState([]);
  const [show, setShow] = useState(false);

  useEffect(() => {
    fetchCountries().then(setCountries())
  }, []);

  const ordered = useMemo(() => {
    return {
      origins: origins.sort(
        (a, b) =>
          moment(a.scheduledTime, 'DD/MM/YYYY HH:mm').toDate().valueOf() -
          moment(b.scheduledTime, 'DD/MM/YYYY HH:mm').toDate().valueOf()
      ),
      destinations: destinations.sort(
        (a, b) =>
          moment(a.destinationDate, 'DD/MM/YYYY HH:mm').toDate().valueOf() -
          moment(b.destinationDate, 'DD/MM/YYYY HH:mm').toDate().valueOf()
      ),
    };
  }, [origins, destinations]);

  function getDestinations() {
    if (loadDestinations ) {
      const formattedDestinations = loadDestinations .map(destination => ({
        ...destination,
        country: countries.find(
          element => element.abbreviation === destination.countrySlug
        ),
        zipCode: destination.zipCode || '',
        number: destination.number || '',
        neighborhood: destination.neighborhood || '',
        complement: destination.complement || '',
        action: actionOptions.find(
          ({ id }) => id === destination.action.toLowerCase()
        ),
        scheduledTime: destination.scheduledTime
          ? moment(destination.scheduledTime).format('DD/MM/YYYY HH:mm')
          : '',
        startSchedule: destination.startSchedule
          ? moment(destination.startSchedule).format('DD/MM/YYYY HH:mm')
          : '',
        type: 'destination',
      }));

      setDestinations(formattedDestinations);
    }
  }
  useEffect(() => getDestinations(), [loadDestinations]);

  function getOrigins() {
    if (loadOrigins) {
      const formattedOrigins = loadOrigins.map(origin => ({
        ...origin,
        country: countries.find(
          element => element.abbreviation === origin.countrySlug
        ),
        zipCode: origin.zipCode || '',
        number: origin.number || '',
        neighborhood: origin.neighborhood || '',
        complement: origin.complement || '',
        action: actionOptions.find(({ id }) => id === 'collect'),
        scheduledTime: origin.scheduledTime
          ? moment(origin.scheduledTime).format('DD/MM/YYYY HH:mm')
          : '',
        formattedAddress: origin.formatted,
        startSchedule: origin.startSchedule
          ? moment(origin.startSchedule).format('DD/MM/YYYY HH:mm')
          : '',
        type: 'origin',
      }));

      setOrigins(formattedOrigins);
    }
  }
  useEffect(() => getOrigins(), [loadOrigins]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function locationsObject(showAllDestinations = true) {
    const destinationsToDisplay = showAllDestinations
      ? ordered.destinations
      : ordered.destinations.slice(-1);

    const items = [
      ...ordered.origins.map(item => ({
        header: (
          <div className="d-flex flex-row justify-content-between">
            <Text>{item.action.name}</Text>
          </div>
        ),
        body: (
          <>
            <Text className="my-2" color="gray" type="regular">
              {formatAddress(item)}
            </Text>
            <Text color="gray" type="regular">
              {item.startSchedule && `${item.startSchedule} até `}
              {item.scheduledTime}
            </Text>
          </>
        ),
        filledCircle: false,
      })),
    ];

    if (!showAllDestinations && ordered.destinations.length > 1)
      items.push({
        header: (
          <Text color="light_blue" type="regular">
            <Styled.ModalLauncher onClick={handleShow} className="">
              {ordered.destinations.length - 1} destinos
            </Styled.ModalLauncher>
          </Text>
        ),
        link: true,
      });

    items.push(
      ...destinationsToDisplay.map(item => ({
        header: (
          <div className="d-flex flex-row justify-content-between">
            <Text>{item.action.name}</Text>
          </div>
        ),
        body: (
          <>
            <Text className="my-2" color="gray" type="regular">
              {formatAddress(item)}
            </Text>
            <Text color="gray" type="regular">
              {item.startSchedule && `${item.startSchedule} até `}
              {item.scheduled0Time}
            </Text>
          </>
        ),
        filledCircle: true,
      }))
    );

    return items;
  }


  return (
    <>
      <Modal
        scrollable
        show={show}
        onHide={handleClose}
        heading={<Text>Endereço</Text>}
        body={
          <Row>
            <Col xs={12} className="mt-4 pl-4">
              <List items={locationsObject()} />
            </Col>
          </Row>
        }
      />
      <Col xs={12}>
        <Card
          header={
            <Text color="#464E5F" type="header">
              Endereços
            </Text>
          }
        >
          <Row>
            <Col xs={12} className="pt-3">
              <List items={locationsObject(false)} />
            </Col>
          </Row>
        </Card>
      </Col>
    </>
  );
}

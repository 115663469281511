export default {
  FETCH_VEHICLE_TYPES: 'vehicle/FETCH_VEHICLE_TYPES',
  FETCH_VEHICLE_TYPES_SUCCESS: 'vehicle/FETCH_VEHICLE_TYPES_SUCCESS',
  FETCH_VEHICLE_TYPES_FAILURE: 'vehicle/FETCH_VEHICLE_TYPES_FAILURE',
  FETCH_VEHICLE_AXIS: 'vehicle/FETCH_VEHICLE_AXIS',
  FETCH_VEHICLE_AXIS_SUCCESS: 'vehicle/FETCH_VEHICLE_AXIS_SUCCESS',
  FETCH_VEHICLE_AXIS_FAILURE: 'vehicle/FETCH_VEHICLE_AXIS_FAILURE',
  FETCH_VEHICLE_IMPLEMENTS: 'vehicle/FETCH_VEHICLE_IMPLEMENTS',
  FETCH_VEHICLE_IMPLEMENTS_SUCCESS: 'vehicle/FETCH_VEHICLE_IMPLEMENTS_SUCCESS',
  FETCH_VEHICLE_IMPLEMENTS_FAILURE: 'vehicle/FETCH_VEHICLE_IMPLEMENTS_FAILURE',
  FETCH_VEHICLE_BODIES: 'vehicle/FETCH_VEHICLE_BODIES',
  FETCH_VEHICLE_BODIES_SUCCESS: 'vehicle/FETCH_VEHICLE_BODIES_SUCCESS',
  FETCH_VEHICLE_BODIES_FAILURE: 'vehicle/FETCH_VEHICLE_BODIES_FAILURE',
  FETCH_VEHICLES: 'vehicle/FETCH_VEHICLES',
  FETCH_VEHICLES_SUCCESS: 'vehicle/FETCH_VEHICLES_SUCCESS',
  FETCH_VEHICLES_FAILURE: 'vehicle/FETCH_VEHICLES_FAILURE',
};

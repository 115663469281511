import React from 'react';

import theme from 'styles/theme';

import { StyledSpan } from './styles';

function Text({
  type = 'body',
  family = 'Poppins',
  weight = 400,
  color,
  transform = 'none',
  fontSize,
  as = 'span',
  clickable,
  truncate,
  style,
  wordwrap,
  ...props
}) {
  const styles = {
    ...style,
    fontFamily: family,
    fontWeight: weight,
    color: theme.colors[color] || color,
    fontSize: fontSize || theme.text.sizes[type],
    textTransform: transform,
  };

  return (
    <StyledSpan
      as={as}
      clicklable={clickable}
      truncate={truncate}
      wordwrap={wordwrap}
      style={styles}
      {...props}
    />
  );
}

export default Text;


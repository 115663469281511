import styled, { keyframes } from 'styled-components';
import theme from '../../styles/theme';

const blink = keyframes`
  0%, 50%, 100% {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
`;

export const NotificationUnreadIcon = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 5px;
  background-color: ${theme.colors.error};
  position: absolute;
  top: 10px;
  left: 25px;
`;

export const NotificationHelp = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 5px;
  background-color: ${theme.colors.error};
  position: absolute;
  top: 8px;
  animation: ${blink} 2s infinite;
`;

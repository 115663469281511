import React from 'react';

import Button from '..';

export default function IconButton({
  icon,
  color = '#fff',
  variant,
  size,
  sizeButton = 'sm',
  loading,
  onClick,
  disabled,
  ...props
}) {
  // eslint-disable-next-line global-require
  const Icon = require('react-icons/fa')[icon];

  return (
    <Button
      {...props}
      variant={variant}
      onClick={onClick}
      loading={loading}
      size={sizeButton}
      disabled={disabled}
    >
      <Icon color={color} size={size} style={{ marginBottom: 3 }} />
    </Button>
  );
}

/* eslint-disable import/no-unresolved */
/* eslint-disable no-shadow */
import React from 'react';

import { useTravelDetail } from '../context';
import { Row, Col } from 'react-bootstrap';

import {
  formatNumber,
} from 'utils/formatter';

import Text from 'components/Text';
import Card, { DefaultLoadingBodyComponent } from 'components/Card';

import { formatDateTime } from 'utils/formatter';

function Load() {
  const { data, isFetching } = useTravelDetail();

  return (
    <Card
      loading={isFetching}
      LoadingBodyComponent={() => (
        <DefaultLoadingBodyComponent linesCount={3} perLine={2} />
      )}
      header={
        <Row>
          <Col xs={12} className="mb-2">
            <Text color="dark" type="header">
              Dados da carga
            </Text>
          </Col>
        </Row>
      }
      HeaderRightComponent={
        <Row>
          <Col xs={12} className="mt-1">
            {data?.load?.due_date && (
              <>
                <Text type="regular" color="gray" as="p">
                  Criada por {data?.load.userCreated?.username}
                  <Text type="label" color="gray" as="p">
                    Em {formatDateTime(data?.load?.created_at)}
                  </Text>
                </Text>
              </>
            )}
          </Col> 
        </Row>
      }
    >
      <Row>
        <Col md={6} xs={12}>
          <Text type="label" color="dark">
            Cliente
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {data?.social_name} {' - '} {data?.cgccpf}
          </Text>
        </Col>
        <Col md={6} xs={12}>
          <Text type="label" color="dark">
            Centro de custo
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {data?.load?.costCenter?.name || 'Não informado'}
          </Text>
        </Col>
        <Col md={6} xs={12} className="mt-2">
          <Text type="label" color="dark">
            Produto
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {(data?.load?.productOnly &&
              `${data?.load?.productOnly?.name} - ${data?.load?.productType?.name}`) ||
              'Não informado'}
          </Text>
        </Col>
        <Col md={6} xs={12} className="mt-2">
          <Text type="label" color="dark">
            Negociação
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {data?.negotiation_name || 'Não informado'}
          </Text>
        </Col>
        <Col md={6} xs={12}>
          <Text type="label" color="dark">
            Filial
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {data?.branch_office_name || 'Não informado'}
          </Text>
        </Col>
        <Col md={6} xs={12}>
          <Text type="label" color="dark">
            Disposição
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {data?.load?.disposition?.name || 'Não informado'}
          </Text>
        </Col>

        <Col md={6} xs={12}>
          <Text type="label" color="dark">
            Pallets
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {data?.load?.pallet_number || 'Não paletizada'}
          </Text>
        </Col>

        <Col md={6} xs={12}>
          <Text type="label" color="dark">
            Peso
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {data?.load?.load_weight
              ? formatNumber(data?.load?.load_weight)
              : 'Não informado'}
          </Text>
        </Col>

        <Col md={6} xs={12}>
          <Text type="label" color="dark">
            Volume em M³
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {data?.load?.load_volume > 0
              ? formatNumber(data?.load?.load_volume)
              : 'Não informado'}
          </Text>
        </Col>
        <Col md={6} xs={12}>
          <Text type="label" color="dark">
            Temperatura
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {data?.load?.temperature || 'Não informado'}
          </Text>
        </Col>

        <Col md={6} xs={12}>
          <Text type="label" color="dark">
            Tags
          </Text>
          {data?.load?.tags?.length > 0 ? (
            <Text type="regular" color="gray" className="mt-2" as="p">
              {data?.load?.tags.map(tag => tag.name.name).join(', ')}
            </Text>
          ) : (
            <Text type="regular" color="gray" className="mt-2" as="p">
              Não informado
            </Text>
          )}
        </Col>
        <Col md={6} xs={12}>
          <Text type="label" color="dark">
            Invoice
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {data?.load?.external_invoice_number || 'Não informado'}
          </Text>
        </Col>

        <Col xs={12}>
          <Text type="label" color="dark">
            CRT
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {data?.load?.crt || 'Não informado'}
          </Text>
        </Col>
        <Col xs={12} md={6}>
          <Text type="label" color="dark">
            Número do pedido do cliente
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {data?.load?.client_order_number || 'Não informado'}
          </Text>
        </Col>
      </Row>
    </Card>
  );
}

export default Load;

import React from 'react';

import Text from 'components/Text';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'components/Table';

export default function ContactDetails({ driver }) {
  return (
    driver && (
      <Row>
        <Col lg={6} md={6} xs={12}>
          <Text type="label">Telefone principal</Text>

          <Text type="regular" color="gray" as="p" className="mt-2">
            { driver.phone || driver.user?.phonenumber || '-'}
          </Text>
        </Col>
        <Col xs={12}>
          <Text type="label">Email</Text>

          <Text type="regular" color="gray" as="p" className="mt-2">
            {driver.email || driver.user?.email || '-'}
          </Text>
        </Col>

        {driver.phones && driver.phones.length > 0 && (
          <>
            <Col xs={12} className="my-3">
              <Text type="body" color="#464E5F">
                Outros contatos
              </Text>
            </Col>
            <Col xs={12}>
              <Table>
                <thead>
                  <tr>
                    <th>
                      <Text type="label" weight="500">
                        Nome
                      </Text>
                    </th>
                    <th className="text-center">
                      <Text type="label" weight="500">
                        Telefone
                      </Text>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {driver.phones &&
                    driver.phones.map(contact => (
                      <tr>
                        <td>
                          <Text weight={400} type="regular">
                            {contact.name}
                          </Text>
                        </td>
                        <td width="240" className="text-center">
                          <Text weight={400} type="regular">
                            {contact.phone}
                          </Text>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Col>
          </>
        )}
      </Row>
    )
  );
}

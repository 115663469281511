import React from 'react';

import Card from 'components/Card';
import Text from 'components/Text';

export function Section({ Title, HeaderRightComponent, children, loading }) {
  return (
    <Card
      loading={loading}
      header={
        <Text type="header" color="dark">
          {Title}
        </Text>
      }
      HeaderRightComponent={HeaderRightComponent}
    >
      {children}
    </Card>
  );
}

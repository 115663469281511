import styled, { css } from 'styled-components';
import ListItemText from '@material-ui/core/ListItemText';

import { withoutProps } from 'utils/props';

const StyledListItemText = styled(withoutProps('active')(ListItemText))`
  div span {
    font-weight: 200 !important;
    font-family: 'Muli', sans-serif;
  }

  span {
    font-weight: 200;
    font-family: 'Muli', sans-serif;
    /*text-transform: uppercase;*/
    font-size: '14px';
    color: #76838f;

    span {
      font-weight: 200;
    }

    padding-left: '30px';
    ${({ active }) =>
      active &&
      css`
        font-weight: 600 !important;
        font-size: '18px'
      `};
  }
`;

export default StyledListItemText;

import React from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Text from "components/Text";


function handleVehicleInfo(vehicle) {
  let info = "";
  if (vehicle?.vehicleType) info = vehicle?.vehicleType.name;
  if (vehicle?.veichleAxi) info += ` | ${vehicle?.veichleAxi.name}`;
  if (vehicle?.vehicleBodyType) info += ` | ${vehicle?.vehicleBodyType.name}`;

  return info;
}

export default function VehileCard({ vehicle }) {
  return (
    <>
      <Col lg={12} md={12} xs={12} className="p-2" style={{ display: 'flex', justifyContent: 'center' }}>
        <Col lg={2} md={2} xs={2}>
          <Card style={{ width: 200, height: 140, justifyContent: 'center', alignItems: 'center' }}>
            <Row style={{ display: 'flex', flexDirection: 'column' }}>
              <Col>
                <Text weight={600}>
                  {`Posição: ${vehicle?.position}º `}
                </Text>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col lg={8} md={8} xs={8}>
          <Card className="card-list" >
            <Card.Body>
              <Row className="p-3">
                <Col xs="8">
                  <span className="card-title">
                    <i className="fas fa-truck mr-2" />
                    {vehicle?.plate === null ? "não encontrada" : vehicle?.plate}
                  </span>
                  <Text className="card-text">{handleVehicleInfo(vehicle)}</Text>
                  <br />
                </Col>
                <Col xs={12}>
                  <span className="card-label">Transportadora:</span>
                  <span className="card-text">
                    {vehicle?.owner.socialName}
                  </span>
                </Col>
                <Col xs={12}>
                  <span className="card-label">CNPJ:</span>
                  <span className="card-text">
                    {vehicle?.owner.cpfCnpj}
                  </span>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Col>
    </>
  );
}

import React from 'react';

import Text from 'components/Text';

import {
  Container,
  SubContainer,
  BoxContainer,
  OriginBox,
  DestinationBox,
  FinalBox,
  Speed,
  AverageSpeed,
  MaxSpeed,
  DriverJourney,
} from './styles';

function StatusColor() {
  return (
    <Container>
      <SubContainer>
        <BoxContainer>
          <OriginBox />
          <Text weight="400" type="label" color="gray">
            Início
          </Text>
        </BoxContainer>

        <BoxContainer>
          <DestinationBox />
          <Text weight="400" type="label" color="gray">
            Destinos
          </Text>
        </BoxContainer>

        <BoxContainer>
          <FinalBox />
          <Text weight="400" type="label" color="gray">
            Final
          </Text>
        </BoxContainer>

        <BoxContainer>
          <Speed />
          <Text weight="400" type="label" color="gray">
            Velocidade
          </Text>
        </BoxContainer>

        <BoxContainer>
          <AverageSpeed />

          <Text weight="400" type="label" color="gray">
            Velocidade média
          </Text>
        </BoxContainer>

        <BoxContainer>
          <MaxSpeed />

          <Text weight="400" type="label" color="gray">
            Velocidade máxima
          </Text>
        </BoxContainer>
        <BoxContainer>
          <DriverJourney />

          <Text weight="400" type="label" color="gray">
            Motorista
          </Text>
        </BoxContainer>
      </SubContainer>
    </Container>
  );
}

export default StatusColor;

/* eslint-disable import/no-unresolved */
import React from 'react';

import * as Controller from './controller';
import * as Styled from './styles';

const [RadioGroupProvider] = Controller.RadioGroupContext;

export default function RadioGroup({
  label,
  labelColor = 'dark',
  onChange,
  value,
  horizontal = false,
  disabled,
  children,
}) {
  return (
    <RadioGroupProvider value={{ onChange, value, disabled }}>
      {label && (
        <Styled.Label color={labelColor} type="label" weight="500">
          {label}
        </Styled.Label>
      )}
      <Styled.Container horizontal={horizontal}>{children}</Styled.Container>
    </RadioGroupProvider>
  );
}

export { Controller as GroupController };

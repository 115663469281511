import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Collapse from '@material-ui/core/Collapse';
import ExpandMore from '@material-ui/icons/ExpandMore';

import ListItem from 'components/ListItem';
import ListItemText from 'components/ListItemText';

const useStyles = makeStyles(theme => ({
  menuItem: {
    fontSize: '14px',
    color: '#76838F',
    fontFamily: 'Muli, sans-serif',
    fontWeight: '300',
    textTransform: 'none',
  },
  menuItemNested: {
    color: '#76838F',
    fontFamily: 'Muli, sans-serif',
    fontWeight: '300',
    textTransform: 'initial',
  },
  nested: {
    fontWeight: '300',
    textTransform: 'initial',
    paddingLeft: '30px',
  },
  submenuTitle: {
    fontFamily: 'Muli, sans-serif',
    fontWeight: '300',
  },
}));

export default function NestedList({ title, text }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  function handleClick() {
    setOpen(!open);
  }

  return (
    <>
      <ListItem
        button
        disableRipple
        onClick={handleClick}
        className={classes.menuItem}
      >
        <ExpandMore
          style={{
            transform: open ? 'rotate(180deg)' : 'none',
            transition: 'transform .3s ease',
          }}
        />
        <ListItemText
          primary={title}
          style={{ paddingLeft: '20px' }}
          active={true}
        />
      </ListItem>
    
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            disableRipple
            primary={title}
            button
            className={classes.nested}
          >
            <ListItemText
              classes={classes.nested}
              primary={text}
              style={{ paddingLeft: 20 }}
            />
          </ListItem>
        </List>
      </Collapse>
    </>
  );
}

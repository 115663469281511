const colors = {
  primary: '#1C4B75',
  secondary: '#E5EAEE',
  error: '#F64E60',
  danger: '#df4759',
  success: '#099b94',
  info: '#29096c',
  warning: '#FFC107',
  light: '#D8EBFF',
  dark: '#464E5F',
  background: '#FFFFFF',
  white: '#fff',
  light_blue: '#266ca8',
  dark_blue: '#143857',
  button_menu: 'rgba(255, 255, 255, 0.15)',
  medium_gray: '#B5B5C3',
  placeholder: '#888',
  gray: '#494950',
  light_gray: '#F9F9F9',
  black: '#212529',
  light_yellow: '#FFF4DE',
  light_red: '#FFE2E5',
  light_green: '#C9F7F5',
  yellow_indicator: '#FDEAA7',
  orange: '#FFC300',
  yellow_rating_star: '#FFA800',
  yellow: '#FFFF00',
};

const button = {
  colors: {
    primary: {
      background: colors.primary,
      text: colors.white,
      outline: {
        background: colors.white,
        text: colors.primary,
      },
      light: {
        background: colors.light,
        text: colors.primary,
      },
    },
    secondary: {
      background: colors.secondary,
      text: colors.dark,
      outline: {
        background: colors.white,
        text: colors.dark,
      },
    },
    danger: {
      background: colors.danger,
      text: colors.white,
      outline: {
        background: colors.white,
        text: colors.danger,
      },
      light: {
        background: colors.light_red,
        text: colors.danger,
      },
    },
    error: {
      background: colors.error,
      text: colors.white,
      outline: {
        background: colors.white,
        text: colors.error,
      },
      light: {
        background: colors.light_red,
        text: colors.error,
      },
    },
    success: {
      background: colors.success,
      text: colors.white,
      outline: {
        background: colors.white,
        text: colors.success,
      },
      light: {
        background: colors.light_green,
        text: colors.success,
      },
    },
    info: {
      background: colors.info,
      text: colors.white,
      outline: {
        background: colors.white,
        text: colors.info,
      },
    },
    warning: {
      background: colors.warning,
      text: colors.dark,
      outline: {
        background: colors.white,
        text: colors.warning,
      },
      light: {
        background: colors.light_yellow,
        text: colors.dark,
      },
    },
    light: {
      background: colors.light,
      text: colors.light_blue,
      outline: {
        background: colors.white,
        text: colors.light_blue,
      },
    },
    dark: {
      background: colors.dark,
      text: colors.white,
      outline: {
        background: colors.white,
        text: colors.dark,
      },
    },
    white: {
      background: colors.white,
      text: colors.dark,
      outline: {
        background: colors.white,
        text: colors.dark,
      },
    },
    transparent: {
      background: 'transparent',
      text: colors.white,
    },
    raster: {
      background: 'transparent',
      text: colors.danger,
    },
  },
  spacing: {
    sm: '5px',
    md: '10px',
    lg: '15px',
  },
};

const badge = {
  colors: {
    'Ordem de coleta': button.colors.primary,
    'Liberado coleta': button.colors.primary,
    'Em viagem': button.colors.info,
    'Aguardando descarregamento': button.colors.warning,
    'Em análise': button.colors.warning,
    'Carregamento finalizado': button.colors.primary,
    'Documentação emitida': button.colors.info,
    Finalizado: button.colors.success,
    Cancelado: button.colors.error,
    'Aguardando carregamento': button.colors.primary,
    'Aguardando emissão': button.colors.primary,
    Transbordado: button.colors.success,
    Bloqueado: button.colors.error,
    ...button.colors,
  },
};

const theme = {
  text: {
    sizes: {
      title: '2rem',
      subtitle: '24px',
      header: '1.125rem',
      body: '1rem',
      regular: '0.875rem',
      medium: '0.8125rem',
      label: '0.75rem',
      little: '0.625rem',
    },
  },
  spacing: {
    buttonY: {
      sm: '5px',
      md: '10px',
      lg: '15px',
    },
    buttonX: {
      sm: '10px',
      md: '30px',
      lg: '25px',
    },
  },
  colors: {
    ...colors,
    button: button.colors,
    badge: badge.colors,
  },
  shadow: '0 1px 1px rgba(0, 0, 0, 0.16)',
};

export default theme;
/* eslint-disable import/no-unresolved */
import styled, { css } from 'styled-components';
import Text from 'components/Text';

export const Container = styled.div`
  ${({ horizontal }) =>
    horizontal &&
    css`
      display: inline-flex;
    `}
`;

export const Label = styled(Text)`
  color: ${props => props.labelColor};
  margin-bottom: 10px;
  margin-top: 3px;
  display: flex;
  font-size: 13px;
`;

import React, { useState, useEffect, useMemo } from 'react';
import { formatDate } from 'utils/formatter';
import { Row, Col } from 'react-bootstrap';
import Text from 'components/Text';
import Card, { DefaultLoadingBodyComponent } from 'components/Card';
import { WhatsAppIcon } from './styles';
import { useTravelDetail } from '../context';

function Driver() {

  const { data, isFetching } = useTravelDetail();
  const [paymentMethod, setPaymentMethod] = useState(null);

  function checkDriverPaymentMethod() {
    if (data?.driverProps) {
      setPaymentMethod(data?.driverProps?.payment?.name);
    } else if (data?.shipperPayment) {
      setPaymentMethod(data?.shipperPayment?.payment?.name);
    }
  }

  useEffect(() => {
    checkDriverPaymentMethod();
  }, [data]);


  const driverPhone = useMemo(() => {
    const assertedPhone =
      data?.driverData?.phone ||
      data?.driverData?.user?.phonenumber;

    let whatsappPhone = assertedPhone?.replace(/\D/g, '');

    const PHONE_LENGTH_WITHOUT_DDI = 13;
    if (whatsappPhone?.length <= PHONE_LENGTH_WITHOUT_DDI) {
      whatsappPhone = '55'.concat(whatsappPhone);
    }

    return {
      raw: assertedPhone,
      whatsapp: whatsappPhone,
    };
  }, [data?.driverData]);

  if (isFetching) {
    return null;
  }
  return (
    <>
      <Card
        loading={isFetching}
        LoadingBodyComponent={() => (
          <DefaultLoadingBodyComponent linesCount={3} perLine={2} />
        )}
        header={
          <Text color="dark" type="header">
            Motorista
          </Text>
        }
      >
          <Row>
            <Col xs={12}>
              <Text type="label" color="dark">
                Motorista
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {data?.driverData?.social_name || 'Não informado'}
              </Text>
            </Col>
          </Row>
        <Row>
          <Col xs={12} md={6}>
            <Text type="label" color="dark">
              {data?.driverData?.is_international ? 'Documento' : 'CPF'}
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {data?.driverData?.cgccpf || 'Não informado'}
            </Text>
          </Col>

          <Col xs={12} md={6}>
            <Text type="label" color="dark">
              Telefone
            </Text>
            <a
              href={`https://api.whatsapp.com/send?l=pt-BR&phone=${driverPhone.whatsapp}`}
              target="_blank"
              rel="noreferrer"
            >
              <div>
                <WhatsAppIcon size={18} />
                <Text type="regular" color="gray">
                  {driverPhone.raw || 'Não informado'}
                </Text>
              </div>
            </a>
          </Col>

          <Col xs={12} md={6}>
            <Text type="label" color="dark">
              CNH
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {data?.driverData?.cnh_key || 'Não informado'}
            </Text>
          </Col>

          <Col xs={12} md={6}>
            <Text type="label" color="dark">
              Data de expiração
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {formatDate(data?.driverData?.cnh_exp_date) ||
                'Não informado'}
            </Text>
          </Col>
          <Col xs={12} md={6}>
            <Text type="label" color="dark">
              Validade cadastro
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {formatDate(data?.personVerifications?.due_date)}
            </Text>
          </Col>
          {data?.personVerifications?.due_date && (
            <Col xs={12} md={6}>
              <Text type="label" color="dark">
                Validado por
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {data?.personVerifications?.verifiedByUser.username}
              </Text>
            </Col>
          )}
          <Col xs={12} md={6}>
            <Text type="label" color="dark">
              Tags
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {data?.driverData?.driverTags
                ?.map(tag => tag.name)
                ?.join(', ') || 'Não informado'}
            </Text>
          </Col>
          <Col xs={12} md={12}>
            <Text type="label" color="dark">
              Método de pagamento
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {paymentMethod || 'Não informado'}
            </Text>
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default Driver;

import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import LoadUseContext from 'contexts/load'
import { privateRoutes, publicRoutes } from './map';
import AuthLayout from 'layouts/Auth';
import ApplicationLayout from 'layouts/Application';
import PrivateRoute from './PrivateRoute';
export default function Routes() {

  const publicRoutesPaths = publicRoutes.map(publicRoute => publicRoute.path);
  const privateRoutesPaths = privateRoutes.map(
    privateRoute => privateRoute.path
  );

  return (
    <Router>
      <Switch>
        <Route exact path={publicRoutesPaths}>
          <AuthLayout>
            <Switch>
              {publicRoutes.map(privateRouteProps => (
                <Route {...privateRouteProps} />
              ))}
            </Switch>
          </AuthLayout>
        </Route>
        <Route exact path={privateRoutesPaths}>
          <LoadUseContext>
                <ApplicationLayout>
                  <Switch>
                    {privateRoutes.map(rest => (
                      <PrivateRoute {...rest} />
                    ))}
                  </Switch>
                </ApplicationLayout>
          </LoadUseContext>
        </Route>
      </Switch>
    </Router>
  );
}

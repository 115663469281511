import api from 'services/api';
import apiCooperplace from 'services/api/cooperplace';

export async function fetchBodyTypes() {
  try {
    const response = await api.get(`body-types?app=true`);
    return response.data;
  } catch (err) {
    return [];
  }
}

export async function fetchAxisTypes() {
  try {
    const response = await api.get(`vehicle-axe?app=true`);
    return response.data;
  } catch (err) {
    return [];
  }
}

export async function fetchCountries() {
  try {
    const response = await api.get('countries');
    return response.data;
  } catch (err) {
    return []
  }
}

export async function fetchAllProvinces() {
  try {
    const response = await api.get('provinces');
    return response.data
  } catch (ex) {
    return []
  }
}

export async function fetchProvinces(search) {
  try {
    const response = await api.get('provinces', { params: { search } });

    return response.data;
  } catch (ex) {
    return [];
  }
}
export async function fetchTrackerTypes(search, tracker) {
  try {
    const response = await api.get(
      `filter-tracker-types?${tracker ? `tracker=${tracker.id}&` : ''
      }search=${search}`
    );
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function fetchBodies() {
  try {
    const response = await api.get(`body-types?app=${true}`);
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function fetchImplements() {
  try {
    const response = await api.get(`vehicle-implement-types?app=${true}`);
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function fetchVehiclesTypes() {
  try {
    const response = await api.get('vehicle-types?app=true', {
      params: { app: true },
    });

    return response.data;
  } catch (ex) {
    return [];
  }
}

export async function fetchVehiclesForDeals(plate) {
  try {
    const response = await apiCooperplace.get('carrierWeb/vehicles/deals', {
      params: { plate: plate }
    });

    return response.data

  } catch (error) {
    return []
  }
}

export async function fetchTrackers() {
  try {
    const response = await api.get('tracker/App');
    return response.data

  } catch (error) {
    return [];
  }
}

export async function fetchCities(search) {
  try {
    const response = await api.get('cities', { params: { search } });
    const provinces = await fetchProvinces(search);
    return [...provinces, ...response.data];
  } catch (ex) {
    return [];
  }
}

export async function fetchAnttTypes() {
  try {
    const response = await api.get('antt-types');
    return response.data;
  } catch (error) {
    return [];
  }
}

export const fetchShippingCompanies = async (search) => {
  try {
    const response = await apiCooperplace.get(`carrierWeb/shippingCompany/byUserId`, {
      params: { name: search }
    });
    return response.data
  } catch (error) {
    return []
  }
}

export const fetchDrivers = async (search) => {
  try {
    const response = await apiCooperplace.get(`carrierWeb/driver`,
      {
        params: { name: search, notPaginate: true }
      });
    return response.data
  } catch (error) {
    return []
  }
}

export async function fetchImplementTypes() {
  try {
    const response = await api.get(`vehicle-implement-types?app=${true}`);
    return response.data;
  } catch (error) {
    return []
  }
}

export async function fetchTakers(search) {
  try {
    const response = await apiCooperplace.get(`carrierWeb/travel/searchTakers`, {
      params: {
        name: search
      }
    });
    return response.data
  } catch (ex) {
    return [];
  }
}

export async function fetchDriverByVehicleId(search) {
  try {
    const response = await apiCooperplace.get(`carrierWeb/driver/byVehicles`, {
      params: { name: search }
    });
    return response.data
  } catch (ex) {
    return [];
  }
}

export async function fetchFuels() {
  try {
    const response = await api.get('fuel');
    return response.data
  } catch (error) {
    return []
  }
}
import { takeLatest, call, put, all } from 'redux-saga/effects';

// eslint-disable-next-line import/no-cycle
import api from 'services/api';

import Types from './types';
import Actions from './actions';

export function* fetchCities() {
  try {
    const response = yield call(api.get, 'cities');

    yield put(Actions.fetchCitiesSuccess(response.data));
  } catch (ex) {
    yield put(Actions.fetchCitiesFailure());
  }
}

export default all([takeLatest(Types.FETCH_CITIES, fetchCities)]);

import React, { useCallback, useMemo, useState } from 'react';
import { Card, Text } from 'components';
import { Mdfe } from './docs/mdfe';
import { Emitted } from './docs/emitted';
import theme from 'styles/theme';
import StringBuilder from 'utils/StringBuilder';
import { formatDateTime } from 'utils/formatter';
import { useTravelDetail } from './context';
import api from 'services/api';

export function TravelDocsCard() {
  const { data, isFetching } = useTravelDetail();
  const [documents, setDocuments] = useState({});
  const [opened, setOpened] = useState();
  const [loading, setLoading] = useState(false);
  const [lightbox, setLightbox] = useState({
    isOpen: false,
    documents: [],
    index: 0,
  });
  function getDocumentMetadata() {
    const map = {
      cte: 'CT-e',
      canhoto: 'Canhoto',
    };

    const document = lightbox.documents[lightbox.index];
    const type = map[document?.type_obs?.toLowerCase()] || 'Outros';
    const createdAt = formatDateTime(document?.created_at);

    return { type, createdAt };
  }
  const lightboxTitle = useMemo(() => {
    const builder = new StringBuilder(' ');

    const documentMetadata = getDocumentMetadata();

    builder
      .append(`${lightbox.index + 1}/${lightbox.documents.length}`)
      .append('|')
      .append(documentMetadata.type)
      .append(`(${documentMetadata.createdAt})`);

    return builder.build();
  }, [lightbox.index, lightbox.documents]);
  const lightboxIndexes = useMemo(() => {
    const next = (lightbox.index + 1) % lightbox.documents.length;
    const previous =
      (lightbox.index + lightbox.documents.length - 1) %
      lightbox.documents.length;
    return { next, previous };
  }, [lightbox.index, lightbox.documents]);
  const fetchDocuments = useCallback(
    async (emittedId, options = {}) => {
      const { refetch = false, setLoading } = options;
      if (!refetch && opened === emittedId) {
        setOpened(null);
        return;
      }
      try {
        setLoading(true);
        const response = await api.get(`travel-attachment/${emittedId}`);
        const filtered = response.data.filter(data => {
          const [attachment] = data.attachments;
          return !!attachment;
        });
        const mapped = filtered?.map(data => {
          const [attachment] = data.attachments;

          let isDocument = true;
          if (attachment.doc_filename) {
            const [, extension] = attachment.doc_filename.split('.');
            if (
              ['jpg', 'png', 'jpeg', 'gif'].includes(extension.toLowerCase())
            ) {
              isDocument = false;
            }
          }

          return {
            ...data,
            ...attachment,
            isDocument,
          };
        });

        setDocuments(prevDocuments => ({
          ...prevDocuments,
          [emittedId]: mapped,
        }));
        setOpened(emittedId);
      } catch (error) {
        // Handle exception
      } finally {
        setLoading(false);
      }
    },
    [opened, setOpened, setDocuments]
  );
  return (
    <Card
      header={
        <Text type="header" color={theme.colors.dark}>
          Documentos da viagem
        </Text>
      }
      loading={loading || isFetching}
    >
      {data?.mdfes?.length > 0 &&
        data?.mdfes?.map(mdfe => (
          <>
            <Mdfe mdfe={mdfe} />
            <hr />
          </>
        ))}
      {data?.ctes?.length > 0 ? (
        data?.ctes?.map(emitted => (
          <>
            <Emitted
              emitted={emitted}
              setLightbox={setLightbox}
              fetchDocuments={fetchDocuments}
              documents={documents}
              opened={opened}
              lightbox={lightbox}
              lightboxTitle={lightboxTitle}
              lightboxIndexes={lightboxIndexes}
            />
            <hr />
          </>
        ))
      ) : (
        <Text color="gray" type="regular">
          Esta viagem ainda não possui documentação emitida!
        </Text>
      )}
    </Card>
  );
}

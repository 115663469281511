/* eslint-disable import/no-cycle */
// Public routes
import Login from 'pages/Login';
import ForgotPassword from 'pages/ForgotPassword'
// Private routes
import Home from 'pages/Home';
import Driver from 'pages/Driver';
import DriverRegister from 'pages/Driver/Register';
import Travels from 'pages/Travels';
import Vehicles from 'pages/Vehicles/';
import TravelDetail from 'pages/Travels/Details';
import LoadDetails from 'pages/Load/Detail';
import LoadList from 'pages/Load/List';
import DriverDetails from 'pages/Driver/Details';
import VehicleShared from 'pages/Vehicles/shared';
import ImplementsList from 'pages/Wagons';
import ImplementShared from 'pages/Wagons/shared';
import QueueOfVehicles from 'pages/QueueOfVehicles';

import Deal from 'pages/Deal';
import List from 'pages/Deal/List';

export const publicRoutes = [
  {
    key: 'login',
    path: '/login',
    component: Login,
    exact: true,
  },
  {
    key: 'forgot-password',
    path: '/forgot-password/:token',
    component: ForgotPassword,
    exact: true,
  },
];

export const privateRoutes = [
  {
    key: 'home',
    path: '/',
    component: Home,
    title: 'Início',
    exact: true,
  },
  {
    key: 'driver',
    path: '/motoristas',
    title: 'Motoristas',
    component: Driver,
    exact: true,
  },
  {
    key: 'driver register',
    path: '/motoristas/cadastrar',
    title: 'Cadastrar motorista',
    component: DriverRegister,
    exact: true,
  },
  {
    key: 'driver details',
    path: '/motoristas/:driver_id',
    title: 'Alterar motorista',
    component: DriverDetails,
    exact: true,
  },
  {
    key: 'travels',
    path: '/viagens',
    title: 'Viagens',
    component: Travels,
    exact: true,
  },
  {
    key: 'travel details',
    path: '/viagens/:id',
    title: 'Detalhes da viagem',
    component: TravelDetail,
    exact: true,
  },
  {
    key: 'queue of vehicles',
    path: '/fila-de-veiculos',
    component: QueueOfVehicles,
    title: 'Fila de veiculos',
    exact: true,
  },
  {
    key: 'vehicles',
    path: '/veiculos',
    title: 'Veículos',
    component: Vehicles,
    exact: true,
  },
  {
    key: 'vehicle register',
    path: '/veiculos/cadastrar',
    title: 'Cadastrar veículo',
    component: VehicleShared,
    exact: true,
  },
  {
    key: 'vehicle show',
    path: '/veiculos/:vehicle_id',
    title: 'Visualizar veículo',
    component: VehicleShared,
    exact: true,
  },
  {
    key: 'deals',
    path: '/negociacoes',
    title: 'Negociações',
    component: List,
    exact: true,
  },
  {
    key: 'deal',
    path: '/negociacoes/cadastrar/:load_id',
    title: 'Nova Negociação',
    component: Deal,
    exact: true,
  },
  {
    key: 'loads',
    path: '/cargas',
    component: LoadList,
    title: 'Listagem de cargas',
    exact: true,
  },
  {
    key: 'load',
    path: '/cargas/:load_id',
    component: LoadDetails,
    title: 'Detalhes da carga',
    exact: true,
  },
  {
    key: 'implement register',
    path: '/implementos/cadastrar',
    component: ImplementShared,
    title: 'Cadastro de implementos',
    exact: true,
  },
  {
    key: 'implement',
    path: '/implementos',
    component: ImplementsList,
    title: 'Listagem de implementos',
    exact: true,
  },
  {
    key: 'implement details',
    path: '/implementos/:implement_id',
    component: ImplementShared,
    title: 'Detalhes da implementos',
    exact: true,
  },
];

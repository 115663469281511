import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

import { StyledButton } from './styles';

export default function Button({
  variant,
  outlined,
  loading,
  disabled,
  children,
  size = 'md',
  ...props
}) {
  return (
    <StyledButton
      {...props}
      size={size}
      type={variant}
      loading={loading}
      outlined={outlined}
      disabled={disabled || loading}
    >
      {loading ? (
        <>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          <span className="sr-only"> Loading...</span>
        </>
      ) : (
        children
      )}
    </StyledButton>
  );
}

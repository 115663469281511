import React, { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Row, Col } from 'react-bootstrap';
import Card from 'components/Card';
import Text from 'components/Text';
import Fallback from 'components/Fallback';
import Badge from 'components/Badge';
import Space from 'components/Space';

import { formatNumber, formatPlate } from 'utils/formatter';

import { useVehicle } from '../../shared/context';
import * as Styled from './styles';

const TRACTION_PLACEHOLDER = '/images/vehicles/4x2.png';
const IMPLEMENT_PLACEHOLDER = '/images/vehicles/implements/carreta/sider.png';

export default function VehicleDetailsSummary() {
  const vehicle = useVehicle();
  const tractionImage = useMemo(() => {
    if (!vehicle.data.type?.id || !vehicle.data.body?.id) {
      return TRACTION_PLACEHOLDER;
    }

    if (vehicle.data.type.qty_plates > 1) {
      const image = `/images/vehicles/${vehicle.data.axisType?.name}`;

      return `${image}.png`;
    }

    const image = `/images/vehicles/${vehicle.data.type?.image}`;

    if (vehicle.data.type.image === 'van') {
      return `${image}.png`;
    }
    if (vehicle.data.body?.image)
      return `/images/vehicles/${vehicle.data.type?.image}/${vehicle.data.body?.image}.png`;
  }, [vehicle.data]);

  const implementImage = useMemo(() => {
    if (
      !vehicle.data.implement ||
      !vehicle.data.body ||
      !vehicle.data.implement?.image ||
      !vehicle.data.body?.image
    ) {
      return IMPLEMENT_PLACEHOLDER;
    }

    const image = `/images/vehicles/implements/${vehicle.data.implement.image}/${vehicle.data.body.image}.png`;

    return image;
  }, [vehicle.data]);

  const hasImplement = useMemo(() => vehicle.data.type?.qty_plates > 1, [
    vehicle.data,
  ]);

  const capacity = useMemo(
    () => vehicle.data.total_gross_weight - vehicle.data.tare_mass,
    [vehicle.data.total_gross_weight, vehicle.data.tare_mass]
  );

  return (
    <Card
      loading={vehicle.isFetching}
      header={
        <Text color="dark" type="header">
          Resumo do veículo
        </Text>
      }
    >
      <div className="text-center">
        <Fallback
          fall={vehicle.isFetching}
          component={<Skeleton width={100} />}
        >
          <Space
            x="20px"
            className="d-flex justify-content-center align-items-center"
          >
            <Badge>
              <Text type="body">{formatPlate(vehicle.data.plate)}</Text>
            </Badge>
            <div>
              <Styled.TareMass>
                <Text color="dark">
                  Tara - {formatNumber(vehicle.data.tare_mass)} kg
                </Text>
                <Styled.Border inverted />
              </Styled.TareMass>
              <Styled.Vehicle>
                <img src={tractionImage} alt="Vehicle" />
                {hasImplement && vehicle.data.implement !== null && (
                  <Styled.Wagon>
                    <img src={implementImage} alt="Implement" />
                    <Styled.Capacity>
                      <Styled.Border />
                      <Text color="dark">
                        Capacidade - {formatNumber(capacity)} kg
                      </Text>
                    </Styled.Capacity>
                  </Styled.Wagon>
                )}
              </Styled.Vehicle>
              {!hasImplement && (
                <Styled.Capacity applyOffset>
                  <Styled.Border />
                  <Text color="dark">
                    Capacidade -{' '}
                    {formatNumber(
                      vehicle.data.total_gross_weight - vehicle.data.tare_mass
                    )}{' '}
                    kg
                  </Text>
                </Styled.Capacity>
              )}
              <Styled.TotalWeight>
                <Styled.Border />
                <Text color="dark">
                  PBT - {formatNumber(vehicle.data.total_gross_weight)} kg
                </Text>
              </Styled.TotalWeight>
            </div>
            {hasImplement && vehicle.data?.implements?.length !== 3 ? (
              vehicle.data?.implements?.map(wagon => (
                <Space y="8px">
                  <Badge>
                    <Text type="body">{formatPlate(wagon?.plate)}</Text>
                  </Badge>
                </Space>
              ))
            ) : (
              <Row>
                {vehicle.data?.implements?.map(wagon => (
                  <Col xs={12} className="mt-2">
                    <Badge style={{ width: '140px' }}>
                      <Text type="body">{formatPlate(wagon?.plate)}</Text>
                    </Badge>
                  </Col>
                ))}
              </Row>
            )}
          </Space>
        </Fallback>
      </div>
    </Card>
  );
}

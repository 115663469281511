import styled from 'styled-components';

export const ContainerCard = styled.div`
  background-color: #fff;
  width: 100%;
  box-shadow: ${props => props.containerCardBoxShadow};
  border-radius: 5px;
`;

export const CardHeader = styled.div`
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 1rem;
  padding: 3rem 2rem 1rem 2rem;
  border-bottom: 1px solid #f2f2f2;

  .selectDate {
    width: 200px;

    @media (max-width: 800px) {
      width: 150px;
    }
  }
`;

export const CardBody = styled.div`
  padding: ${props => props.bodyCardPadding};
`;

import React from 'react';

import { StyledTable } from './styles';

export default function Table({ striped, hover, children }) {
  return (
    <div className="table-responsive">
      <StyledTable striped={striped} hover={hover}>
        {children}
      </StyledTable>
    </div>
  );
}

import styled, { css } from 'styled-components';

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import theme from 'styles/theme';
import Text from 'components/Text';

export const Container = styled.div`
  width: 100%;
`;

export const Input = styled(Form.Control)`
  border: 1px solid #e5eaee;
  border-radius: 5px;
  height: 44px;
  font-family: Poppins;
  font-size: ${props => props.fontSize};
  line-height: 21px;
  letter-spacing: 0.02em;
  color: ${props => props.fontColor};

  ${({ hideAppearance }) =>
    hideAppearance &&
    css`
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    `}

  ::placeholder {
    color: ${theme.colors.placeholder};
    font-size: ${props => props.placeholderSize};
  }

  ${props =>
    props.focus &&
    css`
      box-shadow: none !important;
    `}
  ${props =>
    props.error &&
    css`
      border-color: #f64e60 !important;
    `};

  ${props =>
    props.as &&
    css`
      :first-child {
        width: 100%;
        background-color: #fff;
        padding: 5px;
      }
    `};

  ${props =>
    props.tooltip &&
    css`
      border-width: 1px 0px 1px 1px;
    `}
`;

export const Label = styled(Text)`
  color: ${props => props.labelColor};
  font-size: 13px;
  display: flex;
  margin-bottom: 10px;
  margin-top: 3px;
  white-space: ${props => props.labelWhiteSpace};
`;

export const ContainerInside = styled(InputGroup)`
  border-radius: 5px 5px 5px 5px;
`;

export const IconSpace = styled(InputGroup)`
  border-radius: 5px 0px 0px 5px;
  background: ${props => props.IconSpaceColor};
  height: 44px;
  width: 40px;
  justify-content: center;
  align-content: center;
`;

export const RightIcon = styled(InputGroup)`
  border-radius: 0px 5px 5px 0px;
  background: ${props => props.rightIconColor};
  height: 44px;
  width: 40px;
  justify-content: center;
  align-content: center;
  cursor: pointer;
`;

export const ErrorText = styled(Text)`
  margin-top: 5px;
`;


import React, { useEffect, useState, useRef } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import dealApi from 'services/api/deal';
import dealSocket from 'services/api/deal/socket';

import { formatTime } from 'utils/formatter';

export default function Chat({ deal, user, markDealNewMessage }) {
  const ref = useRef();

  const [messages, setMessages] = useState([]);
  const [sentMessage, setSentMessage] = useState('');

  async function message() {
    if (!deal.id) {
      setMessages([]);
      return;
    }
    const response = await dealApi.get('messages', {
      params: { deal_id: deal.id },
    });
    setMessages(response.data);
  }


  async function sendMessage(event) {
    event.preventDefault();
    setSentMessage('');
    if (!sentMessage) {
      return;
    }
    const messageData = {
      sender_id: user.id,
      receiver_id: deal.negotiator_id,
      deal_id: deal.id,
      message: sentMessage,
    };

    setMessages(oldMessages => [...oldMessages, messageData]);

    await dealApi.post('carrierWebMessages', { ...messageData });
  }

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    message();
  }, [deal]);

  useEffect(() => {
    dealSocket.on('message', incomingMessage => {
      if (incomingMessage.receiver_id === user.id) {
        markDealNewMessage(incomingMessage.deal_id, true);
      }

      if (
        incomingMessage.deal_id === deal.id &&
        incomingMessage.receiver_id === user.id
      ) {
        setMessages(oldMessages => [...oldMessages, incomingMessage]);
        dealSocket.emit('read', incomingMessage);
        markDealNewMessage(deal.id, false);
      }
    });
    return () => {
      dealSocket.removeEventListener('message');
    };
  }, [setMessages, deal, user]);

  return (
    <>
      <div className="chat-message px-3" ref={ref}>
        {messages.map((message, index) => {
          const classStringDirection =
            message.sender_id === user.id ? 'altfollow' : 'follow';
          const classStringArrowText =
            message.sender_id === user.id ? 'alt' : '';
          return (
            <div key={index} className="msg">
              <div className={`bubble ${classStringDirection}`}>
                <div className="txt">
                  <span className="timestamp">
                    {formatTime(message.delivered_at) === null
                      ? 'kkk'
                      : formatTime(message.delivered_at)}
                  </span>
                  <p className={`message ${classStringArrowText}`}>
                    {message.message}
                  </p>
                </div>
                <div className={`bubble-arrow ${classStringArrowText}`} />
              </div>
            </div>
          );
        })}
      </div>
      <div className="chat-footer px-5 py-3">
        <Form onSubmit={event => sendMessage(event)}>
          <Container>
            <Row>
              <Col lg={10} md={10} xs={8} className="px-0">
                {deal.closed || !deal.user_id ?
                  (<FormControl
                    className="border-right-0"
                    placeholder="Digite uma mensagem"
                    value={sentMessage}
                    onChange={event => setSentMessage(event.target.value)}
                    disabled
                  />) :
                  (<FormControl
                    className="border-right-0"
                    placeholder="Digite uma mensagem"
                    value={sentMessage}
                    onChange={event => setSentMessage(event.target.value)}
                  />)}
              </Col>
              <Col lg={2} md={2} xs={4} className="px-0">
                {deal.closed || !deal.user_id ?
                  (<Button
                    type="submit"
                    variant="primary"
                    className="border-left-0 btn-block"
                    disabled
                  >
                    Enviar
                  </Button>) :
                  (<Button
                    type="submit"
                    variant="primary"
                    className="border-left-0 btn-block"
                  >
                    Enviar
                  </Button>)}
              </Col>
            </Row>
          </Container>
        </Form>
      </div>
    </>
  );
}

import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';
import { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import { NotificationContextProvider } from './components/Menu/Notifications';
import Snackbar from './components/SnackbarV3';

import './styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-datepicker/dist/react-datepicker.css';

import theme from 'styles/theme';
import store, { persistor } from 'store';
import api from 'services/api';

import Routes from 'routes';

registerLocale('pt-BR', ptBR);

async function updateOneSignalId(id) {
  try {
    await api.put('user/add-user-id', { push_user_id: id });
  } catch (error) {
    // Handle exception
  }
}

export default function App() {
  window.OneSignal = window.OneSignal || [];
  const { OneSignal } = window;

  useEffect(() => {
    function getOneSignalUserId() {
      window.OneSignal.getUserId(userId => {
        if (userId) {
          updateOneSignalId(userId);
          localStorage.setItem('oneSignalPlayerId', userId);
        }
      });
    }

    OneSignal.push(() => {
      OneSignal.on('notificationPermissionChange', () => {
        getOneSignalUserId();
      });
    });

    OneSignal.push([
      'getNotificationPermission',
      permission => {
        if (permission === 'granted') {
          getOneSignalUserId();
        }
      },
    ]);

    OneSignal.push(() => {
      OneSignal.init({
        appId: process.env.REACT_APP_ONESIGNAL_APPID,
        safari_web_id:
          'web.onesignal.auto.2c31ff0c-1624-4aec-8f89-a4f0b1da0ea1',
        notifyButton: {
          enable: true,
          size: 'small' /* One of 'small', 'medium', or 'large' */,
          theme:
            'default' /* One of 'default' (red-white) or 'inverse" (white-red) */,
          position: 'bottom-left' /* Either 'bottom-left' or 'bottom-right' */,
          offset: {
            bottom: '10px',
            left: '10px' /* Only applied if bottom-left */,
            right: '10px' /* Only applied if bottom-right */,
          },
          prenotify: true /* Show an icon with 1 unread message for first-time site visitors */,
          showCredit: false /* Hide the OneSignal logo */,
          text: {
            'tip.state.unsubscribed': 'Clique para receber notificações',
            'tip.state.subscribed': 'Vocês está recebendo notificações',
            'tip.state.blocked': 'Você bloqueou as notificações',
            'message.prenotify': 'Clique para receber notificações',
            'message.action.subscribed':
              'Obrigado por aceitar receber notificações!',
            'message.action.resubscribed': 'Vocês está recebendo notificações',
            'message.action.unsubscribed':
              'Você não receberá mais notificações',
            'dialog.main.title': 'Gerenciar Notificações',
            'dialog.main.button.subscribe': 'Aceitar',
            'dialog.main.button.unsubscribe': 'Recusar',
            'dialog.blocked.title': 'Desbloqueie as Notificações',
            'dialog.blocked.message':
              'Siga essas instruções para assinar as notificações:',
          },
        },
        subdomainName: 'transportador',
      });
    });
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <div className="App h-100">
          <PersistGate persistor={persistor}>
            <Snackbar>
              <NotificationContextProvider>
                <Routes />
              </NotificationContextProvider>
            </Snackbar>
          </PersistGate>
        </div>
      </ThemeProvider>
    </Provider>
  );
}

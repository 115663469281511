import React from 'react';

import { SnackbarContent, IconButton } from '@material-ui/core';
import {
  Close as CloseIcon,
  Info as InfoIcon,
  Error as ErrorIcon,
  Warning as WarningIcon,
  Check as CheckIcon,
} from '@material-ui/icons';

import { StyledSnackbar, Message } from './styles';

const Types = {
  INFO: 'info',
  ERROR: 'error',
  WARNING: 'warning',
  SUCCESS: 'success',
};

function Snackbar({ open, duration = 5000, message, type, onClose }) {
  function renderIcon() {
    switch (type) {
      case Types.INFO:
        return <InfoIcon style={{ color: '#fff' }} />;
      case Types.ERROR:
        return <ErrorIcon style={{ color: '#fff' }} />;
      case Types.WARNING:
        return <WarningIcon style={{ color: '#fff' }} />;
      case Types.SUCCESS:
        return <CheckIcon style={{ color: '#fff' }} />;
      default:
        return null;
    }
  }

  return (
    <StyledSnackbar
      open={open}
      autoHideDuration={duration}
      type={type}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      onClose={onClose}
    >
      <SnackbarContent
        message={
          <>
            <IconButton>{renderIcon()}</IconButton>
            <Message style={{ color: '#fff', fontSize: '16px' }}>
              {message}
            </Message>
          </>
        }
        action={
          <IconButton
            key="close"
            aria-label="Fechar"
            onClick={onClose}
            style={{ color: '#fff' }}
          >
            <CloseIcon />
          </IconButton>
        }
        id="snackbar"
      />
    </StyledSnackbar>
  );
}

export default Snackbar;

import apiCooperplace from "services/api/cooperplace";

export async function getDrivers(
  filter,
  page = 1,
  { setDrivers, setLoading, setPagination }
) {
  if (page === 1) {
    setLoading(true);
  }

  try {

    const { name, cgccpf, shippingCompanie } = filter;

    const shippingCompanyId = shippingCompanie.map(shipping => shipping.id)

    const response = await apiCooperplace.get(`carrierWeb/driver`,
      {
        params: {
          name: name,
          shippingCompany: shippingCompanyId,
          cgccpf: cgccpf,
          page: page
        }
      });

    const { data, meta } = response.data;

    setDrivers(oldDrivers => {
      if (page === 1) {
        return data;
      }
      return [...oldDrivers, ...data];
    });

    setPagination(meta);
  } catch (error) {
    // snackbar
  } finally {
    setLoading(false);
  }
}

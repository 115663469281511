/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';

import { FaRegStar, FaStarHalfAlt, FaStar, FaCheck } from 'react-icons/fa';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import Text from 'components/Text';
import Card from 'components/Card';
import Tooltip from 'components/Tooltip';

import api from 'services/api/';
import { pluralize } from 'utils/formatter';

import SkeletonResume from 'components/Skeleton';
import { ContainerStar, Image } from './styles';

function Resume({ driver }) {
  const [travelsCount, setTravelsCount] = useState(0);
  const [ratings, setRatings] = useState({
    general: 0,
    schedule: 0,
    app: 0,
  });
  const [selfie, setSelfie] = useState();
  const [verified, setVerified] = useState(false);

  async function fetchRating() {
    const response = await api.get(`driver-rating/${driver.id}`);

    if (response.data) {
      setTravelsCount(response.data.__meta__.travels_count);
      setRatings({
        general: response.data.rating_general,
        app: response.data.rating_app,
        schedule: response.data.rating_schedules,
      });
    }
  }

  function renderStars(rating) {
    const starsQtt = [1, 2, 3, 4, 5];

    return (
      <Tooltip
        placement="bottom"
        content={<Text>{Number(rating).toFixed(2)}</Text>}
      >
        <div>
          {starsQtt.map(star => {
            if (rating >= star) {
              return <FaStar color="#FFA800" />;
            }

            if (rating < star && rating > star - 1) {
              return <FaStarHalfAlt color="#FFA800" />;
            }

            return <FaRegStar color="#FFA800" />;
          })}
        </div>
      </Tooltip>
    );
  }

  useEffect(() => {
    if (driver && driver.id) {
      fetchRating();

      const { driver: driverSelfie } = driver;
      setSelfie(
        driverSelfie && driverSelfie.selfie
          ? driverSelfie.selfie.full_path
          : null
      );
    }

    if (driver && driver.driver) {
      setVerified(driver.driver.verified_person);
    }
  }, [driver]);

  return (
    <Row>
      <Col lgs={12} xs={12}>
        <Card loading={!driver} LoadingBodyComponent={() => <SkeletonResume />}>
          <Row>
            <Col lg={2} md={2} xs={12}>
              <Image
                url={selfie || '/images/user-padrao.png'}
                className="mx-auto mb-4"
                alt="Imagem de perfil"
              />
            </Col>
            <Col lg={10} md={10} xs={12}>
              <Row>
                <Col xs={12} className="text-md-left text-center">
                  <Text
                    type="subtitle"
                    transform="uppercase"
                    line-height="24px"
                  >
                    {driver && driver.social_name}
                  </Text>
                  {verified && (
                    <Tooltip
                      placement="right"
                      content={<Text>Motorista verificado</Text>}
                    >
                      <FaCheck className="mx-3 mb-2" color="#0BB7AF" />
                    </Tooltip>
                  )}
                </Col>
                <Col lg={3} md={3} xs={4} className="my-3">
                  <Text type="body" weight="500">
                    {driver && driver.cgccpf}
                  </Text>
                </Col>
                <Col lg={3} md={3} xs={4} className="my-3">
                  <Text type="body" weight="500">
                    {driver && driver.phone}
                  </Text>
                </Col>
                <Col lg={6} md={6} xs={4} className="my-3 text-center">
                  <Text type="body" weight="500">
                    {travelsCount
                      ? `${travelsCount} ${pluralize(
                          'viagem',
                          travelsCount,
                          'viagens'
                        )}`
                      : 'Nenhuma viagem'}
                  </Text>
                </Col>
                <Col xs={12} className="my-3">
                  <hr />
                </Col>
                <Col lg={3} md={3} xs={6} className="d-sm-none d-md-block">
                  <Text type="label" weight="500">
                    CNH
                  </Text>
                  <Text type="body" as="p" weight="500" className="mt-3">
                    {driver && driver.cnh_number}
                  </Text>
                </Col>
                <Col lg={3} md={3} xs={6} className="d-sm-none d-md-block">
                  <Text type="label" weight="500">
                    Tipo
                  </Text>
                  <Text type="body" as="p" weight="500" className="mt-3">
                    {driver && driver.cnh_type}
                  </Text>
                </Col>
                <Col
                  lg={6}
                  md={6}
                  xs={12}
                  className="d-flex justify-content-between "
                >
                  <ContainerStar>
                    <Text type="label" weight="500" className="d-flex mb-2">
                      Geral
                    </Text>

                    <div>{renderStars(ratings.general)}</div>
                  </ContainerStar>
                  <ContainerStar>
                    <Text type="label" weight="500" className="d-flex mb-2">
                      Horário
                    </Text>

                    <div>{renderStars(ratings.schedule)}</div>
                  </ContainerStar>
                  <ContainerStar>
                    <Text
                      type="label"
                      weight="500"
                      transform="uppercase"
                      className="d-flex mb-2"
                    >
                      App
                    </Text>

                    <div>{renderStars(ratings.app)}</div>
                  </ContainerStar>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
}

export default Resume;

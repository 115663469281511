import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-cycle
import Header from 'components/Header';

export default function Home() {
  const user = useSelector((store) => store.auth.user.properties);

  return (
    <>
      <Header />
      <Container>
        <Card>
          <Card.Body className="py-5">
            <Card.Title>Olá, {user.username}.</Card.Title>
            <hr />
            <Col className="pt-4">
              <p>
                Bem vindo ao portal Cooperplace para transportadoras, aqui você
                pode cadastrar seus veículos, motoristas, acompanhar suas
                viagens e negociar novos fretes.
              </p>
              <p>
                Caso tenha algum dúvida ou questionamento entre contato
                conosco através do email{' '}
                <a href="mailto:coopercarga@coopercarga.com.br">coopercarga@coopercarga.com.br</a>.
              </p>
            </Col>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
}
